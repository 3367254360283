import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import RadioGroup from "components/atomic/atoms/RadioGroup";

import FieldWrapper from "../FieldWrapper";

const RadioGroupField = ({ column, vertical, label, name, control, rules = {}, errors = {}, ...restProps }) => {
  return (
    <FieldWrapper column={column} name={name} label={label} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        // eslint-disable-next-line no-unused-vars
        render={({ field: { ref, ...restField } }) => <RadioGroup vertical={vertical} {...restProps} {...restField} />}
      />
    </FieldWrapper>
  );
};

RadioGroupField.propTypes = {
  column: PropTypes.bool,
  vertical: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default RadioGroupField;
