import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 4000;
  overflow-y: auto;
  background-color: white;
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #555555;
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  min-height: 10%;
  box-shadow: 0px -2px 8px lightgray inset;
`;
const List = styled.div`
  min-height: 90%;
  max-height: 90%;
  width: 100%;
  overflow-y: auto;

  .react-select__menu-list {
    max-height: max-content;
    padding: 8px;
  }

  .react-select__option {
    cursor: pointer;
    font-size: 1.3rem;
    border: 1px solid lightgray;
    border-radius: 12px;
    padding: 8px 48px;
    margin-bottom: 12px;

    @media (max-width: 575px) {
      padding: 8px 12px;
    }
  }
`;

const MobileListModal = (props) => {
  const { children, innerRef, innerProps } = props;

  return (
    <Container
      {...innerProps}
      ref={innerRef}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
    >
      <Title>Please, select option:</Title>
      <List>{children}</List>
    </Container>
  );
};

MobileListModal.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.object,
  innerProps: PropTypes.object,
};

export default MobileListModal;
