import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import useIsDesktopSize from "_hooks/useIsDesktopSize";
import { selectBusinessLicenses, selectLoading } from "_store/orders/selector";

import Button from "components/atomic/atoms/Button";
import CustomExternalLink from "components/atomic/atoms/CustomExternalLink";
import EmptyMessage from "components/atomic/atoms/EmptyMessage";
import Icon from "components/atomic/atoms/Icon";
import Table from "components/atomic/molecules/Table";
import LicenseInfoModal from "components/atomic/organisms/modals/LicenseInfoModal";
import Card from "components/layouts/Card";
import CardHeader from "components/layouts/CardHeader";

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 90%;
  max-height: 90%;
  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    height: 100%;
    max-height: 100%;
  }
`;

const StyledCard = styled(Card)`
  gap: 0px;
  width: 100%;
  min-height: 360px;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: start;
  align-items: start;
  height: max-content;

  padding-left: 10px;
  padding-right: 10px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: max-content;
  padding: 20px 10px;
  /* overflow-y: auto; */

  @media (max-width: 991px) {
    padding: 0 10px;
  }
`;

const HeaderTitle = styled.h6`
  font-size: large;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;
const HeaderText = styled.p`
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const TableCell = styled.div`
  height: 100%;
  width: 100%;
  font-size: 0.8rem;
  word-break: break-word;
`;
const MobileTable = styled.div`
  display: flex;
  flex-direction: column;
  min-height: max-content;
`;
const MobileItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  border-top: none;
  border-bottom: 1px solid #dbe4f0;

  &:last-child {
    border-bottom: none;
  }
`;
const MobileInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 90%;
`;
const MobileText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;
const MobileAction = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 10%;
  min-width: 80px;
  max-width: 80px;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: auto;

  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const MobileButton = styled(Button)`
  min-width: 38px;
  max-width: 38px;
  padding: 0;
  border: none;
`;
const Label = styled.span`
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;

function getLicensesColumns() {
  return [
    {
      dataIndex: "licenseName",
      title: "Name",
      mobile: true,
      width: "15%",
      // width: "25%",
      minWidth: "180px",
      render: (row) => <TableCell>{row?.licenseName || "—"}</TableCell>,
    },
    {
      dataIndex: "authority",
      title: "Authority",
      mobile: true,
      width: "15%",
      minWidth: "120px",
      render: (row) => <TableCell>{row?.authority || "—"}</TableCell>,
    },
    {
      dataIndex: "url",
      title: "Link",
      mobile: false,
      width: "15%",
      // width: "25%",
      minWidth: "200px",
      render: (row) => (
        <TableCell short>
          {row?.url ? (
            <CustomExternalLink short href={row.url} target="_blank">
              {row.url}
            </CustomExternalLink>
          ) : (
            "—"
          )}
        </TableCell>
      ),
    },
    {
      dataIndex: "description",
      title: "Description",
      mobile: true,
      width: "20%",
      // width: "50%",
      minWidth: "120px",
      render: (row) => <TableCell>{row?.description || "—"}</TableCell>,
    },
    {
      dataIndex: "appliesTo",
      title: "Applies to",
      mobile: true,
      width: "15%",
      minWidth: "120px",
      render: (row) => <TableCell>{row?.appliesTo || "—"}</TableCell>,
    },
    {
      dataIndex: "includes",
      title: "Includes",
      mobile: true,
      width: "15%",
      minWidth: "120px",
      render: (row) => <TableCell>{row?.includes || "—"}</TableCell>,
    },
  ];
}

const LicensesPage = () => {
  const isDesktop = useIsDesktopSize();

  const [showModal, setShowModal] = useState(false);
  const [licenseDetails, setLicenseDetails] = useState(null);

  const licenses = useSelector(selectBusinessLicenses);
  const loading = useSelector(selectLoading);

  const columns = getLicensesColumns();

  return (
    <>
      <Container>
        <ContentRow>
          <StyledCard>
            <StyledCardHeader>
              <HeaderTitle>Recommended Licenses and Permits</HeaderTitle>
              <HeaderText>
                Our specialists have identified the following Licenses and Permits for your business that are highly
                recommended. While not all may be applicable to your business, it is recommended you perform a thorough
                review, and apply for the ones you deem necessary.
              </HeaderText>
            </StyledCardHeader>
            <CardContent>
              <>
                {isDesktop ? (
                  <Table columns={columns} rows={licenses} loading={loading} />
                ) : licenses?.length ? (
                  <MobileTable>
                    {licenses.map((license, idx) => (
                      <MobileItem key={`license-item-${license.licenseName}-${idx}`}>
                        <MobileInfo>
                          {columns
                            ?.filter(({ mobile }) => mobile)
                            ?.map((col) => {
                              return (
                                <MobileText key={`mobile-col-${col?.dataIndex}`}>
                                  <Label>{col.title}:</Label> {license[col?.dataIndex] || "—"}
                                </MobileText>
                              );
                            })}
                        </MobileInfo>
                        <MobileAction>
                          <MobileButton
                            outlined
                            onClick={() => {
                              setLicenseDetails(license);
                              setShowModal(true);
                            }}
                          >
                            <StyledIcon icon="mailbox:open" />
                          </MobileButton>
                          <CustomExternalLink href={license.url} target="_blank">
                            <StyledIcon icon="misc:external-link" />
                          </CustomExternalLink>
                        </MobileAction>
                      </MobileItem>
                    ))}
                  </MobileTable>
                ) : (
                  <EmptyMessage>No Licenses or Permits for this company</EmptyMessage>
                )}
              </>
            </CardContent>
          </StyledCard>
        </ContentRow>
      </Container>
      {showModal && (
        <LicenseInfoModal
          columns={columns}
          licenseDetails={licenseDetails}
          onCancel={() => {
            setLicenseDetails(null);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default LicensesPage;
