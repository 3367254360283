import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  a:hover {
    color: inherit;
  }


  // 237°, 71%, 91% #444CE7
  // 236°, 71%, 76% #3841c2
  // 236°, 71%, 71% #353db5
  // 236°, 71%, 67% #323aab
  .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #444CE7;
    --bs-btn-border-color: #444CE7;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3841c2;
    --bs-btn-hover-border-color: #353db5;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #353db5;
    --bs-btn-active-border-color: #323aab;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #444CE7;
    --bs-btn-disabled-border-color: #444CE7;
  }

  // 13°, 95%, 77% #C4320A
  // 12°, 95%, 62% #9e2608
  // 12°, 95%, 57% #912307
  // 12°, 95%, 53% #872007
  .btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #C4320A;
    --bs-btn-border-color: #C4320A;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #9e2608;
    --bs-btn-hover-border-color: #912307;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #912307;
    --bs-btn-active-border-color: #872007;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #C4320A;
    --bs-btn-disabled-border-color: #C4320A;
  }

  .animated-placeholder {
    position: relative;
    overflow: hidden;
    z-index: 1;
    
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-image: linear-gradient(90deg, rgba(255,255,255,0) 46%, #fff 52%, rgba(255,255,255,0) 58%);
      background-size: 400% 100%;

      @keyframes skeleton-loading {
        from {
          background-position: 100% 50%;
        }
        to {
          background-position: 0 50%;
        }
      }

      animation: skeleton-loading 1.4s ease infinite;
    }
  }
`;
