import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

const StyledAreaInput = styled.textarea`
  box-sizing: border-box;
  /* min-width: 692px; */
  width: 100%;
  min-height: 150px;
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 9px 20px;
  outline: none;
  resize: none;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};

    &::placeholder {
      color: ${theme.colors["cl-text-light"]};
    }

    &:focus {
      border: 1px solid;
      border-radius: 8px;
    }
  `}
`;

// eslint-disable-next-line react/display-name
const AreaInput = forwardRef((props, ref) => {
  return <StyledAreaInput ref={ref} {...props} />;
});

export default AreaInput;
