import moment from "moment";

import { PRODUCT_CODES } from "_constants/products";

export const INCLUDED_NAME = {
  NONE: "None",
  OWNER_NAME: "Owner Name",
  OTHER_LIVING_NAME: "Owner Living Name",
  OTHER_NAME: "Other Name",
};

const parseDate = (isoString) => {
  const milliseconds = Date.parse(isoString);
  const dateObj = new Date(milliseconds);
  return {
    date: `${dateObj.getDate()}`,
    month: `${dateObj.getMonth() + 1}`,
    year: `${dateObj.getFullYear()}`,
  };
};

const createDate = (form, dateId) => {
  if (!form[dateId + "Year"] && form[dateId + "Year"]?.length === 0) {
    return null;
  }

  const year = parseInt(form[dateId + "Year"]);
  const month = parseInt(form[dateId + "Month"]) - 1; // to start from 0
  const day = parseInt(form[dateId + "Day"]);

  if (!Number.isNaN(new Date(year, month, day, 10, 0, 0).getTime())) {
    return new Date(year, month, day, 10, 0, 0).toISOString().split("T")[0];
  }

  return null;
};

const nameInMark = (product, form) => {
  if (form.isIncludeLivingPersonName) {
    if (!form.isIncludeNicknameName) {
      if (!form.isOtherLivingName) {
        product.includedName = "OTHER_NAME";
      } else {
        product.includedName = "OTHER_LIVING_NAME";
        product.includedNameValue = form.ownName;
      }
    } else {
      product.includedName = "OWNER_NAME";
      product.includedNameValue = form.ownName;
    }
  } else {
    product.includedName = "NONE";
  }
};

const translationWords = (product, form) => {
  product.includeTranslationWords = form.includeTranslationWords;

  if (form.includeTranslationWords) {
    product.translation = form.translation;
  }
};

const currentTrademark = (product, form) => {
  product.currentTrademark = form.currentTrademark;

  if (form.currentTrademark) {
    if (form.firstUseInCommerceYear) {
      product.firstUseInCommerce = createDate(form, "firstUseInCommerce");
    }
    if (form.firstUseAnywhereYear) {
      product.firstUseAnywhere = createDate(form, "firstUseAnywhere");
    }
  }
};

export const parseTrademarkRegistrationProduct = (product) => {
  const form = {
    id: null,
    orderId: null,
    trademarkType: null,
    markDesc: null,
    literalElements: null,
    logoColoredVariety: true,
    colorsInLogo: null,
    logoFile: null,
    isIncludeLivingPersonName: false,
    isIncludeNicknameName: false,
    ownName: null,
    isOtherLivingName: false,
    includedName: null,
    includedNameValue: null,
    includeTranslationWords: false,
    translation: null,
    currentTrademark: false,
    firstUseAnywhereYear: null,
    firstUseAnywhereMonth: null,
    firstUseAnywhereDay: null,
    firstUseInCommerceYear: null,
    firstUseInCommerceMonth: null,
    firstUseInCommerceDay: null,
    orgName: null,
    orgType: null,
    orgState: null,
    orgPosition: null,
    representativeFirstName: null,
    representativeLastName: null,
    representativeEmail: null,
    representativePhone: null,
    classes: [
      {
        goods: true,
        goodsCategory: null,
        description: null,
        goodsServiceDetail: null,
      },
    ],
    // isSpecimenFile: false,
    specimenDescription: null,
    specimenFile: null,
    website: null,
  };

  form.trademarkType = product?.trademarkType || null;
  form.markDesc = product?.markDesc || null;

  if (product?.trademarkType === "LOGO") {
    form.literalElements = product.literalElements;
    form.logoColoredVariety = product.logoColoredVariety;

    if (product?.colorsInLogo) {
      form.colorsInLogo = product.colorsInLogo;
      form.logoColoredVariety = false;
    }
  }

  if (product?.includedName === "NONE") {
    form.isIncludeLivingPersonName = false;

    form.includedName = "NONE";
    form.includedNameValue = null;
  } else {
    form.isIncludeLivingPersonName = true;

    if (product?.includedName === "OWNER_NAME") {
      form.isIncludeNicknameName = true;
      form.ownName = product.includedNameValue;

      form.includedName = "OWNER_NAME";
      form.includedNameValue = product.includedNameValue;
    } else {
      form.isIncludeNicknameName = false;

      if (product?.includedName === "OTHER_LIVING_NAME") {
        form.isOtherLivingName = true;
        form.ownName = product.includedNameValue;

        form.includedName = "OTHER_LIVING_NAME";
        form.includedNameValue = product.includedNameValue;
      } else {
        form.isOtherLivingName = false;
        form.ownName = product.includedNameValue;

        form.includedName = "OTHER_NAME";
        form.includedNameValue = product.includedNameValue;
      }
    }
  }

  if (product?.includeTranslationWords) {
    form.includeTranslationWords = product.includeTranslationWords;
  }

  if (product?.translation) {
    form.includeTranslationWords = true;
    form.translation = product.translation;
  }

  if (product?.currentTrademark) {
    form.currentTrademark = true;

    if (product.firstUseInCommerce) {
      const {
        date: firstUseInCommerceDay,
        month: firstUseInCommerceMonth,
        year: firstUseInCommerceYear,
      } = parseDate(product.firstUseInCommerce);

      form.firstUseInCommerceYear = firstUseInCommerceYear;
      form.firstUseInCommerceMonth = firstUseInCommerceMonth;
      form.firstUseInCommerceDay = firstUseInCommerceDay;
    }

    if (product.firstUseAnywhere) {
      const {
        date: firstUseAnywhereDay,
        month: firstUseAnywhereMonth,
        year: firstUseAnywhereYear,
      } = parseDate(product.firstUseAnywhere);

      form.firstUseAnywhereYear = firstUseAnywhereYear;
      form.firstUseAnywhereMonth = firstUseAnywhereMonth;
      form.firstUseAnywhereDay = firstUseAnywhereDay;
    }
  } else {
    form.currentTrademark = false;
  }

  if (product?.classes.length) {
    form.classes = product.classes;
  }

  if (product?.organization) {
    form.orgName = product.organization.name;
    form.orgType = product.organization.type;
    form.orgState = product.organization.stateOrCountryOfFormation;
    form.orgPosition = product.organization.position;
  }

  if (product?.individual) {
    form.representativeFirstName = product.individual.firstName;
    form.representativeLastName = product.individual.lastName;
    form.representativeEmail = product.individual.email;
    form.representativePhone = product.individual.phone;
  }

  // if (product?.website) {
  //   form.website = product.website;
  //   form.specimenDescription = product?.specimenDescription || null;
  // } else {
  //   form.isSpecimenFile = true;
  //   form.specimenDescription = product?.specimenDescription || null;
  // }

  if (product?.website) {
    form.website = product.website;
    form.specimenDescription = product?.specimenDescription || null;
  }

  if (product?.specimenDescription) {
    form.specimenDescription = product?.specimenDescription || null;
  }

  if (product?.id) {
    form.id = product?.id || null;
  }

  if (product?.orderId) {
    form.orderId = product?.orderId || null;
  }
  return form;
};

export const parseTrademarkForm = (form) => {
  const TM_PRODUCT_CODES = [
    PRODUCT_CODES.incRegisterTrademark,
    PRODUCT_CODES.incTrademarkBasicSearch,
    PRODUCT_CODES.incTrademarkMonitoring,
  ];

  return TM_PRODUCT_CODES.map((code) => {
    const product = {};

    if (code === PRODUCT_CODES.incRegisterTrademark) {
      product.code = code;
      product.trademarkType = form.trademarkType;
      product.markDesc = form.markDesc;
      product.id = form.id;
      product.orderId = form.orderId;

      product.includedName = "NONE";
      product.includedNameValue = null;
      product.logoColoredVariety = false;
      product.colorsInLogo = null;
      product.literalElements = null;
      product.includeTranslationWords = false;
      product.translation = null;
      product.currentTrademark = false;
      product.firstUseAnywhere = null;
      product.firstUseInCommerce = null;
      product.classes = [];
      product.specimenDescription = null;
      product.website = null;

      if (form.trademarkType === "LOGO") {
        product.literalElements = form.literalElements;
        product.logoColoredVariety = form.logoColoredVariety;

        if (!form.logoColoredVariety) {
          product.colorsInLogo = form.colorsInLogo;
        }
      }

      nameInMark(product, form);
      translationWords(product, form);
      currentTrademark(product, form);

      if (form.classes.length && form.classes[0]?.goodsCategory) {
        product.classes = form.classes;
      }

      if (form?.specimenDescription) {
        product.specimenDescription = form.specimenDescription;
      }

      if (form?.website) {
        product.website = form.website;
      }
    }

    if (code === PRODUCT_CODES.incTrademarkBasicSearch) {
      product.code = code;
      product.searchTermType = form.trademarkType;
      product.text = form.markDesc;
    }

    if (code === PRODUCT_CODES.incTrademarkMonitoring) {
      product.code = code;
      product.trademarkName = form.markDesc;
    }

    return product;
  });
};

export const createTrademarkDetailsData = (form) => {
  const data = [];

  if (!form?.markDesc) {
    return data;
  }

  Object.keys(form).forEach((key) => {
    if (key === "markDesc") {
      data.push({
        label: "Trademark",
        value: form[key],
        order: 1,
      });
    }

    if (key === "trademarkType") {
      data.push({
        label: "Trademark Type",
        value: form[key],
        order: 2,
      });
    }

    if (key === "logoColoredVariety") {
      data.push({
        label: "Protect All Colors",
        value: form[key] ? "Yes" : "No",
        order: 3,
      });
    }

    if (key === "literalElements") {
      data.push({
        label: "Custom Logo",
        value: form[key] || "–",
        order: 4,
      });
    }

    if (key === "colorsInLogo") {
      data.push({
        label: "Colors in Logo",
        value: form[key] || "–",
        order: 5,
      });
    }

    if (key === "includedName") {
      data.push({
        label: "Included Name",
        value: INCLUDED_NAME[form[key]],
        order: 6,
      });
    }

    if (key === "includedNameValue") {
      data.push({
        label: form?.includedName === "NONE" ? "Name" : INCLUDED_NAME[form?.includedName],
        value: form[key] || "—",
        order: 7,
      });
    }

    if (key === "includeTranslationWords") {
      data.push({
        label: "Need Translation",
        value: form[key] ? "Yes" : "No",
        order: 8,
      });
    }

    if (key === "translation") {
      data.push({
        label: "Translation",
        value: form[key] || "–",
        order: 9,
      });
    }

    if (key === "currentTrademark") {
      data.push({
        label: "In Use",
        value: form[key] ? "Yes" : "No",
        order: 10,
      });
    }

    if (key === "firstUseAnywhereYear") {
      data.push({
        label: "First Use Anywhere",
        value: form[key] ? moment(createDate(form, "firstUseAnywhere")).format("MM/DD/YYYY") : "–",
        order: 11,
      });
    }

    if (key === "firstUseInCommerceYear") {
      data.push({
        label: "First Use in Commerce",
        value: form[key] ? moment(createDate(form, "firstUseInCommerce")).format("MM/DD/YYYY") : "–",
        order: 12,
      });
    }

    if (key === "specimenDescription") {
      data.push({
        label: "Specimen Description",
        value: form[key] || "–",
        order: 13,
      });
    }

    if (key === "website") {
      data.push({
        label: "Website",
        value: form[key] || "–",
        order: 14,
      });
    }
  });

  return data;
};
