import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";

const CancelSubscriptionConfirmModal = ({ productName, onConfirm, onCancel }) => {
  return (
    <Modal centered show onHide={onCancel} size="md">
      <Modal.Header style={{ border: 0 }} />
      <Modal.Body>Are you sure you wish to cancel your {productName} subscription?</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Unsubscribe
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CancelSubscriptionConfirmModal.propTypes = {
  productName: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CancelSubscriptionConfirmModal;
