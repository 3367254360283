import PropTypes from "prop-types";
import React from "react";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { FILE_TYPES, PRODUCT_CODES } from "_constants/products";
import { checkIfFileAllowed, getFileExtension, getFileExtensionsString, validateTrim, validateUrl } from "_helpers";
import { selectFileTypes, selectSpecimenFiles } from "_store/orders/selector";

import FileField from "components/atomic/molecules/fields/FileField";
import TextField from "components/atomic/molecules/fields/TextField";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";
import CardText from "components/layouts/CardText";

const rules = {
  specimenDescription: (formState, hasFiles) => ({
    validate: (value) => {
      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      const specimenFile = formState.specimenFile;

      if (!value && (specimenFile || hasFiles)) {
        return "Please, provide specimen description";
      }

      return true;
    },
  }),
  specimenFile: (formState, hasFiles, fileType, fileExtentions) => ({
    validate: (value) => {
      const specimenDescription = formState.specimenDescription;

      if (specimenDescription && !value && !hasFiles) {
        return "Please, provide specimen file";
      }

      if (value) {
        const selectedFileExt = getFileExtension(value?.name);
        // const isExtensionAllowed = fileType?.allowedExtensions?.includes(selectedFileExt);
        const isExtensionAllowed = checkIfFileAllowed(selectedFileExt, fileType?.allowedExtensions);

        if (!isExtensionAllowed) {
          return "Please select file with correct extension: " + (fileExtentions || "any type");
        }

        if (value?.size && value?.size / 1024 / 1024 > 5) {
          return "File size limit is 5MB";
        }
      }

      return true;
    },
  }),
  website: (formState) => ({
    validate: (value) => {
      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      const website = formState.website;
      if (website && !validateUrl(website)) {
        return "Please provide correct Website URL in format:\nhttps://www.example.com";
      }

      return true;
    },
  }),
};

const StyledCard = styled(Card)`
  gap: 0px;
  width: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  border: none;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  gap: 10px;
  width: 100%;
  padding: 20px 30px;
  overflow-y: unset;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledCardText = styled(CardText)`
  padding-left: 8px;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dbe4f0;
`;

const StyledSpace = styled.div`
  height: 16px;
  width: 100%;

  ${({ size }) =>
    size &&
    css`
    height: &{size}px;
  `}
`;

const TrademarkSpecimenBlock = ({ form }) => {
  const values = useWatch({ control: form?.control });

  const fileTypes = useSelector(selectFileTypes);
  const files = useSelector(selectSpecimenFiles);
  const hasFiles = files?.length > 0;

  const specimenFileType = fileTypes?.[PRODUCT_CODES.incRegisterTrademark]?.[FILE_TYPES.specimen];
  const specimenFileExtensions = getFileExtensionsString(specimenFileType?.allowedExtensions);

  return (
    <StyledCard>
      <StyledCardHeader>Trademark Specimen</StyledCardHeader>
      <StyledCardContent>
        <StyledCardText>
          Our specialists have received your order. To begin creating your application, the USPTO requires a specimen to
          be submitted along with your information. <br />
          <b>What is a specimen?</b>
          <br /> * Real-world application example of how the trademark is used with the goods/services the company
          provides.
          <br /> * Some acceptable specimen examples: Tags, Packaging, magazines, website pages, etc. Once we receive
          your specimen and you have had 24 hours to review your selected search results, we will begin processing your
          application.
        </StyledCardText>
        <StyledSpace />
        <StyledCardText bold>
          Please upload specimen file ({specimenFileExtensions || "any type"}) or provide URL of website (or both)
        </StyledCardText>

        <StyledDivider />

        <StyledCardText>URL of website</StyledCardText>
        <TextField
          column
          name="website"
          placeholder="https://www.example.com"
          control={form.control}
          errors={form.formState.errors}
          rules={rules.website(values)}
        />
        <StyledSpace />
        <StyledCardText bold>OR</StyledCardText>
        <StyledSpace />
        <StyledCardText>Specimen file</StyledCardText>
        <TextField
          column
          name="specimenDescription"
          placeholder="Enter description of the specimen and upload file"
          control={form.control}
          errors={form.formState.errors}
          rules={rules.specimenDescription(values, hasFiles)}
        />
        <FileField
          column
          name="specimenFile"
          accept={specimenFileExtensions}
          control={form.control}
          errors={form.formState.errors}
          rules={rules.specimenFile(values, hasFiles, specimenFileType, specimenFileExtensions)}
        />
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkSpecimenBlock.propTypes = {
  form: PropTypes.object,
};

export default TrademarkSpecimenBlock;
