import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import InputMask from "react-input-mask";
import styled from "styled-components";

const StyledInput = styled(InputMask)`
  box-sizing: border-box;

  width: 100%;
  /* border: 1px solid #bdc6da; */
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 9px 50px 9px 20px;
  outline: none;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
  font-size: 1rem;
  font-weight: 400;
  font-family: inherit;

  &::placeholder {
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors["cl-text-light"]};
  }
`;

// eslint-disable-next-line react/display-name
const InputCustomMask = forwardRef(({ mask, value, ...otherProps }, ref) => {
  return <StyledInput ref={ref} {...otherProps} value={value || ""} mask={mask} maskPlaceholder="_" />;
});

InputCustomMask.propTypes = {
  mask: PropTypes.string,
  value: PropTypes.string,
};

export default InputCustomMask;
