export const LABELS = {
  markDesc: {
    NAME: "Name you wish to protect",
    SLOGAN: "Slogan you wish to protect",
    LOGO: "Please provide any word(s), letter(s), and/or number(s) that appear in your logo",
  },
};

export const PLACEHOLDERS = {
  markDesc: {
    NAME: "Enter the name you wish to protect",
    SLOGAN: "Enter the slogan you wish to protect",
    LOGO: "Enter the description of your logo",
  },
};

export const INVALID_MESSAGES = {
  markDesc: {
    NAME: "You must provide the name",
    SLOGAN: "You must provide the slogan",
    LOGO: "You must provide the description",
  },
};
