import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  /* min-height: 48px; */
  margin-bottom: 16px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

const Values = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  min-width: 160px;
  width: 160px;
  flex-grow: 0;
  font-size: 1rem;
  font-weight: 500;
  text-align: end;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}

  @media (max-width: 991px) {
    text-align: start;
  }
`;

const Value = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: end;
  font-size: 1rem;
  font-weight: 500;

  word-break: break-all;
  white-space: normal;

  ${({ noWordBreak }) =>
    noWordBreak &&
    css`
      word-break: keep-all;
    `}

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  @media (max-width: 991px) {
  }
`;

const Actions = styled.div``;

const CompanyInfoItem = ({ noWordBreak, address, title, value, actions }) => {
  if (address && value !== "N/A") {
    const {
      address1,
      address2,
      city,
      //  country,
      //  county,
      state,
      zip,
    } = address;

    return (
      <StyledInfoItem>
        <Title>{title}:</Title>
        <Values>
          <Value>
            {address1 || "N/A"} <Actions>{actions}</Actions>
          </Value>
          {address2 && <Value>{`${address2}`}</Value>}
          <Value>{`${city ? `${city}, ` : "N/A, "}${state ? `${state} ` : "N/A "}${zip ? `${zip}` : "N/A"}`}</Value>
          {/* <Value>{`${county}`}</Value> */}
          {/* <Value>{`${country}`}</Value> */}
        </Values>
      </StyledInfoItem>
    );
  }

  return (
    <StyledInfoItem>
      <Title>{title}:</Title>
      <Value noWordBreak={noWordBreak}>
        {value} <Actions>{actions}</Actions>
      </Value>
    </StyledInfoItem>
  );
};

CompanyInfoItem.propTypes = {
  address: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actions: PropTypes.any,
  noWordBreak: PropTypes.bool,
};

export default CompanyInfoItem;
