import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { REFILE_ABILITY_ARRAY, REJECTION_REASON_ARRAY } from "_constants/variables";
import { validateTrim } from "_helpers";
import { selectMainProduct } from "_store/orders/selector";

import Button from "components/atomic/atoms/Button";
import TextField from "components/atomic/molecules/fields/TextField";
import AbandonApplicationModal from "components/atomic/organisms/modals/AbandonApplicationModal";
import ChangeAltCompanyNameModal from "components/atomic/organisms/modals/ChangeAltCompanyNameModal";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const Divider = styled.div`
  text-align: center;
  font-size: 1.5rem;
`;

const FieldsGroup = styled.div``;
// const FieldsGroupHeader = styled.div`
//   margin: 0 0 14px 8px;
// `;
const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

// eslint-disable-next-line no-useless-escape
const companyNameRegexp = /^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$/;

const rules = {
  altCompanyName: (previousValue) => ({
    required: "Alternate Company Name is a required field",
    pattern: {
      value: companyNameRegexp,
      message: "Name must not contain invalid characters",
    },
    validate: (value) => {
      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      if (previousValue === value) {
        return "Please, provide a name other than the previous one";
      }

      return true;
    },
  }),
};

const RejectionLetterActionForm = () => {
  const [openAbandonModal, setOpenAbandonModal] = useState(false);
  const [openChangeAltCompanyNameModal, setOpenChangeAltCompanyNameModal] = useState(false);

  const mainProduct = useSelector(selectMainProduct);

  const { rejectionReason, reFileAbility } = mainProduct;

  const initValues = {
    altCompanyName: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: initValues });

  const formValues = useWatch({ control });

  const isAlternateName = rejectionReason === REJECTION_REASON_ARRAY.altName;
  // const isReFileFree = reFileAbility === REFILE_ABILITY_ARRAY.freeReFile;
  const isReFileFreeNotAvailable = reFileAbility === REFILE_ABILITY_ARRAY.freeReFileNotAvailable;

  function onSubmit() {
    setOpenChangeAltCompanyNameModal(true);
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FieldsGroup>
          {/* <FieldsGroupHeader>{"Pick another name and re-file"}</FieldsGroupHeader> */}
          <FieldsGroupContent>
            <TextField
              column
              placeholder="Enter new name..."
              name="altCompanyName"
              control={control}
              errors={errors}
              rules={rules.altCompanyName(mainProduct?.altCompanyName || mainProduct?.companyName)}
            />
            <ButtonsGroup>
              <StyledButton primary disabled={!formValues.altCompanyName} type="submit">
                {/* {isAlternateName && isReFileFree && "Submit"} */}
                {isAlternateName && isReFileFreeNotAvailable ? "Submit & Re-Charge State Filing Fee" : "Submit"}
              </StyledButton>
            </ButtonsGroup>
          </FieldsGroupContent>
        </FieldsGroup>

        <Divider>OR</Divider>

        <FieldsGroup>
          <FieldsGroupContent>
            <ButtonsGroup>
              <StyledButton
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenAbandonModal(true);
                }}
              >
                Abandon Application
              </StyledButton>
            </ButtonsGroup>
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>

      {openAbandonModal && (
        <AbandonApplicationModal
          onCancel={() => {
            setOpenAbandonModal(false);
          }}
        />
      )}
      {openChangeAltCompanyNameModal && (
        <ChangeAltCompanyNameModal
          formValues={formValues}
          resetForm={reset}
          onCancel={() => {
            setOpenChangeAltCompanyNameModal(false);
          }}
        />
      )}
    </>
  );
};

export default RejectionLetterActionForm;
