import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import useIsDesktopSize from "_hooks/useIsDesktopSize";
import {
  selectLoading,
  selectMailFiles,
  selectOrganizedState,
  selectRAProduct,
  selectRaAddress,
  selectRaAddressesLoading,
} from "_store/orders/selector";
import { getFileFromProduct, getRaAddress } from "_store/orders/slice";

import Button from "components/atomic/atoms/Button";
import EmptyMessage from "components/atomic/atoms/EmptyMessage";
import Icon from "components/atomic/atoms/Icon";
import { Spinner } from "components/atomic/atoms/Spinner";
import Table from "components/atomic/molecules/Table";
import DocumentInfoModal from "components/atomic/organisms/modals/DocumentInfoModal";
import Card from "components/layouts/Card";
import CardHeader from "components/layouts/CardHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 20px;

  box-sizing: border-box;
  width: 100%;
  height: 90%;
  max-height: 90%;
  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    height: 100%;
    max-height: 100%;
  }
`;

const StyledCard = styled(Card)`
  gap: 0px;
  /* width: 816px; */
  width: 100%;
  /* max-width: 800px; */
  min-height: 360px;
  padding-bottom: 0;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  padding-left: 10px;
  padding-right: 10px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 10px;
  overflow-y: auto;

  @media (max-width: 991px) {
    padding: 0 10px;
  }
`;

const HeaderText = styled.span`
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;
const Date = styled.div`
  font-style: italic;
`;
const StyledButton = styled(Button)`
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

const MobileTable = styled.div`
  display: flex;
  flex-direction: column;
`;
const MobileItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  border-top: none;
  border-bottom: 1px solid #dbe4f0;

  &:last-child {
    border-bottom: none;
  }
`;
const MobileInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 90%;
`;
const MobileText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;
const MobileAction = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 10%;
  min-width: 80px;
  max-width: 80px;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin: auto;

  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const MobileButton = styled(Button)`
  min-width: 38px;
  max-width: 38px;
  padding: 0;
  border: none;
`;
const Label = styled.span`
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 8px;
  box-shadow: 0 0 5px lightgray inset;
  border-radius: 8px;
  width: 800px;
  max-width: 100%;
`;
const AddressLabel = styled.div`
  flex: 1;
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;
const AddressContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
const AddressNote = styled.div`
  font-weight: normal;
  margin-top: 12px;
  font-size: 0.8rem;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const Values = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const Value = styled.div`
  display: flex;
  /* flex-grow: 1; */
  align-items: end;
  font-size: 1rem;
  font-weight: 500;

  word-wrap: break-word;
  white-space: normal;

  animation: fadeIn 0.5s;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
    ${theme.animations.fadeIn};
  `};
`;

function getMailboxColumns(handler) {
  return [
    {
      dataIndex: "uploaded",
      title: "Date",
      width: "10%",
      minWidth: "120px",
      render: (row) =>
        row?.uploaded && !row?.uploaded?.includes("null") ? (
          <Date>{moment.utc(row?.uploaded).local().format("YYYY/MM/DD HH:mm:ss")}</Date>
        ) : (
          "—"
        ),
    },
    {
      dataIndex: "ourFileName",
      title: "File Name",
      width: "30%",
      minWidth: "200px",
      breakWords: true,
      render: (row) => row?.ourFileName || "—",
    },
    {
      dataIndex: "note",
      title: "File Note",
      width: "50%",
      minWidth: "300px",
      breakWords: true,
      render: (row) => row?.note || "—",
    },
    {
      dataIndex: "download",
      title: "Download",
      width: "10%",
      minWidth: "130px",
      render: (row) => (
        <StyledButton
          outlined
          onClick={(e) => {
            e.stopPropagation();
            handler(row.orderId, row.productId, row.objKey);
          }}
        >
          Download
        </StyledButton>
      ),
    },
  ];
}

const MailboxPage = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktopSize();

  const [showModal, setShowModal] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(null);

  // const { orderDetails } = useSelector(({ orders }) => orders);
  const organizedState = useSelector(selectOrganizedState);
  const raProduct = useSelector(selectRAProduct);
  const raAddress = useSelector(selectRaAddress);

  const files = useSelector(selectMailFiles);
  const loading = useSelector(selectLoading);
  const raAddressLoading = useSelector(selectRaAddressesLoading);

  const downloadHandler = (orderId, productId, fileType) => {
    dispatch(getFileFromProduct({ orderId, productId, fileType }));
  };

  const columns = getMailboxColumns(downloadHandler);

  const { address1, address2, city, state, zip } = raAddress.address || {};

  useEffect(() => {
    if (raProduct) {
      dispatch(getRaAddress({ state: organizedState }));
    }
  }, [dispatch, raProduct, organizedState]);

  return (
    <>
      <Container>
        {raProduct !== null && raProduct?.processingResult?.status !== "Cancelled" && (
          <AddressContainer>
            <AddressContent>
              <AddressLabel>
                <div>Registered Agent Address&nbsp;is:</div>
                <AddressNote>
                  *This address may only be used for offical communications&nbsp;and cannot accept standard mail
                </AddressNote>
              </AddressLabel>
              <Values>
                {raAddressLoading ? (
                  <Spinner />
                ) : raAddress.address ? (
                  <>
                    <Value>{address1 || "N/A"}</Value>
                    {address2 && <Value>{`${address2}`}</Value>}
                    <Value>{`${city ? `${city}, ` : "N/A, "}${state ? `${state} ` : "N/A "}${
                      zip ? `${zip}` : "N/A"
                    }`}</Value>
                    {/* <Value>{`${county}`}</Value> */}
                    {/* <Value>{`${country}`}</Value> */}
                  </>
                ) : (
                  <div>The address is not provided</div>
                )}
              </Values>
            </AddressContent>
          </AddressContainer>
        )}

        <ContentRow>
          <StyledCard>
            <StyledCardHeader>
              <HeaderText>Mailbox</HeaderText>
            </StyledCardHeader>
            <CardContent>
              {Boolean(files.length) && (
                <>
                  {isDesktop ? (
                    <Table columns={columns} rows={files} loading={loading} height="100%" />
                  ) : (
                    <MobileTable>
                      {files.map((file, idx) => (
                        <MobileItem key={`mail-item-${file?.fileKey}-${idx}`}>
                          <MobileInfo>
                            <Date>
                              <Label>{moment.utc(file?.uploaded).local().format("YYYY/MM/DD HH:mm")}</Label>
                            </Date>
                            <MobileText>
                              <Label>Name:</Label> {file?.ourFileName || "—"}
                            </MobileText>
                            <MobileText>
                              <Label>Note:</Label> {file?.note || "—"}
                            </MobileText>
                          </MobileInfo>
                          <MobileAction>
                            <MobileButton
                              outlined
                              width="120px"
                              onClick={() => {
                                setDocumentDetails({
                                  objKey: file?.objKey,
                                  orderId: file?.orderId,
                                  productId: file?.productId,
                                  date: file?.uploaded,
                                  title: file?.type,
                                  subtitle: file?.ourFileName,
                                  note: file?.note,
                                });
                                setShowModal(true);
                              }}
                            >
                              <StyledIcon icon="mailbox:open" />
                            </MobileButton>
                            <MobileButton
                              outlined
                              width="120px"
                              onClick={(e) => {
                                e.stopPropagation();
                                downloadHandler(file?.orderId, file?.productId, file?.objKey);
                              }}
                            >
                              <StyledIcon icon="documents:download" />
                            </MobileButton>
                          </MobileAction>
                        </MobileItem>
                      ))}
                    </MobileTable>
                  )}
                </>
              )}
              {!files.length && <EmptyMessage>Mailbox is empty</EmptyMessage>}
            </CardContent>
          </StyledCard>
        </ContentRow>
      </Container>
      {showModal && (
        <DocumentInfoModal
          documentDetails={documentDetails}
          downloadHandler={(e) => {
            e.stopPropagation();
            downloadHandler(documentDetails.orderId, documentDetails.productId, documentDetails.objKey);
          }}
          onCancel={() => {
            setDocumentDetails(null);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default MailboxPage;
