import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import Select from "components/atomic/atoms/Select";

import FieldWrapper from "../FieldWrapper";

const SelectField = ({ column, label, name, options, control, rules = {}, errors = {}, ...restProps }) => {
  const handleChange = (handler) => (option) => {
    handler({ target: { value: option?.value } });

    if (restProps.clearFields) {
      restProps.clearFields();
    }

    if (restProps.changeFields) {
      restProps.changeFields(option?.value);
    }
  };

  return (
    <FieldWrapper column={column} label={label} name={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, ...restField } }) => (
          <Select
            secondary
            {...restProps}
            {...restField}
            options={options}
            value={options?.find((option) => option?.value === value)}
            onChange={handleChange(onChange)}
          />
        )}
      />
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  options: PropTypes.array,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default SelectField;
