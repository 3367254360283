import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import Table from "components/atomic/molecules/Table";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 700px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

const StyledLabel = styled.span`
  font-weight: 500;
`;

const StyledEmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const getTrademarkDetailsColumns = () => {
  return [
    {
      dataIndex: "label",
      title: "",
      width: "50%",
      render: (row) => <StyledLabel>{row?.label}</StyledLabel>,
    },
    {
      dataIndex: "value",
      title: "",
      width: "50%",
    },
  ];
};

const TrademarkDetails = ({ disabled, loading, data, onEdit }) => {
  const columns = getTrademarkDetailsColumns();
  const isEmpty = !data?.length;

  return (
    <StyledCard>
      <StyledCardHeader>
        <div>Trademark Details</div>
        <StyledButton borderless disabled={disabled} onClick={onEdit}>
          Edit <Icon icon="billing:edit" />
        </StyledButton>
      </StyledCardHeader>
      <StyledCardContent>
        {isEmpty && <StyledEmptyMessage>No details provided</StyledEmptyMessage>}
        {!isEmpty && <Table hiddenHeader columns={columns} rows={data} loading={loading} skeletonRowsNum={6} />}
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkDetails.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onEdit: PropTypes.func,
};

export default TrademarkDetails;
