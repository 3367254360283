import { config } from "_config";
import { mappingQueryParams } from "_helpers";
import { AxiosClient } from "_services/AxiosClient";

const { customerApiUrl } = config;

class CustomerService extends AxiosClient {
  constructor(url) {
    super(url);
    this.setPassword = this.setPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.getMe = this.getMe.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.getPaymentMethods = this.getPaymentMethods.bind(this);
    this.createPaymentMethod = this.createPaymentMethod.bind(this);
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.getUSPTOCategories = this.getUSPTOCategories.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.customerAction = this.customerAction.bind(this);
  }

  setPassword(token, password) {
    const path = "/customers/set-password";
    this.Authorization = `Bearer ${token}`;
    return this.client.post(path, { password });
  }

  resetPassword(email) {
    const path = "/customers/reset-password";
    return this.client.post(path, {}, { params: { email } });
  }

  getMe() {
    const path = "/customers/me";
    return this.client.get(path);
  }

  updateCustomer(customerId, customerInfo) {
    const path = `/customers/${customerId}`;
    return this.client.put(path, customerInfo);
  }

  getOrders({ customerId, ...params }) {
    const path = `/customers/${customerId}/orders`;
    return this.client.get(path, { params });
  }

  getOrder(customerId, orderId) {
    const path = `/customers/${customerId}/orders/${orderId}`;
    return this.client.get(path);
  }

  getPaymentMethods(customerId) {
    const path = `/customers/${customerId}/payment-methods`;
    return this.client.get(path);
  }

  createPaymentMethod({ customerId, cardDetails, updateProductStatus }) {
    const path = `/customers/${customerId}/payment-methods${mappingQueryParams({ updateProductStatus })}`;
    return this.client.post(path, cardDetails);
  }

  getSubscriptions(customerId) {
    const path = `/customers/${customerId}/subscriptions`;
    return this.client.get(path);
  }

  getUSPTOCategories({ termId, search, description, isGoods, page, count }) {
    const path = "/trademarks/categories";
    return new Promise((resolve) => {
      this.client
        .get(path, {
          params: { termId, search, description, isGoods, page, count },
        })
        .then((response) => {
          const data = response?.data ? { ...response?.data } : {};
          resolve({ ...data });
        })
        .catch(() => {
          resolve({ elements: [], maxPerPage: count, totalResults: 0 });
        });
    });
  }

  updateProducts(customerId, products) {
    const path = `/customers/${customerId}/products`;
    return Promise.all(products?.map((product) => this.client.put(`${path}/${product.id}`, product)));
  }

  customerAction({ customerId, productId, actionType, message }) {
    const path = `/customers/${customerId}/products/${productId}/action`;

    const params = {
      actionType,
      message,
    };

    return this.client.post(path, {}, { params });
  }
}

const ServiceInstance = new CustomerService(customerApiUrl);

export { ServiceInstance as CustomerService };
