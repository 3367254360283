import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { config } from "_config";
import { selectPaymentMethods, selectPaymentMethodsLoading } from "_store/billing/selector";

import Icon from "components/atomic/atoms/Icon";
import PlaceholderRow from "components/atomic/atoms/PlaceholderRow";

const ActionDescriptionExtra = styled(Card)`
  position: relative;
  /* background-color: gray; */
  border-radius: 12px;
  width: max-content;
  max-width: 100%;
  padding: 12px;
`;
const ActionDescriptionHeader = styled.div`
  margin-bottom: 12px;

  ${({ theme }) => css`
    border-bottom: 1px solid lightgray;
    /* border-bottom: 1px solid ${theme.colors["cl-text-light"]}; */
    color: ${({ theme }) => theme.colors["cl-text-light"]};
  `}

  ${({ $label }) =>
    $label &&
    css`
      border-bottom: none;
      margin-bottom: 0px;

      &:not(:first-child) {
        margin-top: 16px;
      }
    `}
`;
const ActionDescriptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 6px;

  & div:first-child {
    min-width: 100px;
    max-width: 100px;
    color: ${({ theme }) => theme.colors["cl-text-light"]};
  }

  @media (max-width: 300px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 12px;
    align-items: start;
  }
`;

const ActionExtraDescription = (props) => {
  const { product, error, wasFormSubmitted } = props;

  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethodsLoading = useSelector(selectPaymentMethodsLoading);

  function renderExtraDescriptionInfo() {
    const paymentDetails = paymentMethods?.find((item) => item.primary);
    const cardExpiration =
      paymentDetails?.cardMonth && paymentDetails?.cardYear
        ? `${paymentDetails?.cardMonth}/${paymentDetails?.cardYear}`
        : null;

    function getCardType() {
      if (paymentDetails?.cardType?.toLowerCase() === "testcard") {
        return config.envName !== "prod" ? "Test Card" : "Unknown Card";
      }

      return paymentDetails?.cardType || "Unknown Type";
    }

    switch (error) {
      case "CardDeclined":
        return (
          <ActionDescriptionExtra>
            <ActionDescriptionHeader>Payment Method on File</ActionDescriptionHeader>
            <ActionDescriptionItem>
              <div>Card Type:</div>
              {!wasFormSubmitted && paymentMethodsLoading ? (
                <PlaceholderRow />
              ) : (
                <div>
                  <Icon inline icon={`billing:${paymentDetails?.cardType?.toLowerCase() || "testcard"}`} />
                  &nbsp;{getCardType()}
                </div>
              )}
            </ActionDescriptionItem>
            <ActionDescriptionItem>
              <div>Exp. Date:</div>
              {!wasFormSubmitted && paymentMethodsLoading ? <PlaceholderRow /> : <div>{cardExpiration || "N/A"}</div>}
            </ActionDescriptionItem>
            <ActionDescriptionItem>
              <div>Card Last 4:</div>
              {!wasFormSubmitted && paymentMethodsLoading ? (
                <PlaceholderRow />
              ) : (
                <div>{paymentDetails?.cardNumber || "N/A"}</div>
              )}
            </ActionDescriptionItem>
          </ActionDescriptionExtra>
        );

      case "MismatchError":
        return (
          <ActionDescriptionExtra>
            <ActionDescriptionHeader $label>Name Provided:</ActionDescriptionHeader>
            <ActionDescriptionItem>
              {`${product?.owner?.firstName || "<unknown>"} ${product?.owner?.lastName || "<unknown>"}`}
            </ActionDescriptionItem>

            <ActionDescriptionHeader $label>Last 4 of SSN:</ActionDescriptionHeader>
            <ActionDescriptionItem>{product?.owner?.ssn?.replace(/\D/g, "")?.slice(-4) || "N/A"}</ActionDescriptionItem>
          </ActionDescriptionExtra>
        );

      case "NameConflict":
        return null;

      default:
        return null;
    }
  }

  return renderExtraDescriptionInfo();
};

export default ActionExtraDescription;
