import moment from "moment";

import { codeToTitle } from "_helpers/formatter";

export function getProductFiles(product) {
  if (!product) return null;
  const id = product.id;
  const files = product.processingResult.files;
  return Object.keys(files)
    .map((key) => ({ filename: key, uploaded: files[key].uploaded, productId: id }))
    .flat()
    .sort((cur, next) => {
      const curDate = moment(cur.uploaded).valueOf();
      const nextDate = moment(next.uploaded).valueOf();
      if (Number.isNaN(nextDate) || (Number.isNaN(nextDate) && Number.isNaN(curDate))) return -1;
      if (Number.isNaN(curDate)) return 1;
      return curDate - nextDate;
    });
}

export function getProductsFiles(products, fileTypes) {
  if (!products) return null;

  return products
    .map(({ processingResult: { files }, id, orderId, code }) =>
      Object.keys(files).map((key) => {
        const productFileTypes = fileTypes[code?.code];
        const fileType = files[key].type;
        const fileTypeDetails = productFileTypes[fileType];

        return {
          productId: id,
          orderId: orderId,
          productCode: code?.code,
          filename: key,
          title: codeToTitle(fileTypeDetails.customersName, "_"),
          subtitle: files[key].ourFileName || "",
          note: files[key].note,
          type: fileType,
          fileKey: files[key].fileKey,
          uploaded: files[key].uploaded,
        };
      })
    )
    .flat()
    .filter(({ type }) => type !== "TRADEMARK_MAIL")
    .sort((cur, next) => {
      const curDate = moment(cur.uploaded).valueOf();
      const nextDate = moment(next.uploaded).valueOf();
      if (Number.isNaN(nextDate) || (Number.isNaN(nextDate) && Number.isNaN(curDate))) return -1;
      if (Number.isNaN(curDate)) return 1;
      return curDate - nextDate;
    });
}

export function findProduct(activeOrderDetails, activeOrderUpsells, productCodes, notCancelled) {
  {
    const selectedProduct = activeOrderDetails?.products?.find(({ code: { code } }) => productCodes.includes(code));

    if (
      selectedProduct &&
      (selectedProduct?.paymentStatus !== "Declined" || selectedProduct?.processingResult?.status !== "Lead")
    ) {
      if (!notCancelled) {
        return selectedProduct;
      } else {
        if (selectedProduct?.processingResult?.status !== "Cancelled") {
          return selectedProduct;
        }
      }
    }

    const upsells = [];

    activeOrderUpsells.forEach((upsell, upsellIdx) => {
      upsell.products.find((product, productIdx) => {
        if (productCodes.includes(product.code.code)) {
          upsells.push([upsellIdx, productIdx]);
        }
      });
    });

    if (upsells.length) {
      const selectedUpsellIndexes = upsells.find((upsell) => {
        const [upsellIdx, productIdx] = upsell;
        const currentUpsell = activeOrderUpsells[upsellIdx].products[productIdx];

        if (currentUpsell?.paymentStatus !== "Declined" && currentUpsell?.processingResult?.status !== "Lead") {
          if (!notCancelled) {
            return currentUpsell;
          } else {
            if (currentUpsell?.processingResult?.status !== "Cancelled") {
              return currentUpsell;
            }
          }
        }
      });

      if (selectedUpsellIndexes) {
        const [upsellIdx, productIdx] = selectedUpsellIndexes;
        const upsell = activeOrderUpsells[upsellIdx] || null;
        const product = upsell?.products[productIdx] || null;

        return product;
      }
    }

    return null;
  }
}
