import { config } from "_config";
import { AxiosClient } from "_services/AxiosClient";

const { usersApiUrl } = config;

class AuthService extends AxiosClient {
  constructor(url) {
    super(url);
    this.login = this.login.bind(this);
  }

  login(username, password) {
    const path = "/users/oauth2/token";
    this.Authorization = `Basic ${window.btoa(username + ":" + password)}`;
    return this.client.post(path);
  }
}

const ServiceInstance = new AuthService(usersApiUrl);

export { ServiceInstance as AuthService };
