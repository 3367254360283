import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { handleError } from "_helpers";
import { CustomerService } from "_services";
import { selectActiveOrderId, selectMainProduct } from "_store/orders/selector";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;
  word-wrap: break-word;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`;
const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;
const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
`;
const StyledIcon = styled(Icon)`
  display: inline;
`;

const STEPS = {
  start: "step-start",
  loading: "step-loading",
  success: "step-success",
  error: "step-error",
};

const ChangeAltCompanyNameModal = ({ formValues, resetForm, onCancel }) => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const mainProduct = useSelector(selectMainProduct);

  const [error, setError] = useState(null);
  const [step, setStep] = useState(STEPS.start);

  function handleCancel({ update = false }) {
    onCancel();
    setError(null);
    setStep(STEPS.start);
    update && resetForm();
    update &&
      customerId &&
      orderId &&
      dispatch(getOrder({ customerId, orderId, refreshOrders: true, redirectUrl: "../actions" }));
  }

  async function sendReFileRequest() {
    try {
      const products = [
        {
          ...mainProduct,
          altCompanyName: formValues.altCompanyName,
        },
      ];

      const response = await CustomerService.updateProducts(customerId, products);

      if (response[0]?.status === 200) {
        setStep(STEPS.success);
      } else {
        const processingError = handleError(response) || "";
        setError({ message: processingError || "Unknown Error" });
        setStep(STEPS.error);
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show onHide={handleCancel} size="md">
          <StyledHeader>Re-File Alternate Company Name</StyledHeader>
          <StyledBody>
            <Row>
              Are you sure you wish to re-file with {`"${formValues?.altCompanyName}"` || "this"} alternate company
              name?
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Buttons>
              <Button variant="secondary" onClick={handleCancel}>
                Nevermind
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  setStep(STEPS.loading);
                  sendReFileRequest();
                }}
              >
                Yes, please re-file
              </Button>
            </Buttons>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show onHide={() => {}} size="md">
          <StyledHeader>Re-File Alternate Company Name</StyledHeader>
          <StyledBody>
            <SpinnerBlock message="Sending Request..." />
          </StyledBody>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered show onHide={() => handleCancel({ update: true })} size="md">
          <StyledHeader>Re-File Alternate Company Name</StyledHeader>
          <StyledBody>
            <Row>Thank you! Our specialists will re-file your application with this new name.</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCancel({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show onHide={() => handleCancel({ update: true })} size="md">
          <StyledHeader>Re-File Alternate Company Name</StyledHeader>
          <StyledBody>
            {/* <Row>Please, try again later or contact with our agents.</Row> */}
            {/* <Row>{error?.message ? `Error: ${error?.message}` : ""};</Row> */}
            <Row>
              <ErrorText>
                <StyledIcon icon="error:alert" size="24px" />
                &nbsp;Error:
              </ErrorText>
              <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCancel({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

ChangeAltCompanyNameModal.propTypes = {
  formValues: PropTypes.object,
  resetForm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ChangeAltCompanyNameModal;
