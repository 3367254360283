import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;

  font-size: 0.875rem;
  font-weight: 400;

  ${({ theme }) => css`
    height: 44px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding-left: 120px;
    padding-right: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    outline: none;
    line-height: 24px;
    /* vertical-align: middle; */
    color: ${theme.colors["cl-text-dark"]};
    cursor: pointer;

    &::placeholder {
      color: ${theme.colors["cl-text-light"]};
    }

    &:focus {
      border: 1px solid;
      border-radius: 8px;
    }

    &::file-selector-button {
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid #eaecf0;
      background-color: #eaecf0;
      font-weight: 500;
      color: ${theme.colors["cl-text-dark"]};
      cursor: pointer;
    }

    &.invalid:focus::file-selector-button {
      top: 2px;
      bottom: 2px;
      left: 2px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `}
`;

// eslint-disable-next-line react/display-name, no-unused-vars
const FileInput = forwardRef(({ name, value, onChange, errors, accept, ...restProps }, ref) => {
  const handleChangeFile = (e) => {
    const { files } = e.target;
    onChange && onChange({ target: { name, value: files?.length ? files[0] : null } });
  };

  return (
    <StyledInputContainer>
      <StyledInput
        {...restProps}
        ref={ref}
        type="file"
        accept={accept || ""}
        name={name}
        value={undefined}
        onChange={handleChangeFile}
        className={clsx({ invalid: Boolean(errors[name]) })}
      />
    </StyledInputContainer>
  );
});

FileInput.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default FileInput;
