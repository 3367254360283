import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
  text-decoration: underline;

  &:hover {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
`;

const CustomLink = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

export default CustomLink;

CustomLink.propTypes = {
  children: PropTypes.any,
};
