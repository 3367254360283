import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

import CustomExternalLink from "components/atomic/atoms/CustomExternalLink";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
`;
const Label = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;
const Data = styled.div`
  width: 100%;
  word-break: break-all;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const LicenseInfoModal = ({ columns = [], licenseDetails = {}, onCancel }) => {
  const { url } = licenseDetails;

  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader>License/Permit Details</StyledHeader>
      <Modal.Body>
        {columns
          ?.filter(({ dataIndex }) => dataIndex !== "url")
          ?.map((col) => {
            return (
              <Row key={`mobile-col-details-${col?.dataIndex}`}>
                <Label>{col.title}:</Label>
                <Data>{licenseDetails[col?.dataIndex] || "—"}</Data>
              </Row>
            );
          })}

        <Row>
          <Label>Link:</Label>
          <Data>
            {url ? (
              <CustomExternalLink href={url} target="_blank">
                {url}
              </CustomExternalLink>
            ) : (
              "—"
            )}
          </Data>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LicenseInfoModal.propTypes = {
  onCancel: PropTypes.func,
  licenseDetails: PropTypes.object,
  columns: PropTypes.array,
};

export default LicenseInfoModal;
