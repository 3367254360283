// import { PRODUCT_CODES } from "_constants/products";

const navitems = [
  {
    id: "nav0",
    to: "dashboard/profile",
    text: "My Business",
    icon: "sidebar:mybusiness",
    home: true,
    indicator: "true",
  },
  {
    id: "nav-profile",
    to: "dashboard/profile",
    text: "Company Profile",
    icon: "sidebar:profile",
    separate: "true",
  },
  {
    id: "nav-documents",
    to: "dashboard/documents",
    text: "Company Documents",
    icon: "sidebar:documents",
  },
  {
    id: "nav-actions",
    to: "dashboard/actions",
    text: "Action Required",
    icon: "sidebar:actions",
  },
  {
    id: "nav-licenses",
    to: "dashboard/licenses",
    text: "Licenses And Permits",
    icon: "sidebar:licenses",
    // requiredProduct: PRODUCT_CODES.incBusinessLicense,
  },
  {
    id: "nav-services",
    to: "dashboard/services",
    text: "Additional Services",
    icon: "sidebar:services",
  },
  {
    id: "nav-mailbox",
    to: "dashboard/mailbox",
    text: "Mailbox",
    icon: "sidebar:mailbox",
  },
  {
    id: "nav-trademark",
    to: "dashboard/trademark",
    text: "Trademark",
    icon: "sidebar:trademark",
  },
];

const footeritems = [
  {
    id: "footer-billing",
    to: "settings/billing",
    text: "Billing",
    icon: "sidebar:billing",
  },
  {
    id: "footer-support",
    to: "settings/support",
    text: "Ticket Support",
    icon: "sidebar:support",
  },
];

export { navitems, footeritems };
