import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import TrademarkCategorySelect from "components/atomic/atoms/TrademarkCategorySelect";

import FieldWrapper from "../FieldWrapper";

const TrademarkCategoryField = ({ column, label, name, control, rules = {}, errors = {}, ...restProps }) => {
  return (
    <FieldWrapper column={column} name={name} label={label} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        // eslint-disable-next-line no-unused-vars
        render={({ field: { ref, ...restField } }) => (
          <TrademarkCategorySelect name={name} {...restProps} {...restField} />
        )}
      />
    </FieldWrapper>
  );
};

TrademarkCategoryField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default TrademarkCategoryField;
