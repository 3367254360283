export function currencyFormatter(amount) {
  const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

  return formatter.format(amount);
}

export function codeToTitle(code, divider = null) {
  if (code) {
    if (divider) {
      return code
        .split(divider)
        .map((word) => capitalize(word))
        .join(" ");
    }

    return code;
  }

  return "";
}

export function capitalize(str) {
  if (str) {
    return str[0].toUpperCase() + str.substring(1).toLowerCase();
  }

  return "";
}

export function phoneFormatter(phone) {
  if (phone) {
    const newPhone = phoneCleaner(phone);
    return `(${newPhone.slice(0, 3)}) ${newPhone.slice(3, 6)}-${newPhone.slice(6)}`;
  }
  return;
}

export function phoneCleaner(phone) {
  if (phone) {
    return phone.replace(/\D/g, "");
  }
  return;
}

export const phoneRegexp = new RegExp(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/);
export const phoneNumRegexp = new RegExp(/\d{10}/);
