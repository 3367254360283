const fontFamilies = {
  regular: ['"Poppins"', "sans-serif"].join(","),
  logo: ['"Montserrat"', "sans-serif"].join(","),
};

const fontSizes = {
  small: "14px",
  regular: "16px",
  medium: "18px",
  large: "20px",
  larger: "24px",
  xlarge: "26px",
  xxlarge: "30px",
  xxxlarge: "32px",
};

const fontWeights = {
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
};

export { fontFamilies, fontSizes, fontWeights };
