import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { validatePassword, validateTrim } from "_helpers";
import useIsDesktopSize from "_hooks/useIsDesktopSize";

import Button from "components/atomic/atoms/Button";
import PasswordField from "components/atomic/molecules/fields/PasswordField";
import PhoneField from "components/atomic/molecules/fields/PhoneField";
import TextField from "components/atomic/molecules/fields/TextField";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const rules = {
  firstName: () => ({
    required: "First Name is a required field",
    pattern: {
      value: /^[ a-zA-Z\\&-]+$/i,
      message: "First Name should contain only letters and & \\ - symbols",
    },
    maxLength: {
      value: 30,
      message: "First Name should be less or equal 30 symbols",
    },
    validate: (value) => {
      if (value?.includes("null")) {
        return 'First Name can\'t contain "Null" name';
      }

      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      return true;
    },
  }),
  lastName: () => ({
    required: "Last Name is a required field",
    pattern: {
      value: /^[ a-zA-Z\\&-]+$/i,
      message: "Last Name should contain only letters and & \\ - symbols",
    },
    maxLength: {
      value: 30,
      message: "Last Name should be less or equal 30 symbols",
    },
    validate: (value) => {
      if (value?.includes("null")) {
        return 'Last Name can\'t contain "Null" name';
      }

      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      return true;
    },
  }),
  phone: () => ({
    required: "Phone is a required field",
    validate: (value) => {
      if (value?.includes("_")) {
        return "You must provide a valid phone number";
      }

      return true;
    },
  }),
  email: () => ({
    required: "Email is a required field",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid Email address",
    },
    maxLength: {
      value: 100,
      message: "Email should be less or equal 100 symbols",
    },
  }),
  oldPassword: () => ({
    required: "Current Password is a required field",
    minLength: {
      value: 8,
      message: "Current Password should be more or equal 8 symbols",
    },
    maxLength: {
      value: 120,
      message: "Current Password should be less or equal 120 symbols",
    },
  }),
  newPassword: () => ({
    required: "New Password is a required field",
    minLength: {
      value: 8,
      message: "New Password should be more or equal 8 symbols",
    },
    maxLength: {
      value: 120,
      message: "New Password should be less or equal 120 symbols",
    },
    validate: (value) => {
      if (!validatePassword(value)) {
        return "Password is invalid";
      }
    },
  }),
  confirmPassword: ({ newPassword } = {}) => ({
    required: "Confirm Password is a required field",
    minLength: {
      value: 8,
      message: "Confirm New Password should be more or equal 8 symbols",
    },
    maxLength: {
      value: 120,
      message: "Confirm New Password should be less or equal 120 symbols",
    },
    validate: (value) => {
      if (value !== newPassword && newPassword) {
        return "Password doesn't match the new password";
      }

      return true;
    },
  }),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px;
  box-sizing: border-box;
  max-height: 100%;
  animation: fadeIn 0.5s;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    align-items: center;
  }
`;

const ContentRow = styled.form`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  max-width: 700px;
`;

const StyledCard = styled(Card)`
  gap: 0px;
  width: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  border: none;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  gap: 10px;
  width: 100%;
  padding: 20px 30px;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 0 30px;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  width: 180px;
`;

const SettingsForm = ({ defaultValues = {}, loading, error, handleSubmitAccount, handleSubmitPassword }) => {
  const accountForm = useForm({ defaultValues });
  const passwordForm = useForm();

  const isDesktopSize = useIsDesktopSize();

  useEffect(() => {
    console.log(error);
    const { message } = error || {};

    if (message?.match(/^.*(old|password).*$/i)) {
      passwordForm.setError("oldPassword", { message });
    }
  }, [error]);

  return (
    <Container>
      <ContentRow onSubmit={accountForm.handleSubmit(handleSubmitAccount)}>
        <StyledCard>
          <StyledCardHeader>Account Information</StyledCardHeader>
          <StyledCardContent>
            <TextField
              column={!isDesktopSize}
              label="First Name"
              placeholder="First Name"
              name="firstName"
              control={accountForm.control}
              errors={accountForm.formState.errors}
              rules={rules.firstName()}
            />

            <TextField
              column={!isDesktopSize}
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              control={accountForm.control}
              errors={accountForm.formState.errors}
              rules={rules.lastName()}
            />

            <TextField
              column={!isDesktopSize}
              autocomplete="email"
              label="Email"
              placeholder="Email"
              name="email"
              control={accountForm.control}
              errors={accountForm.formState.errors}
              rules={rules.email()}
            />

            <PhoneField
              column={!isDesktopSize}
              label="Phone"
              placeholder="Phone"
              name="phone"
              control={accountForm.control}
              errors={accountForm.formState.errors}
              rules={rules.phone()}
            />
          </StyledCardContent>

          <Buttons>
            <StyledButton
              secondary
              type="reset"
              disabled={loading}
              onClick={() => {
                accountForm.reset();
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton primary type="submit" disabled={loading}>
              Save
            </StyledButton>
          </Buttons>
        </StyledCard>
      </ContentRow>

      <ContentRow onSubmit={passwordForm.handleSubmit(handleSubmitPassword)}>
        <StyledCard>
          <StyledCardHeader>Change Password</StyledCardHeader>
          <StyledCardContent>
            <PasswordField
              column={!isDesktopSize}
              className="sessionstack-sensitive"
              label="Current Password"
              placeholder="Current Password"
              name="oldPassword"
              control={passwordForm.control}
              errors={passwordForm.formState.errors}
              rules={rules.oldPassword()}
            />

            <PasswordField
              validation
              column={!isDesktopSize}
              className="sessionstack-sensitive"
              label="New Password"
              placeholder="New Password"
              name="newPassword"
              control={passwordForm.control}
              errors={passwordForm.formState.errors}
              rules={rules.newPassword()}
            />

            <PasswordField
              column={!isDesktopSize}
              className="sessionstack-sensitive"
              label="Confirm New Password"
              placeholder="Confirm Password"
              name="confirmPassword"
              control={passwordForm.control}
              errors={passwordForm.formState.errors}
              rules={rules.confirmPassword(passwordForm.getValues())}
            />
          </StyledCardContent>

          <Buttons>
            <StyledButton
              secondary
              type="reset"
              disabled={loading}
              onClick={() => {
                passwordForm.reset();
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton primary type="submit" disabled={loading}>
              Save
            </StyledButton>
          </Buttons>
        </StyledCard>
      </ContentRow>
    </Container>
  );
};

SettingsForm.propTypes = {
  defaultValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  handleSubmitAccount: PropTypes.func,
  handleSubmitPassword: PropTypes.func,
};

export default SettingsForm;
