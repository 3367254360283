import { configureStore } from "@reduxjs/toolkit";
import { reducer as notifications } from "react-notification-system-redux";
import logger from "redux-logger";

import applicationSlice from "./application/slice";
import billingSlice from "./billing/slice";
import ordersSlice from "./orders/slice";
import userSlice from "./user/slice";

export default configureStore({
  reducer: {
    user: userSlice,
    orders: ordersSlice,
    billing: billingSlice,
    notifications,
    application: applicationSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
