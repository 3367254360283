import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import Checkbox from "components/atomic/atoms/Checkbox";

import FieldWrapper from "../FieldWrapper";

const CheckboxField = ({ column, label, name, control, rules = {}, errors = {}, ...restProps }) => {
  return (
    <FieldWrapper column={column} name={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => <Checkbox {...restProps} {...field} label={label} />}
      />
    </FieldWrapper>
  );
};

CheckboxField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default CheckboxField;
