import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
`;
const Label = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;
const Data = styled.div`
  width: 100%;
  /* word-break: break-all; */
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const ErrorDetailsModal = ({ errorDetails = {}, onCancel }) => {
  const { value, description } = errorDetails;

  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader>Error Details</StyledHeader>
      <Modal.Body>
        <Row>
          <Label>Error Name:</Label>
          <Data>{value || "—"}</Data>
        </Row>
        <Row>
          <Label>Description:</Label>
          <Data>{description || "—"}</Data>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ErrorDetailsModal.propTypes = {
  onCancel: PropTypes.func,
  errorDetails: PropTypes.object,
};

export default ErrorDetailsModal;
