import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

import SettingsNav from "components/atomic/molecules/SettingsNavigation";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const SettingsLayout = () => {
  return (
    <Container>
      <ContentContainer>
        <SettingsNav />
        <Outlet />
      </ContentContainer>
    </Container>
  );
};

export default SettingsLayout;
