export function parseQueryString(queryString) {
  const searchParams = new URLSearchParams(queryString);
  const res = {};

  for (const [key, value] of searchParams.entries()) {
    res[key] = value;
  }

  return res;
}

export function getFileExtension(fileName, includeDot = false) {
  const regexp = /(?:\.([^.]+))?$/;
  const extension = regexp.exec(fileName)[0];

  if (extension === ".%EXTENSION%") {
    return "";
  }

  if (extension && !includeDot) {
    return extension.slice(1);
  }

  return extension;
}
