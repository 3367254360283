export function saveFile(response) {
  const name = response.headers["content-disposition"].split("filename=")[1].split(";")[0].replace(/"/g, "");

  const blob = new Blob([response.data]);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;

  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

  return true;
}
