import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { selectPaymentMethods, selectPaymentMethodsLoading, selectSubscriptionsLoading } from "_store/billing/selector";
import { cancelSubscription, createPaymentMethod, getPaymentMethods, getSubscriptions } from "_store/billing/slice";
import {
  selectActiveOrderSubscriptions,
  selectBills,
  selectLoading as selectBillsLoading,
} from "_store/orders/selector";
import { selectCustomerId } from "_store/user/selector";

import RecentBillsListModal from "components/atomic/organisms/modals/RecentBillsListModal";
import PaymentMethods from "components/atomic/organisms/sections/PaymentMethods";
import RecentBills from "components/atomic/organisms/sections/RecentBills";
import Subscriptions from "components/atomic/organisms/sections/Subscriptions";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    align-items: center;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  max-width: 800px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BillingPage = () => {
  const dispatch = useDispatch();
  const customerId = useSelector(selectCustomerId);
  const subscriptions = useSelector(selectActiveOrderSubscriptions);
  const subscriptionsLoading = useSelector(selectSubscriptionsLoading);
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethodsLoading = useSelector(selectPaymentMethodsLoading);
  const bills = useSelector(selectBills);
  const billsLoading = useSelector(selectBillsLoading);

  const [showRecentBillsList, setShowRecentBillsList] = useState(false);

  const handleCancelSubscription = useCallback(
    ({ orderId, productId, productName }) => {
      dispatch(cancelSubscription({ orderId, productId, productName }));
    },
    [dispatch]
  );

  const handleUpdatePaymentMethod = useCallback(
    (cardDetails) => {
      customerId && dispatch(createPaymentMethod({ customerId, cardDetails }));
    },
    [dispatch, customerId]
  );

  useEffect(() => {
    customerId && dispatch(getPaymentMethods({ customerId }));
    customerId && dispatch(getSubscriptions({ customerId }));
  }, [dispatch, customerId]);

  return (
    <>
      <Container>
        <ContentRow>
          <Subscriptions loading={subscriptionsLoading} data={subscriptions} onUnsubscribe={handleCancelSubscription} />
        </ContentRow>
        <ContentRow>
          <PaymentMethods loading={paymentMethodsLoading} data={paymentMethods} onUpdate={handleUpdatePaymentMethod} />
          <RecentBills loading={billsLoading} data={bills} dataMax={3} showRecentBillsList={setShowRecentBillsList} />
        </ContentRow>
      </Container>
      {showRecentBillsList && <RecentBillsListModal bills={bills} onCancel={() => setShowRecentBillsList(false)} />}
    </>
  );
};

export default BillingPage;
