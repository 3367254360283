import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/atomic/atoms/Button";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";

import TrademarkRegistrationBlock from "./TrademarkRegistrationBlock";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 20px;
  box-sizing: border-box;
  max-height: 100%;
  animation: fadeIn 0.5s;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    align-items: center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 700px;
`;

const StyledCard = styled(Card)`
  gap: 0px;
  width: 100%;
  padding-bottom: 0;
`;

const StyledCardContent = styled(CardContent)`
  gap: 10px;
  width: 100%;
  padding: 20px 30px;
  overflow-y: unset;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const EditTrademarkDetailsForm = ({ defaultValues = {}, onSubmit }) => {
  const navigate = useNavigate();
  const form = useForm({ defaultValues, reValidateMode: "onChange" });

  const handleCancel = () => {
    navigate("..");
  };

  return (
    <Container>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <TrademarkRegistrationBlock form={form} />
        <StyledCard>
          <StyledCardContent>
            <Row>
              <Col xs={4}>
                <StyledButton type="button" onClick={handleCancel}>
                  Cancel
                </StyledButton>
              </Col>
              <Col xs={4} />
              <Col xs={4}>
                <StyledButton primary type="submit">
                  Save
                </StyledButton>
              </Col>
            </Row>
          </StyledCardContent>
        </StyledCard>
      </Form>
    </Container>
  );
};

EditTrademarkDetailsForm.propTypes = {
  defaultValues: PropTypes.object,
  error: PropTypes.object,
  loading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default EditTrademarkDetailsForm;
