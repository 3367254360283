import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";

const TWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${({ noHeader }) =>
    noHeader &&
    css`
      border-top: 1px solid #eaecf0;
      border-bottom: 1px solid #eaecf0;
    `}

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

  .column-placeholder {
    height: 40px;
    border-radius: 4px;
    line-height: 40px;
    background: ${({ theme }) => theme.colors["cl-text-light"]};
    width: 100%;
    opacity: 0.2;
  }
`;

const THead = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  justify-content: space-between;

  top: 0;
  position: sticky;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-card-bg"]};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const TBody = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:hover {
    background: rgba(219, 228, 240, 0.25);
  }
`;

const TCol = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid #eaecf0;
  min-height: 44px;
  padding: 4px;

  ${({ breakWords }) =>
    breakWords &&
    css`
      word-break: break-all;
      white-space: normal;
    `}

  ${({ header, theme }) =>
    header &&
    css`
      background-color: ${theme.colors["cl-card-bg"]};
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
`;

const Table = ({
  hiddenHeader = false,
  columns = [],
  rows = [],
  loading = false,
  skeletonRowsNum = 4,
  height,
  maxHeight,
}) => {
  const placeholderClassName = clsx("column-placeholder", { ["animated-placeholder"]: loading });
  const emptyRows = useMemo(() => Array.from({ length: skeletonRowsNum }, () => ({})), [skeletonRowsNum]);

  return (
    <TWrapper height={height} maxHeight={maxHeight} noHeader={hiddenHeader}>
      <THead hidden={hiddenHeader}>
        {columns.map(({ dataIndex, title, width, minWidth }) => (
          <TCol header key={dataIndex} width={width} minWidth={minWidth}>
            {title}
          </TCol>
        ))}
      </THead>
      <TBody>
        {loading &&
          emptyRows.map((_, rowIdx) => (
            <TRow key={rowIdx}>
              {columns.map(({ dataIndex, width, minWidth }, colIdx) => (
                <TCol key={`${dataIndex}-${rowIdx}-${colIdx}`} width={width} minWidth={minWidth}>
                  <div className={placeholderClassName} />
                </TCol>
              ))}
            </TRow>
          ))}
        {!loading &&
          rows.map((row, idx) => (
            <TRow key={idx} className={row.classes || ""}>
              {columns.map(({ dataIndex, width, minWidth, breakWords, render }) => (
                <TCol key={`${dataIndex}-${idx}`} width={width} minWidth={minWidth} breakWords={breakWords}>
                  {render ? render(row, idx) : row[dataIndex]}
                </TCol>
              ))}
            </TRow>
          ))}
      </TBody>
    </TWrapper>
  );
};

Table.propTypes = {
  hiddenHeader: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string.isRequired,
      title: PropTypes.node.isRequired,
      width: PropTypes.string,
      render: PropTypes.func,
    })
  ),
  rows: PropTypes.array,
  loading: PropTypes.bool,
  skeletonRowsNum: PropTypes.number,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default Table;
