import App from "App";
import React from "react";
import { redirect } from "react-router-dom";

import { config } from "_config";
import { PRODUCT_CODES } from "_constants/products";

import AccountPage from "components/atomic/pages/AccountPage";
import ActionDetailsPage from "components/atomic/pages/ActionDetailsPage";
import ActionsPage from "components/atomic/pages/ActionsPage";
import BillingPage from "components/atomic/pages/BillingPage";
import DocumentsPage from "components/atomic/pages/DocumentsPage";
import EditTrademarkClassesPage from "components/atomic/pages/EditTrademarkClassesPage";
import EditTrademarkDetailsPage from "components/atomic/pages/EditTrademarkDetailsPage";
import EditTrademarkSpecimenPage from "components/atomic/pages/EditTrademarkSpecimenPage";
import ErrorBoundary from "components/atomic/pages/ErrorBoundary";
import ForgotPasswordPage from "components/atomic/pages/ForgotPasswordPage";
import LicensesPage from "components/atomic/pages/LicensesPage";
import LoginPage from "components/atomic/pages/LoginPage";
import MailboxPage from "components/atomic/pages/MailboxPage ";
import NotificationsPage from "components/atomic/pages/NotificationsPage";
import ProfilePage from "components/atomic/pages/ProfilePage";
import ServicesPage from "components/atomic/pages/ServicesPage";
import SetPasswordPage from "components/atomic/pages/SetPasswordPage";
import SupportPage from "components/atomic/pages/SupportPage";
import TrademarkMonitoringReportPage from "components/atomic/pages/TrademarkMonitoringReportPage";
import TrademarkPage from "components/atomic/pages/TrademarkPage";
import TrademarkSearchReportPage from "components/atomic/pages/TrademarkSearchReportPage";
import DashboardLayout from "components/layouts/DashboardLayout";
import MainLayout from "components/layouts/MainLayout";
import PageLayout from "components/layouts/PageLayout";
import SettingsLayout from "components/layouts/SettingsLayout";

import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute";
import RequireProduct from "routes/RequireProduct";

const dashboardRoutes = [
  {
    path: config.publicUrl,
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <PageLayout />,
            children: [
              {
                path: "dashboard",
                element: <DashboardLayout />,
                children: [
                  { path: "", loader: () => redirect("profile") },
                  { path: "profile", element: <ProfilePage /> },
                  {
                    path: "documents",
                    element: <DocumentsPage />,
                  },
                  {
                    path: "actions",
                    element: <ActionsPage />,
                  },
                  {
                    path: "action-details/:productId/:type",
                    element: <ActionDetailsPage />,
                  },
                  {
                    path: "services",
                    element: <ServicesPage />,
                  },
                  {
                    path: "mailbox",
                    element: <MailboxPage />,
                  },
                  {
                    path: "licenses",
                    element: <RequireProduct code={PRODUCT_CODES.incBusinessLicense} />,
                    children: [
                      {
                        path: "",
                        element: <LicensesPage />,
                      },
                    ],
                  },
                  {
                    path: "trademark",
                    element: <RequireProduct code={PRODUCT_CODES.incRegisterTrademark} />,
                    children: [
                      {
                        path: "",
                        element: <TrademarkPage />,
                      },
                      {
                        path: "edit/details",
                        element: <EditTrademarkDetailsPage />,
                      },
                      {
                        path: "edit/documents",
                        element: <EditTrademarkSpecimenPage />,
                      },
                      {
                        path: "edit/classes",
                        element: <EditTrademarkClassesPage />,
                      },
                      {
                        path: "report/search",
                        element: <TrademarkSearchReportPage />,
                      },
                      {
                        path: "report/monitoring",
                        element: <TrademarkMonitoringReportPage />,
                      },
                    ],
                  },

                  {
                    path: "notifications",
                    element: <NotificationsPage />,
                  },
                ],
              },
              {
                path: "settings",
                element: <SettingsLayout />,
                children: [
                  { path: "", loader: () => redirect("account") },
                  { path: "account", element: <AccountPage /> },
                  { path: "billing", element: <BillingPage /> },
                  { path: "support", element: <SupportPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <PublicRoute />,
        children: [
          {
            element: <MainLayout />,
            children: [
              { path: "", loader: () => redirect("login") },
              { path: "login", element: <LoginPage /> },
              { path: "forgot-password", element: <ForgotPasswordPage /> },
              { path: "set-password", element: <SetPasswordPage /> },
              { path: "reset-password", element: <SetPasswordPage /> },
            ],
          },
        ],
      },
    ],
  },

  { path: "*", loader: () => redirect(config.publicUrl) },
];

export default dashboardRoutes;
