const MAIN_COLOR = "#444CE7";
const SECONDARY_COLOR = "#444CE7";

const colors = {
  "cl-main": MAIN_COLOR,
  "cl-secondary": SECONDARY_COLOR,

  "cl-text-dark": "#1D2939",
  "cl-text-light": "#667085",
  "cl-text-danger": "#C4320A",
  "cl-text-black": "#101828",

  "cl-active": "#32D583",
  // "cl-active-button-bg": "#32D583",
  "cl-active-button-bg": MAIN_COLOR,
  "cl-active-button-text": "#ffffff",
  "cl-card-border": "#EAECF0",
  "cl-card-bg": "#ffffff",

  "cl-regular-button-color": MAIN_COLOR,

  "cl-item1": "#07cea4",
  "cl-item2": "#fc782c",
  "cl-item3": "#9b54e1",
  "cl-item4": "#fe4a8b",
  "cl-item5": "#524fe1",
  "cl-item6": "#7c8db5",
  "cl-item-default": "#9C2A10",

  /* cl-sidebar-top: #006baa,
  cl-sidebar-bottom: #0093c0, */
  // "cl-sidebar-background": "linear-gradient(0.5turn, #444CE7, #6172F3)",
  "cl-sidebar-background": "#FFFFFF",
  // "cl-sidebar-selected": "#6172F3",
  "cl-sidebar-selected": "#F2F4F7",
  // "cl-sidebar-hovered": "#8098F9",
  "cl-sidebar-hovered": "#EAECF0",
  // "cl-sidebar-text": "#ffffff",
  "cl-sidebar-text": "#667085",
  // "cl-sidebar-indicator": "#32D583",
  "cl-sidebar-indicator": MAIN_COLOR,

  "cl-header-bg": "#ffffff",
  "cl-header-divider": "#98A2B3",

  "cl-content-bg": "#F9FAFB",

  "cl-alert-bg": "#EC4A0A",
  "cl-alert-text": "#ffffff",

  "cl-select-bg": "#F9FAFB",
  "cl-select-border": "#98A2B3",
  "cl-select-hover": "#EAECF0",
  "cl-select-focus": "#667085",

  "cl-indicator-active": "#07CEA4",
  "cl-indicator-processing": "#0181b6",
  "cl-indicator-inactive": "#dc3545",

  "cl-status-gray": "#667085",
  // "cl-status-yellow": "#FDDCAB",
  "cl-status-yellow": "#FFA726",
  "cl-status-red": "#EC4A0A",
  "cl-status-green": "#32D583",
};

export default colors;
