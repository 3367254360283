import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

import StyledButton from "components/atomic/atoms/Button";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
`;
const Label = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;
const Data = styled.div`
  width: 100%;
  /* word-break: break-all; */
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;

const DocumentInfoModal = ({ documentDetails, downloadHandler, onCancel }) => {
  const { uploaded, title, subtitle, note, orderId, productId, filename } = documentDetails;

  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader>Document Details</StyledHeader>
      <Modal.Body>
        <Row>
          <Label>Date:</Label>
          <Data>{uploaded ? moment.utc(uploaded).local().format("YYYY/MM/DD - HH:mm:ss") : "—"}</Data>
        </Row>
        <Row>
          <Label>Type:</Label>
          <Data>{title || "—"}</Data>
        </Row>
        <Row>
          <Label>File Name:</Label>
          <Data>{subtitle || "—"}</Data>
        </Row>
        <Row>
          <Label>Note:</Label>
          <Data>{note || "—"}</Data>
        </Row>
        {downloadHandler && (
          <Row>
            <Label>Download:</Label>
            <StyledButton
              outlined
              width="180px"
              onClick={(e) => {
                e.stopPropagation();
                downloadHandler(orderId, productId, filename);
              }}
            >
              Download File
            </StyledButton>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DocumentInfoModal.propTypes = {
  downloadHandler: PropTypes.func,
  onCancel: PropTypes.func,
  documentDetails: PropTypes.object,
};

export default DocumentInfoModal;
