import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;

  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
    `}

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const CardText = ({ children, bold, ...otherProps }) => {
  return (
    <Container bold={bold} {...otherProps}>
      {children}
    </Container>
  );
};

CardText.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.node,
};

export default CardText;
