import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import styled, { css } from "styled-components";

import { ReactComponent as TriangleIcon } from "./assets/dropdown.svg";

const StyledAsyncSelect = styled(AsyncPaginate)`
  text-align: left;
  width: 100%;
  height: 44px;
  font-size: 0.875rem;
  font-weight: 400;

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ theme, secondary }) => css`
    .react-async-select__control {
      cursor: pointer;
      height: 100%;
      border-radius: 8px;
      outline: none;
      border-color: ${secondary ? "#bdc6da" : "transparent"};
      background-color: ${secondary ? "#FFFFFF" : theme.colors["cl-select-bg"]};
      transition: border-color 0.1s;
      box-shadow: none;
      &:hover {
        border: 1px solid ${theme.colors["cl-select-focus"]};
        /* border-color: ${secondary ? "#bdc6da" : "transparent"}; */
      }
      &--is-focused,
      &:focus {
        border: 1px solid ${theme.colors["cl-select-focus"]};
      }
    }
    .react-async-select__single-value {
      color: ${theme.colors["cl-text-light"]};
    }
    .react-async-select__menu {
      background-color: #f2f5fb;
    }
    .react-async-select__option {
      cursor: pointer;
      background-color: ${theme.colors["cl-select-bg"]};
      color: ${theme.colors["cl-text-light"]};
      &:hover {
        background-color: ${theme.colors["cl-select-hover"]};
      }
    }
    .react-async-select__placeholder {
      color: ${theme.colors["cl-text-light"]};
    }
  `}
`;

const DropdownIndicator = (innerProps) => {
  return (
    <components.DropdownIndicator {...innerProps}>
      <TriangleIcon />
    </components.DropdownIndicator>
  );
};

const AsyncSelect = ({
  refreshKey,
  name,
  disabled,
  secondary,
  loading,
  loadOptions,
  placeholder,
  value = "",
  onChange,
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");

  const handleChange = useCallback(
    (option) => {
      setSearchInputValue("");
      onChange && onChange({ target: { name, value: option?.value } });
    },
    [onChange, name]
  );

  const handleSearchChange = (value) => {
    setSearchInputValue((prevValue) => {
      if ((prevValue?.length === 1 && !value) || value) {
        return value;
      }

      return prevValue;
    });
  };

  return (
    <StyledAsyncSelect
      key={refreshKey}
      classNamePrefix="react-async-select"
      secondary={secondary}
      placeholder={placeholder}
      components={{ DropdownIndicator }}
      isDisabled={disabled}
      isLoading={loading}
      isClearable={!!value}
      additional={{ page: 1 }}
      loadOptions={loadOptions}
      inputValue={searchInputValue}
      debounceTimeout={500}
      value={value ? { label: value, value: value } : { label: placeholder }}
      onInputChange={handleSearchChange}
      onChange={handleChange}
    />
  );
};

AsyncSelect.propTypes = {
  refreshKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  loading: PropTypes.bool,
  loadOptions: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AsyncSelect;
