import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { validateTrim } from "_helpers";
import { selectEINRegisterProduct } from "_store/orders/selector";
import { updateProducts } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import CustomMaskField from "components/atomic/molecules/fields/CustomMaskField";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px 12px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div`
  ${({ $marked }) =>
    $marked &&
    css`
      padding: 12px;
      border-radius: 12px;
      background-color: #f0f0f0;
    `}
`;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

const rules = {
  firstName: () => ({
    required: "First Name is a required field",
    maxLength: {
      value: 30,
      message: "First Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\-&]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  lastName: () => ({
    required: "Last Name is a required field",
    maxLength: {
      value: 30,
      message: "Last Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\-&]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  ssn: () => ({
    pattern: {
      value: /^\d{3}-\d{2}-\d{4}$/i,
      message: "Provide Social Security Number in the required format",
    },
  }),
  prevFirstName: () => ({
    maxLength: {
      value: 30,
      message: "First Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\-&]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
  prevLastName: () => ({
    maxLength: {
      value: 30,
      message: "Last Name should be less or equal 30 symbols",
    },
    pattern: {
      value: /^[ a-zA-Z\-&]{1,30}$/i,
      message:
        "There shouldn't be numbers here, the only punctuation and special characters allowed are hyphen (-), backslash (\\) and ampersand (&)",
    },
    validate: validateTrim,
  }),
};

const initValues = {
  firstName: "",
  lastName: "",
  ssn: "",
  prevFirstName: "",
  prevLastName: "",
};

const MissmatchErrorForm = () => {
  const dispatch = useDispatch();

  const form = useForm({ defaultValues: initValues });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const values = useWatch(form);
  const customerId = useSelector(selectCustomerId);
  const einRegisterProduct = useSelector(selectEINRegisterProduct);
  const einRegisterProductOwner = einRegisterProduct?.owner || {};

  function onSubmit() {
    const products = [
      {
        ...einRegisterProduct,
        owner: {
          ...einRegisterProductOwner,
          firstName: values.firstName || einRegisterProductOwner?.firstName || "",
          lastName: values.lastName || einRegisterProductOwner?.lastName || "",
          ssn: values.ssn || einRegisterProductOwner?.ssn || "",
          prevFirstName: values.prevFirstName || einRegisterProductOwner?.prevFirstName || "",
          prevLastName: values.prevLastName || einRegisterProductOwner?.prevLastName || "",
        },
      },
    ];

    dispatch(
      updateProducts({
        customerId,
        products,
        redirectUrl: "../actions",
      })
    );
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FieldsGroup>
          <FieldsGroupContent>
            <TextField
              column
              name="firstName"
              label="First Name*"
              control={control}
              errors={errors}
              rules={rules.firstName()}
            />
            <TextField
              column
              name="lastName"
              label="Last Name*"
              control={control}
              errors={errors}
              rules={rules.lastName()}
            />
            <CustomMaskField
              column
              label="SSN (if incorrect)"
              placeholder="000-00-0000"
              mask="999-99-9999"
              name="ssn"
              control={control}
              errors={errors}
              rules={rules.ssn()}
            />
          </FieldsGroupContent>
        </FieldsGroup>
        <FieldsGroup $marked>
          <FieldsGroupContent>
            <p>
              <strong>NOTE!</strong>
              <br />
              If you happen to have recently changed your name (due to marriage, divorce, etc.), please include your
              previous name as well.
            </p>
            <TextField
              column
              name="prevFirstName"
              label="Previous First Name"
              control={control}
              errors={errors}
              rules={rules.prevFirstName()}
            />
            <TextField
              column
              name="prevLastName"
              label="Previous Last Name"
              control={control}
              errors={errors}
              rules={rules.prevLastName()}
            />
          </FieldsGroupContent>
        </FieldsGroup>
        <ButtonsGroup>
          <StyledButton primary type="submit" disabled={!values.firstName || !values.lastName}>
            Submit
          </StyledButton>
        </ButtonsGroup>
      </StyledForm>
    </>
  );
};

export default MissmatchErrorForm;
