import colors from "components/Theme/colors";

export function getStatusColor(status) {
  switch (status) {
    // gray
    case "Lead":
    case "Processing":
      return colors["cl-status-gray"];

    // yellow
    case "ActionRequired":
    case "SubmittedToState":
      return colors["cl-status-yellow"];

    // red
    case "Error":
    case "Cancelled":
    case "Dissolved":
    case "Unresolved":
      return colors["cl-status-red"];

    // green
    case "Active":
    case "Completed":
    case "Resolved":
      return colors["cl-status-green"];

    default:
      return colors["cl-text-dark"];
  }
}

export function handleCustomerStatus(status) {
  switch (status) {
    case "ReadyToFile":
      return "Processing";

    default:
      return status;
  }
}
