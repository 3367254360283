export const PRODUCT_CODES = Object.freeze({
  incLLC: "INC_LLCIncorporation",
  incResidentAgent: "INC_ResidentAgent",
  incRegisteredAgent: "INC_RegisteredAgent",
  incRushProcessing: "INC_Rush_Processing",
  incBusinessLicense: "INC_BusinessLicenses",
  incEIN: "INC_EIN",
  incRegisterTrademark: "INC_RegisterTrademark",
  incTrademarkMonitoring: "INC_Trademark_Monitoring",
  incTrademarkBasicSearch: "INC_Trademark_Search_Basic",
  incUsptoFillingFee: "INC_USPTOFilingFee",
  incStateIncorporationFee: "INC_StateIncorporationFee",
  incDissolution: "INC_Dissolution",
  incOperatingAgreement: "INC_OperatingAgreement",
  incBankingResolution: "INC_BankingResolution",
  incDissolutionFilingFee: "INC_DissolutionFilingFee",
  incAnnualCompilanceReport: "INC_AnnualComplianceReport",
  incBoi: "INC_BOI",
  incCreditCardProcessing: "INC_Credit_Card_Processing",
});

export const AMENDMENT_PRODUCTS = Object.freeze({
  incAmendmentBusinessAddress: "INC_Amendment_BusinessAddressChange",
  incAmendmentManager: "INC_Amendment_ManagerChange",
  incAmendmentOffice: "INC_Amendment_OfficeChange",
  incAmendmentRegisteredAgent: "INC_Amendment_RegisteredAgentChange",
  incAmendmentBusinessName: "INC_Amendment_BusinessNameChange",
  incAmendmentForeignBusinessName: "INC_Amendment_ForeignBusinessNameChange",
  incAmendmentAllOtherAmendments: "INC_Amendment_AllOtherAmendments",
});

export const FILE_TYPES = Object.freeze({
  incStateForm: "INC_STATE_FORM",
  mail: "TRADEMARK_MAIL",
  einPDF: "EIN_PDF",
  draftOfApplication: "TRADEMARK_DRAFT_OF_APPLICATION",
  approvalDenialUspto: "TRADEMARK_APPROVAL_DENIAL_USPTO",
  completedRegistrationDocument: "TRADEMARK_COMPLETED_REGISTRATION_DOCUMENT",
  noticeOfAllowance: "TRADEMARK_NOTICE_OF_ALLOWANCE",
  noticeOfAbandonment: "TRADEMARK_NOTICE_OF_ABANDONMENT",
  specimen: "TRADEMARK_SPECIMEN",
  logo: "TRADEMARK_LOGO",
  boiId: "BOI_ID",
});

export const CUSTOM_ACTION_CODES = Object.freeze({
  boiOffer: "BOI_OFFER",
});
