import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import Table from "components/atomic/molecules/Table";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 100%;

  @media (max-width: 991px) {
    max-width: 700px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

const StyledEmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const getTrademarkClassesColumns = () => {
  return [
    {
      dataIndex: "goods",
      title: "TYPE",
      width: "10%",
      minWidth: "70px",
      render: (row) => <b>{row?.goods ? "Good" : "Service"}</b>,
    },
    {
      dataIndex: "goodsCategory",
      title: "CATEGORY",
      width: "30%",
      minWidth: "200px",
    },
    {
      dataIndex: "description",
      title: "ADDITIONAL INFO",
      width: "30%",
      minWidth: "120px",
      render: (row) => <span>{row?.description || "Not required"}</span>,
    },
    {
      dataIndex: "goodsServiceDetail",
      title: "DETAILS",
      width: "30%",
      minWidth: "200px",
    },
  ];
};

const TrademarkClasses = ({ disabled, loading, data, onEdit }) => {
  const columns = getTrademarkClassesColumns();
  const isEmpty = data?.length === 1 && !data[0].goodsCategory;

  return (
    <StyledCard>
      <StyledCardHeader>
        <div>Trademark Classes</div>
        <StyledButton borderless disabled={disabled} onClick={onEdit}>
          Edit <Icon icon="billing:edit" />
        </StyledButton>
      </StyledCardHeader>
      <StyledCardContent>
        {isEmpty && <StyledEmptyMessage>No classes chosen</StyledEmptyMessage>}
        {!isEmpty && <Table columns={columns} rows={data} loading={loading} skeletonRowsNum={6} />}
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkClasses.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      goods: PropTypes.bool,
      goodsCategory: PropTypes.string,
      description: PropTypes.string,
      goodsServiceDetail: PropTypes.string,
    })
  ),
  onEdit: PropTypes.func,
};

export default TrademarkClasses;
