import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { getStatusColor } from "_helpers/profile";
import {
  selectActiveOrderId,
  selectCompanyName,
  selectLoading,
  selectProcessingStatusesObj,
  selectProductName,
  selectTrademarkProcessingStatus,
} from "_store/orders/selector";

import { Spinner } from "components/atomic/atoms/Spinner";
import CompanyTitle from "components/atomic/molecules/CompanyTitle";
import Card from "components/layouts/Card";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

// const DisableLayout = styled(Card)`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   background-color: #77777775;
//   box-shadow: 0 0 15px 25px #77777775;
//   border: none;
// `;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto auto;
  background: none;
  max-width: 33.333%;
  min-width: 220px;
  min-height: 200px;
  padding: 15px 25px;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.125rem;
  border: none;
`;

const DashboardLayout = () => {
  const themeContext = useTheme();
  const orderId = useSelector(selectActiveOrderId);
  const companyName = useSelector(selectCompanyName);
  const productName = useSelector(selectProductName);
  const loading = useSelector(selectLoading);
  const trademarkProcessingStatus = useSelector(selectTrademarkProcessingStatus);
  const processingStatusesObj = useSelector(selectProcessingStatusesObj);

  const statusColor = useMemo(() => {
    if (!trademarkProcessingStatus) {
      return themeContext.colors["cl-text-dark"];
    }

    return getStatusColor(trademarkProcessingStatus);
  }, [trademarkProcessingStatus, themeContext]);

  return (
    <Container>
      <ContentContainer>
        {loading ? (
          <StyledCard>
            <Spinner />
          </StyledCard>
        ) : orderId ? (
          <>
            <CompanyTitle
              title={companyName || "N/A"}
              type={productName || "N/A"}
              statusColor={statusColor}
              status={processingStatusesObj[trademarkProcessingStatus]?.statusName || "N/A"}
            />
            <Outlet />
          </>
        ) : (
          // <DisableLayout>
          <StyledCard width="50%">Please, select company</StyledCard>
          // </DisableLayout>
        )}
      </ContentContainer>
    </Container>
  );
};

export default DashboardLayout;
