import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { config } from "_config";
import { selectLoggedIn } from "_store/user/selector";

function PublicRoute() {
  const loggedIn = useSelector(selectLoggedIn);

  return loggedIn ? <Navigate to={config.publicUrl + "/dashboard"} /> : <Outlet />;
}

PublicRoute.propTypes = {
  children: PropTypes.node,
};

export default PublicRoute;
