import React from "react";
import { Link, useRouteError } from "react-router-dom";
import styled from "styled-components";

import Icon from "components/atomic/atoms/Icon";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";

// import CardHeader from "components/layouts/CardHeader";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const StyledCard = styled(Card)`
  width: 50%;
  min-width: 300px;
  min-height: 300px;
  box-shadow: 0 0 15px 0 lightgray;
`;
// const StyledHeaderCard = styled(CardHeader)``;
const StyledContentCard = styled(CardContent)``;
const ContentRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  text-align: center;
  font-size: 1.425rem;
`;
const TextBox = styled(Link)`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  background-color: #e7e7e7;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;
const ErrorMessage = styled.textarea`
  padding: 4px;
  width: 100%;
  font-size: 12px;
  font-family: "Courier New", Courier, monospace;
  min-height: 50px;
  max-height: 200px;
`;

const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);

  return (
    <Container>
      <StyledCard>
        <StyledContentCard>
          <ContentRow>
            <Icon rounded size="120px" padding="20px" color="#ff0000" icon="error:alert" />
          </ContentRow>
          <ContentRow>An unexpected error has occurred</ContentRow>
          <ContentRow>
            <ErrorMessage readOnly value={error?.toString()} />
          </ContentRow>
          <ContentRow>
            <TextBox relative="path" to=".">
              Reload the page
            </TextBox>
          </ContentRow>
          <ContentRow>OR</ContentRow>
          <ContentRow>
            <TextBox relative="path" to="..">
              Go to main page
            </TextBox>
          </ContentRow>
        </StyledContentCard>
      </StyledCard>
    </Container>
  );
};

export default ErrorBoundary;
