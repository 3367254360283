import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { handleError } from "_helpers";
import { OrdersService } from "_services";
import { selectActiveOrderId } from "_store/orders/selector";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}

    ${({ textCentered }) =>
    textCentered &&
    css`
      text-align: center;
    `}
`;

const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
  word-wrap: break-word;
`;

const StyledButton = styled(Button)`
  min-width: 80px;

  &:last-child {
    margin-left: auto;
  }
`;

const STEPS = {
  init: "step-init",
  start: "step-start",
  loading: "step-loading",
  success: "step-success",
  error: "step-error",
};

const AnnualCompilanceReportUpsell = ({ onCancel }) => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);

  const [step, setStep] = useState(STEPS.start);
  const [error, setError] = useState(null);

  function handleCloseModal({ update = false }) {
    setError(null);
    setStep(STEPS.init);
    onCancel();
    // update && dispatch(requestUpdate("REFRESH"));
    update && dispatch(getOrder({ orderId, customerId, refreshOrders: true }));
  }

  async function createUpsell() {
    try {
      setStep(STEPS.loading);

      const request = await OrdersService.chargeWorryFreeCompliance(orderId);

      if (request?.status === 200) {
        if (request?.data?.success === true) {
          setStep(STEPS.success);
        } else {
          let message = "Unknown Error";
          if (request?.data?.message === "Card Declined") {
            message = "Uh oh - your card on file has been declined. Please update your payment method to proceed";
          }
          setError({ message });
          setStep(STEPS.error);
        }
      } else {
        setStep(STEPS.error);
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: false })}>
          <Modal.Header closeButton />
          <StyledBody>
            <Row textCentered>Would you like to subscribe to our Worry Free Compliance Service?</Row>
            <Row textCentered>
              This ensures that your business stays compliant with the state - and includes the filing of your annual
              report
              <br />
              (State fees not included)
            </Row>
            <Row textCentered>
              Only <b>$175</b> Annually
            </Row>
          </StyledBody>
          <Modal.Footer>
            <StyledButton variant="primary" style onClick={createUpsell}>
              Yes
            </StyledButton>
            <StyledButton variant="secondary" style onClick={() => handleCloseModal({ update: false })}>
              No
            </StyledButton>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => {}}>
          <StyledBody>
            <SpinnerBlock message="Sending Request..." />
          </StyledBody>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader closeButton></StyledHeader>
          <StyledBody>
            <Row>
              <Icon inline icon="misc:success-check" size="32px" color="#32D583" />
              &nbsp;Thank you! Your Worry Compliance subscription has successfully been placed.
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: false })}>
          <StyledHeader closeButton></StyledHeader>
          <StyledBody>
            <Row>
              <ErrorText>
                <Icon inline icon="error:alert" size="24px" />
                &nbsp;Error:
              </ErrorText>
              <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal({ update: false })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

AnnualCompilanceReportUpsell.propTypes = {
  onCancel: PropTypes.func,
};

export default AnnualCompilanceReportUpsell;
