import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { selectBoiProduct } from "_store/orders/selector";
import { updateProducts } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import CustomMaskField from "components/atomic/molecules/fields/CustomMaskField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div``;

// const FieldsGroupHeader = styled.div`
//   text-align: center;
//   margin: 0 0 24px 0;
// `;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

const rules = {
  ssn: () => ({
    required: "Social Security Number is a required field",
    pattern: {
      value: /^\d{3}-\d{2}-\d{4}$/i,
      message: "Provide Social Security Number in the required format",
    },
  }),
};

const initValues = {
  dateOfBirthMonth: null,
  dateOfBirthDay: null,
  dateOfBirthYear: null,
  boiIdFile: null,
  ssn: null,
  driverLicenceOrID: null,
};

const SSNNeededActionForm = () => {
  const dispatch = useDispatch();

  const form = useForm({ defaultValues: initValues });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const values = useWatch(form);

  const boiProduct = useSelector(selectBoiProduct);
  const customerId = useSelector(selectCustomerId);

  function onSubmit() {
    const products = [
      {
        ...boiProduct,
        ssn: values.ssn || null,
      },
    ];

    dispatch(
      updateProducts({
        customerId,
        products,
        redirectUrl: "../actions",
      })
    );
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FieldsGroup>
          <FieldsGroupContent>
            <CustomMaskField
              column
              label="Social Security Number"
              placeholder="000-00-0000"
              mask="999-99-9999"
              name="ssn"
              control={control}
              errors={errors}
              rules={rules.ssn()}
            />
            <ButtonsGroup>
              <StyledButton primary type="submit" disabled={!values.ssn}>
                Send
              </StyledButton>
            </ButtonsGroup>
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
};

export default SSNNeededActionForm;
