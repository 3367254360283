import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useFieldArray, useWatch } from "react-hook-form";
import styled from "styled-components";

import { validateTrim } from "_helpers";

import Button from "components/atomic/atoms/Button";
import SwitchField from "components/atomic/molecules/fields/SwitchField";
import TextField from "components/atomic/molecules/fields/TextField";
import TrademarkCategoryField from "components/atomic/molecules/fields/TrademarkCategoryField";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";
import CardText from "components/layouts/CardText";

const getTextFromBrackets = (textFragment) => {
  const result = textFragment?.match(/\{([^)]+)\}/);
  return result ? result[1] : "";
};

const rules = {
  goodsCategory: (options) => ({
    required: "Please choose Category",
    validate: (value) => {
      const { values, fieldName } = options || {};

      const categories = values[fieldName]?.map((item) => {
        return item.goodsCategory;
      });

      if (categories.filter((category) => category === value).length > 1) {
        return "Duplication of categories is not allowed";
      }

      return true;
    },
  }),
  description: () => ({
    required: "Please provide additional description",
    maxLength: {
      value: 90,
      message: "Position should be less or equal 90 symbols",
    },
    validate: validateTrim,
  }),
};

const StyledCard = styled(Card)`
  gap: 0px;
  width: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  border: none;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  gap: 10px;
  width: 100%;
  padding: 20px 30px;
  overflow-y: unset;

  @media (max-width: 991px) {
    padding: 12px;
  }
`;

const StyledCardText = styled(CardText)`
  padding-left: 8px;
`;

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dbe4f0;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-top: 16px;
`;

const TrademarkClassesBlock = ({ form }) => {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "classes",
  });

  const values = useWatch({ control: form.control });

  return (
    <StyledCard>
      <StyledCardHeader>Choose Your Trademark Class</StyledCardHeader>
      <StyledCardContent>
        <CardText>
          Start describing the goods and services related to your mark. Trademark Genius compares the description you
          provide and automatically provides descriptions from the USPTO ID Manual for your consideration.
        </CardText>
        {fields.map((item, index) => (
          <Fragment key={item.id}>
            <StyledDivider />
            <SwitchField
              column
              name={`classes.${index}.goods`}
              label="Do you sell, or intend to sell, a goods or service?"
              labelOff="Services"
              labelOn="Goods"
              control={form.control}
              errors={form.formState.errors}
            />
            <StyledCardText bold>
              Which of these categories best describes the industry or way in which you will use your mark?
            </StyledCardText>
            <StyledCardText>
              A specialist will reach out to you if they find that there is a better suiting category.
            </StyledCardText>
            <TrademarkCategoryField
              column
              secondary
              name={`classes.${index}.goodsCategory`}
              placeholder="Start typing to search categories…"
              goods={`${values.classes[index] && values.classes[index].goods}`}
              control={form.control}
              errors={form.formState.errors}
              rules={rules.goodsCategory({ fieldName: "classes", values })}
            />
            {values.classes[index] && values.classes[index].goodsCategory?.includes("{") && (
              <>
                <StyledCardText>
                  Please&nbsp;
                  {getTextFromBrackets(values.classes[index].goodsCategory)}
                </StyledCardText>
                <TextField
                  column
                  name={`classes.${index}.description`}
                  placeholder="Enter additional description"
                  control={form.control}
                  errors={form.formState.errors}
                  rules={rules.description()}
                />
              </>
            )}
            <StyledRow>
              <Col xs={4}>
                {index === fields.length - 1 && (
                  <StyledButton
                    primary
                    onClick={() =>
                      append({
                        goods: true,
                        goodsCategory: null,
                        description: null,
                      })
                    }
                  >
                    Add
                  </StyledButton>
                )}
              </Col>
              <Col xs={4} />
              <Col xs={4}>{index > 0 && <StyledButton onClick={() => remove(index)}>Remove</StyledButton>}</Col>
            </StyledRow>
          </Fragment>
        ))}
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkClassesBlock.propTypes = {
  form: PropTypes.object,
};

export default TrademarkClassesBlock;
