import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  .column-placeholder {
    height: 1.5rem;
    border-radius: 4px;
    line-height: 40px;
    background: ${({ theme }) => theme.colors["cl-text-light"]};
    width: 100%;
    opacity: 0.2;
  }
`;

const PlaceholderRow = ({ loading = true }) => {
  const placeholderClassName = clsx("column-placeholder", { ["animated-placeholder"]: loading });

  return (
    <Container>
      <div className={placeholderClassName}></div>
    </Container>
  );
};

PlaceholderRow.propTypes = {
  loading: PropTypes.bool,
};

export default PlaceholderRow;
