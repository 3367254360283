import { AGGREGATED_PROCESSING_STATUS } from "_constants/processing";

export function getAggregatedProcessingStatus(processingResult) {
  const { status } = processingResult || {};

  if (status === "CardDeclined") {
    return AGGREGATED_PROCESSING_STATUS.declined;
  }

  if (status === "Cancelled") {
    return AGGREGATED_PROCESSING_STATUS.cancelled;
  }

  return AGGREGATED_PROCESSING_STATUS.active;
}
