import { config } from "_config";
import { AxiosClient } from "_services/AxiosClient";

const { ordersApiUrl } = config;

class VariantsService extends AxiosClient {
  constructor(url) {
    super(url);
    this.getProcessingStatuses = this.getProcessingStatuses.bind(this);
  }

  getProcessingStatuses(category) {
    const path = `/category/${category}/processing-statuses-2`;
    return this.client.get(path);
  }

  getProcessingErrors(category) {
    const path = `/category/${category}/processing-errors`;
    return this.client.get(path);
  }

  getStates(category) {
    const path = `/category/${category}/states`;
    return this.client.get(path);
  }

  getProductFileTypes(code) {
    const path = `/category/products/${code}/file-types`;
    return this.client.get(path);
  }

  getPrices({ category = "INC", ...params } = {}) {
    const path = `/category/${category}/products/prices`;
    return this.client.get(path, { params });
  }

  calculateFees({ state, codes = [], ...params } = {}) {
    const path = "/category/products/calculate-price";
    return Promise.all(codes.map((code) => this.client.post(path, { code, state }, { params })));
  }

  getRaAddress(params) {
    const path = `/resident-agents/addresses`;
    return this.client.get(path, { params });
  }
}

const ServiceInstance = new VariantsService(ordersApiUrl);

export { ServiceInstance as VariantsService };
