import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { phoneCleaner } from "_helpers";
import { selectError, selectLoading, selectDetails as selectUserDetails } from "_store/user/selector";
import { updateMe } from "_store/user/slice";

import SettingsForm from "components/atomic/organisms/forms/SettingsForm";

const AccountPage = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);

  const initValues = {
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    email: userDetails?.email,
    phone: phoneCleaner(userDetails?.phone),
  };

  const handleSubmitAccount = useCallback(
    (values) => {
      const newDetails = values || {};

      if (newDetails.phone) {
        newDetails.phone = phoneCleaner(newDetails.phone);
      }

      const { uid: customerId, ...oldDetails } = userDetails || {};

      const customerInfo = {
        ...oldDetails,
        ...newDetails,
      };

      dispatch(updateMe({ customerId, customerInfo }));
    },
    [dispatch, userDetails]
  );

  const handleSubmitPassword = useCallback(
    (values) => {
      // eslint-disable-next-line no-unused-vars
      const { oldPassword, newPassword } = values || {};
      const { uid: customerId, ...oldDetails } = userDetails || {};

      const customerInfo = {
        oldPassword,
        newPassword,
        ...oldDetails,
      };

      dispatch(updateMe({ customerId, customerInfo }));
    },
    [dispatch, userDetails]
  );

  return (
    <SettingsForm
      defaultValues={initValues}
      loading={loading}
      error={error}
      handleSubmitAccount={handleSubmitAccount}
      handleSubmitPassword={handleSubmitPassword}
    />
  );
};

export default AccountPage;
