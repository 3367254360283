import React from "react";
import styled from "styled-components";

import { config } from "_config";
import { openExternalURL } from "_helpers";

import shield from "assets/img/trademark_shield.svg";

import Button from "components/atomic/atoms/Button";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(8px);
`;
const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid #454cdf;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 48px 32px;
  margin: 0 12px;
  min-width: 33.333%;

  animation: fadeIn 0.5s;
  ${({ theme }) => theme.animations.fadeIn}
`;
const CardContent = styled.div`
  width: 100%;
  height: 100%;
`;
const StyledCardHeader = styled.div`
  margin-bottom: 12px;
  font-size: 1.125rem;
  text-align: center;
`;
const StyledCardBody = styled.div`
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 32px;
`;
const HeaderRow = styled.div``;
const TextRow = styled.div`
  margin-bottom: 12px;
`;
const StyledCardFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled(Button)`
  background: #454cdf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;
const BackgroundImage = styled.img`
  position: absolute;
  bottom: 10px;
  right: 32px;
  width: 110px;
  height: 150px;

  @media (max-width: 991px) {
    top: -55px;
    right: calc(50% - 50px);
    width: 90px;
    height: 100px;
  }
`;

const TrademarkUpsellOverlay = () => {
  return (
    <Container>
      <StyledCard>
        <BackgroundImage src={shield} />
        <CardContent>
          <StyledCardHeader>
            <HeaderRow>You haven&rsquo;t trademarked your business yet...</HeaderRow>
            <HeaderRow>It&rsquo;s not too late!</HeaderRow>
          </StyledCardHeader>
          <StyledCardBody>
            <TextRow>Trademarking your business name, logo, or slogan is a great way to safeguard business</TextRow>
            <TextRow>
              We can help trademark your business for just <b>$199</b>
            </TextRow>
          </StyledCardBody>
          <StyledCardFooter>
            <StyledButton
              primary
              onClick={() => {
                openExternalURL(`${config.incorpifiedUiUrl}/trademarking`);
              }}
            >
              Protect Your Business Now
            </StyledButton>
          </StyledCardFooter>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default TrademarkUpsellOverlay;
