import { createSelector } from "@reduxjs/toolkit";

export const selectLoading = (state) => Boolean(state.user.loading);
export const selectLoggedIn = (state) => state.user.loggedIn;
export const selectError = (state) => state.user.error;
export const selectDetails = (state) => state.user.details;
export const selectCustomerId = (state) => state.user.details?.uid;
export const selectResetPasswordStatus = (state) => state.user.resetPasswordStatus;
export const selectSetPasswordStatus = (state) => state.user.setPasswordStatus;

export const selectContactData = createSelector([selectDetails], ({ email, phone, firstName, lastName }) => ({
  email,
  phone,
  name: `${firstName} ${lastName}`,
}));
