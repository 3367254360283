const { useEffect, useState } = require("react");

function testIsDesktop() {
  if (typeof window === "undefined") {
    return true;
  }
  return window.innerWidth > 991;
}

function useIsDesktopSize() {
  const [isDesktopSize, setIsDesktopSize] = useState(testIsDesktop);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    function autoResize() {
      setIsDesktopSize(testIsDesktop());
    }

    window.addEventListener("resize", autoResize);

    autoResize();

    return () => window.removeEventListener("resize", autoResize);
  }, []);

  return isDesktopSize;
}

export default useIsDesktopSize;
