import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  width: 100%;
  height: 66px;
  padding: 20px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #dbe4f0;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const CardHeader = ({ children, ...otherProps }) => {
  return <Container {...otherProps}>{children}</Container>;
};

CardHeader.propTypes = {
  children: PropTypes.node,
};

export default CardHeader;
