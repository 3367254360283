import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  width: 30%;
  font-weight: 500;
  min-width: 200px;
  min-height: 80px;
  margin: auto auto;
  color: ${({ theme }) => theme.colors["cl-text-dark"] || "gray"};
  /* box-shadow: 0 0 8px 2px lightgray inset; */
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 4px 0px inset;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ $text }) =>
    $text &&
    css`
      padding: 16px;
    `}
`;

const EmptyMessage = ({ children, full, $text }) => {
  if (full) {
    return (
      <Wrapper>
        <Container>{children}</Container>
      </Wrapper>
    );
  }

  if ($text) {
    return <Wrapper $text={$text}>{children}</Wrapper>;
  }

  return <Container>{children}</Container>;
};

export default EmptyMessage;

EmptyMessage.propTypes = {
  children: PropTypes.any,
  full: PropTypes.bool,
  $text: PropTypes.bool,
};
