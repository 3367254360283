import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";

import Paginate from "components/atomic/molecules/Paginate";
import Table from "components/atomic/molecules/Table";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 100%;

  @media (max-width: 991px) {
    max-width: 700px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledEmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const StyledTitle = styled.div`
  padding-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dbe4f0;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledFieldLabel = styled.div`
  margin-bottom: 4px;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-black"]};
  `}
`;

const StyledFieldValue = styled.div`
  margin-bottom: 16px;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const StyledSpace = styled.div`
  height: 16px;
  width: 100%;

  ${({ size }) =>
    size &&
    css`
    height: &{size}px;
  `}
`;

const getTrademarkClassificationColumns = () => {
  return [
    {
      dataIndex: "internationalCode",
      title: "INTERNATIONAL CODE",
      width: "15%",
      minWidth: "130px",
      render: (row) =>
        row?.internationalCode?.reduce((res, code, idx) => {
          if (idx === 0) {
            res = code;
          } else {
            res += `, ${code}`;
          }

          return res;
        }, ""),
    },
    {
      dataIndex: "usCode",
      title: "US CODE",
      width: "15%",
      minWidth: "80px",
      render: (row) =>
        row?.usCode?.reduce((res, code, idx) => {
          if (idx === 0) {
            res = code;
          } else {
            res += `, ${code}`;
          }

          return res;
        }, ""),
    },
    {
      dataIndex: "statusCode",
      title: "STATUS CODE",
      width: "10%",
      minWidth: "80px",
    },
    {
      dataIndex: "statusDate",
      title: "STATUS DATE",
      width: "15%",
      minWidth: "120px",
    },
    {
      dataIndex: "firstUseAnywhereDate",
      title: "FIRST USE ANYWHERE DATE",
      width: "15%",
      minWidth: "140px",
    },
    {
      dataIndex: "firstUseInCommerceDate",
      title: "FIRST USE IN COMMERCE DATE",
      width: "15%",
      minWidth: "140px",
    },
    {
      dataIndex: "primaryCode",
      title: "PRIMARY CODE",
      width: "15%",
      minWidth: "80px",
    },
  ];
};

const getTrademarkOwnerColumns = () => {
  return [
    {
      dataIndex: "partyName",
      title: "PARTY NAME",
      width: "20%",
      minWidth: "160px",
    },
    {
      dataIndex: "partyType",
      title: "PARTY TYPE",
      width: "15%",
      minWidth: "80px",
    },
    {
      dataIndex: "legalEntityTypeCode",
      title: "LEGAL ENTITY TYPE",
      width: "15%",
      minWidth: "80px",
    },
    {
      dataIndex: "address",
      title: "ADDRESS",
      width: "50%",
      minWidth: "260px",
      render: (row) => {
        let address = `${row?.address1}`;

        if (row?.address2) {
          address += `, ${row?.address2}`;
        }

        if (row?.city) {
          address += `, ${row?.city}`;
        }

        if (row?.country) {
          address += `, ${row?.country}`;
        }

        if (row?.postcode) {
          address += `, ${row?.postcode}`;
        }

        return address;
      },
    },
  ];
};

const getTrademarkEventColumns = () => {
  return [
    {
      dataIndex: "code",
      title: "CODE",
      width: "15%",
      minWidth: "80px",
    },
    {
      dataIndex: "type",
      title: "TYPE",
      width: "15%",
      minWidth: "80px",
    },
    {
      dataIndex: "descriptionText",
      title: "DESCRIPTION",
      width: "40%",
      minWidth: "260px",
    },
    {
      dataIndex: "date",
      title: "DATE",
      width: "15%",
      minWidth: "120px",
    },
    {
      dataIndex: "number",
      title: "NUMBER",
      width: "15%",
      minWidth: "80px",
    },
  ];
};

const TrademarkSearchResults = ({ title = "Search Results", loading, data = [] }) => {
  const [currentPageIdx, setCurrentPageIdx] = useState(0);

  const result = data[currentPageIdx];

  const trademarkClassificationColumns = getTrademarkClassificationColumns();
  const isTrademarkClassificationEmpty = !result?.classification?.length;

  const trademarkOwnerColumns = getTrademarkOwnerColumns();
  const isTrademarkOwnersEmpty = !result?.owners?.length;

  const trademarkEventColumns = getTrademarkEventColumns();
  const isTrademarkEventsEmpty = !result?.events?.length;

  const handlePageChange = (idx) => {
    setCurrentPageIdx(idx);
  };

  return (
    <StyledCard>
      <StyledCardHeader>
        <div>{title}</div>
      </StyledCardHeader>
      <StyledCardContent>
        <Row>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Serial Number</StyledFieldLabel>
            <StyledFieldValue>{result?.serialNumber}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Registration Number</StyledFieldLabel>
            <StyledFieldValue>{result?.registrationNumber}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Mark Identification</StyledFieldLabel>
            <StyledFieldValue>{result?.markIdentification}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Status code</StyledFieldLabel>
            <StyledFieldValue>{result?.statusCode}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Status date</StyledFieldLabel>
            <StyledFieldValue>{result?.statusDate}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Filing date</StyledFieldLabel>
            <StyledFieldValue>{result?.filingDate}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Registration date</StyledFieldLabel>
            <StyledFieldValue>{result?.registrationDate}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Attorney name</StyledFieldLabel>
            <StyledFieldValue>{result?.attorneyName}</StyledFieldValue>
          </Col>
          <Col xs={6} md={4}>
            <StyledFieldLabel>Mark drawing code</StyledFieldLabel>
            <StyledFieldValue>{result?.markDrawingCode}</StyledFieldValue>
          </Col>
        </Row>
      </StyledCardContent>
      <StyledCardContent>
        <StyledTitle>Classification</StyledTitle>
        {isTrademarkClassificationEmpty && <StyledEmptyMessage>No classification found</StyledEmptyMessage>}
        {!isTrademarkClassificationEmpty && (
          <Table
            columns={trademarkClassificationColumns}
            rows={result.classification}
            loading={loading}
            skeletonRowsNum={6}
          />
        )}
      </StyledCardContent>
      <StyledCardContent>
        <StyledSpace />
        <StyledTitle>Current Trademark Owners</StyledTitle>
        {isTrademarkOwnersEmpty && <StyledEmptyMessage>No owners found</StyledEmptyMessage>}
        {!isTrademarkOwnersEmpty && (
          <Table columns={trademarkOwnerColumns} rows={result.owners} loading={loading} skeletonRowsNum={6} />
        )}
      </StyledCardContent>
      <StyledCardContent>
        <StyledSpace />
        <StyledTitle>Events</StyledTitle>
        {isTrademarkEventsEmpty && <StyledEmptyMessage>No events found</StyledEmptyMessage>}
        {!isTrademarkEventsEmpty && (
          <Table columns={trademarkEventColumns} rows={result.events} loading={loading} skeletonRowsNum={6} />
        )}
      </StyledCardContent>
      <StyledCardContent>
        <StyledSpace />
        <Row>
          <Col xs={12} md={4}>
            <StyledFieldLabel>Correspondence Name</StyledFieldLabel>
            <StyledFieldValue>{result?.correspondenceName}</StyledFieldValue>
          </Col>
          <Col xs={12} md={4}>
            <StyledFieldLabel>Correspondence Address</StyledFieldLabel>
            <StyledFieldValue>{result?.correspondenceAddress1}</StyledFieldValue>
          </Col>
          <Col xs={12} md={4}>
            <StyledFieldLabel>Correspondence Address 2</StyledFieldLabel>
            <StyledFieldValue>{result?.correspondenceAddress2}</StyledFieldValue>
          </Col>
        </Row>
      </StyledCardContent>
      <StyledCardContent>
        <Paginate pageCount={data?.length} currentPage={currentPageIdx} onPageChange={handlePageChange} />
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkSearchResults.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default TrademarkSearchResults;
