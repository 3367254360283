import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { selectLoading, selectTrademarkMonitoringReports } from "_store/orders/selector";

import Button from "components/atomic/atoms/Button";
import Paginate from "components/atomic/molecules/Paginate";
import TrademarkSearchResults from "components/atomic/organisms/sections/TrademarkSearchResults";
import Card from "components/layouts/Card";
import CardHeader from "components/layouts/CardHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    align-items: center;
    gap: 10px;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `};

  @media (max-width: 991px) {
    ${({ mobile }) =>
      mobile &&
      css`
        display: flex;
      `};

    ${({ desktop }) =>
      desktop &&
      css`
        display: none;
      `};

    flex-direction: column;
    align-items: center;
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  padding-bottom: 0;

  @media (max-width: 991px) {
    max-width: 700px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;
  border-bottom: unset;

  @media (max-width: 991px) {
    justify-content: center;
  }

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledCol = styled(Col)`
  margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const TrademarkMonitoringReportPage = () => {
  const navigate = useNavigate();
  const [currentPageIdx, setCurrentPageIdx] = useState(0);
  const loading = useSelector(selectLoading);
  const monitoringReports = useSelector(selectTrademarkMonitoringReports);

  const dates = Object.keys(monitoringReports).filter((date) => monitoringReports[date]?.completed) || [];
  const currentDate = dates[currentPageIdx];

  const handlePageChange = (idx) => {
    setCurrentPageIdx(idx);
  };

  const handleGoBack = () => {
    navigate("..");
  };

  return (
    <Container>
      <ContentRow desktop>
        <StyledCard>
          <StyledCardHeader>
            Date: {currentDate}
            <Paginate pageCount={dates?.length} currentPage={currentPageIdx} onPageChange={handlePageChange} />
          </StyledCardHeader>
        </StyledCard>
      </ContentRow>
      <ContentRow mobile>
        <StyledCard>
          <StyledCardHeader>
            <Paginate pageCount={dates?.length} currentPage={currentPageIdx} onPageChange={handlePageChange} />
          </StyledCardHeader>
        </StyledCard>
      </ContentRow>
      <ContentRow mobile>
        <StyledCard>
          <StyledCardHeader>Date: {currentDate}</StyledCardHeader>
        </StyledCard>
      </ContentRow>
      <ContentRow>
        <TrademarkSearchResults
          title="Federal Search Results"
          loading={loading}
          data={monitoringReports[currentDate]?.federalSearchResults}
        />
      </ContentRow>
      <ContentRow desktop>
        <StyledCard>
          <StyledCardHeader>
            Date: {currentDate}
            <Paginate pageCount={dates?.length} currentPage={currentPageIdx} onPageChange={handlePageChange} />
          </StyledCardHeader>
        </StyledCard>
      </ContentRow>
      <ContentRow mobile>
        <StyledCard>
          <StyledCardHeader>Date: {currentDate}</StyledCardHeader>
        </StyledCard>
      </ContentRow>
      <ContentRow mobile>
        <StyledCard>
          <StyledCardHeader>
            <Paginate pageCount={dates?.length} currentPage={currentPageIdx} onPageChange={handlePageChange} />
          </StyledCardHeader>
        </StyledCard>
      </ContentRow>
      <ContentRow>
        <StyledRow>
          <Col xs={0} md={4} />
          <StyledCol xs={12} md={4}>
            <StyledButton onClick={handleGoBack}>Go Back</StyledButton>
          </StyledCol>
          <Col xs={0} md={4} />
        </StyledRow>
      </ContentRow>
    </Container>
  );
};

export default TrademarkMonitoringReportPage;
