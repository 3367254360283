/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import { Overlay } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { STORAGE_KEYS } from "_constants/storage";
import { restoreSessionValue } from "_helpers/storage";
import { selectNotifPopupState } from "_store/application/selector";
import { closeNotifPopup, openNotifPopup } from "_store/application/slice";
import {
  selectActionRequiredProductsCount,
  selectActiveOrderId,
  selectCompanyOptionByOrderId,
  selectCompanyOptions,
} from "_store/orders/selector";
import { refreshActiveOrderId } from "_store/orders/slice";
import { logout } from "_store/user/slice";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import Select from "components/atomic/atoms/Select";
import NotificationsPopup from "components/atomic/molecules/NotificationsPopup";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 84px;
  padding: 20px 42px;
  gap: 30px;
  border-bottom: 4px solid #dbe4f0;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-header-bg"]};
  `}

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    /* flex-wrap: wrap-reverse; */
    height: auto;
    padding: 20px 12px;
    gap: 8px;
    /* padding: 0; */

    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.colors["cl-header-divider"]};
    `}
  }
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  /* flex-grow: 1; */
  height: 100%;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  /* @media (max-width: 991px) { */
    /* justify-content: center; */
  /* } */

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledSelect = styled(Select)`
  /* min-width: 170px; */
  max-width: 320px;
  /* width: max-content; */
  width: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 100px;

  @media (max-width: 991px) {
    display: none;
  }
`;

const LogoutButton = styled(Icon)`
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
`;

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 32px;
  height: 100%;
  width: 100%;
  /* border-left: 1px solid ${({ theme }) => theme.colors["cl-header-divider"]}; */
  /* padding-left: 30px; */

  @media (max-width: 991px) {
    /* flex-direction: column; */
    justify-content: space-between;
    gap: 8px;
    padding-left: 0;
  }
`;
const SidebarCollapse = styled.div`
  display: none;
  flex-grow: 1;
  /* min-width: 80px; */

  @media (max-width: 991px) {
    display: block;
  }
`;
const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  height: 100%;

  @media (max-width: 991px) {
    gap: 48px;
  }

  @media (max-width: 450px) {
    gap: 16px;
  }
`;

const HeaderNavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};

    &:hover {
      background-color: #00000011;
      color: ${theme.colors["cl-text-dark"]};
    }
  `}

  ${({ alertscount, theme }) =>
    alertscount &&
    alertscount > 0 &&
    css`
      &::after {
        content: "${alertscount > 9 ? "9+" : alertscount}";
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        /* font-size: 0.8125rem; */
        font-size: 0.75rem;
        font-weight: 700;
        background-color: ${theme.colors["cl-alert-bg"]};
        color: ${theme.colors["cl-alert-text"]};
      }
    `}
`;

const LogoutContainer = styled.div`
  display: flex;
  justify-content: end;

  @media (max-width: 991px) {
    flex-grow: 1;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
`;

const TextLg = styled.div`
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
`;

const PopupWrapper = styled.div`
  position: absolute;
  z-index: 5;
`;

const Header = ({ sidebarObj }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ref = useRef(null);

  const activeOrderId = useSelector(selectActiveOrderId);
  const activeCompanyOption = useSelector(selectCompanyOptionByOrderId(activeOrderId));
  const companyOptions = useSelector(selectCompanyOptions);
  const alertsCount = useSelector(selectActionRequiredProductsCount);
  const notifPopupOpen = useSelector(selectNotifPopupState);

  const handleClick = () => {
    if (!notifPopupOpen) {
      dispatch(openNotifPopup());
    } else {
      dispatch(closeNotifPopup());
    }
  };

  const hide = () => {
    dispatch(closeNotifPopup());
  };

  const handleCompanyChange = useCallback(
    (option) => {
      option && dispatch(refreshActiveOrderId(option?.value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (companyOptions.length && !activeOrderId) {
      const isLoginFromCRM = restoreSessionValue(STORAGE_KEYS.loginFromCRM);
      const orderId = restoreSessionValue(STORAGE_KEYS.activeOrderId);
      const selectedOrderOption = companyOptions?.find((order) => order?.value === orderId);

      if (isLoginFromCRM && orderId && selectedOrderOption) {
        handleCompanyChange(selectedOrderOption);
      } else {
        handleCompanyChange(companyOptions[0]);
      }
    }
  }, [dispatch, companyOptions]);

  return (
    <>
      <StyledHeader>
        <SelectContainer>
          <TextLg>Select&nbsp;Company</TextLg>
          <StyledSelect value={activeCompanyOption} options={companyOptions} onChange={handleCompanyChange} />
        </SelectContainer>
        <HeaderMenu>
          <SidebarCollapse>
            <Icon icon="header:menu" size="28px" color="#25396F" onClick={() => sidebarObj.setShow(true)} />
          </SidebarCollapse>
          <HeaderNav>
            <HeaderNavItem
              ref={ref}
              name="alertIcon"
              alertscount={alertsCount}
              onClick={(e) => {
                if (window.innerWidth < 992) {
                  navigate("dashboard/notifications");
                } else {
                  handleClick(e);
                }
              }}
            >
              <Icon icon="header:alerts" size="32px" />
            </HeaderNavItem>
            <HeaderNavItem>
              <StyledLink to="settings/support">
                <Icon icon="header:help" size="32px" />
              </StyledLink>
            </HeaderNavItem>
            <HeaderNavItem>
              <StyledLink to="settings/account">
                <Icon icon="header:settings" size="32px" />
              </StyledLink>
            </HeaderNavItem>
          </HeaderNav>
          <LogoutContainer>
            <StyledButton
              borderless
              type="button"
              onClick={() => {
                dispatch(logout());
              }}
            >
              Sign&nbsp;Out
            </StyledButton>
            <LogoutButton
              icon="header:logout"
              size="28px"
              color="#25396F"
              onClick={() => {
                dispatch(logout());
              }}
            />
          </LogoutContainer>
        </HeaderMenu>
      </StyledHeader>
      <Overlay
        show={notifPopupOpen}
        target={ref}
        placement="bottom"
        container={ref.current}
        containerPadding={20}
        rootClose={true}
        onHide={hide}
      >
        <PopupWrapper>
          <NotificationsPopup show={notifPopupOpen} hideHandler={() => dispatch(closeNotifPopup())} />
        </PopupWrapper>
      </Overlay>
    </>
  );
};

Header.propTypes = {
  sidebarObj: PropTypes.shape({
    show: PropTypes.bool,
    setShow: PropTypes.func,
  }),
  options: PropTypes.array,
  user: PropTypes.shape({
    username: PropTypes.string,
  }),
};

export default Header;
