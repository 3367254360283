import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Controller } from "react-hook-form";

import Input from "components/atomic/atoms/Input";

import FieldWrapper from "../FieldWrapper";

const TextField = ({ column, label, name, control, rules = {}, errors = {}, getPaymentProps, ...restProps }) => {
  const [selectionTarget, setSelectionTarget] = useState(null);

  const handleTextSelect = (event) => {
    let elem = event.target;
    let selectionLength = elem.selectionEnd - elem.selectionStart;
    if (!selectionLength) {
      setSelectionTarget(null);
    } else {
      setSelectionTarget(elem.name);
    }
  };

  const getPaymentExtraProps = useCallback(() => {
    if (name === selectionTarget) {
      return { onKeyPress: () => {} };
    }

    return {};
  }, [selectionTarget, name]);

  return (
    <FieldWrapper column={column} label={label} name={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          if (getPaymentProps) {
            const { onChange, onBlur, value } = field;

            return (
              <Input
                {...restProps}
                {...getPaymentProps({ onChange, onBlur, value })}
                {...getPaymentExtraProps()}
                onSelect={handleTextSelect}
              />
            );
          }

          return <Input {...restProps} {...field} />;
        }}
      />
    </FieldWrapper>
  );
};

TextField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
  getPaymentProps: PropTypes.func,
};

export default TextField;
