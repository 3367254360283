/* eslint-disable no-undef */
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import ReactPaginate from "react-paginate";
import styled, { css } from "styled-components";

const StyledPaginate = styled(ReactPaginate)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;

  li {
    a {
      display: flex;
      align-items: center;
      padding-right: 16px;
      padding-left: 16px;
      padding-top: 8px;
      padding-bottom: 8px;

      ${({ theme }) => css`
        color: ${theme.colors["cl-text-light"]};
      `}

      &:not([href]):not([class]) {
        ${({ theme }) => css`
          color: ${theme.colors["cl-text-light"]};
        `}
      }

      &:hover,
      &:not([href]):not([class]):hover {
        ${({ theme }) => css`
          color: ${theme.colors["cl-text-dark"]};
        `}
      }
    }

    &.selected {
      a {
        &:not([href]):not([class]) {
          ${({ theme }) => css`
            border-bottom: 2px solid ${theme.colors["cl-text-dark"]};
            color: ${theme.colors["cl-text-dark"]};
          `}
        }
      }
    }

    &.previous {
      a {
        text-decoration: unset;
      }
    }

    &.next {
      a {
        text-decoration: unset;
      }
    }

    &.disabled {
      a {
        pointer-events: none;
      }
    }
  }

  @media (max-width: 450px) {
    li {
      a {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }
`;

const Paginate = ({ pageCount, currentPage, onPageChange }) => {
  const handlePageChange = useCallback(
    (data) => {
      onPageChange(data.selected);
    },
    [onPageChange]
  );

  return (
    <StyledPaginate
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      previousLabel="< prev"
      nextLabel="next >"
      pageCount={pageCount}
      forcePage={currentPage}
      onPageChange={handlePageChange}
    />
  );
};

Paginate.propTypes = {
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Paginate;
