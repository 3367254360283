import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import styled, { css } from "styled-components";

const StyledGroup = styled(Form.Group)`
  display: flex;
  align-items: center;
  height: 100%;

  input {
    cursor: pointer;
  }

  input:checked + label,
  input:not(:checked) + label {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors["cl-text-dark"]};
    cursor: pointer;
  }

  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `};
`;

const StyledCheck = styled(Form.Check)`
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 24px;

  label {
    line-height: 24px;
  }

  &:first-child {
    margin-left: 8px;
  }

  ${({ vertical }) =>
    vertical &&
    css`
      margin-bottom: 8px;
      margin-left: 0;

      &:first-child {
        margin-left: 0;
      }
    `};
`;

const RadioGroup = ({ vertical, name, options, value, onChange }) => {
  return (
    <StyledGroup vertical={vertical}>
      {options.map((option) => (
        <StyledCheck
          key={`${name}:${option.value}`}
          id={`${name}:${option.value}`}
          type="radio"
          vertical={vertical}
          label={option.label}
          checked={value === option.value}
          value={option.value}
          onChange={onChange}
        />
      ))}
    </StyledGroup>
  );
};

RadioGroup.propTypes = {
  vertical: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })),
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default RadioGroup;
