export function mappingQueryParams(params) {
  if (params && typeof params === "object" && Object.keys(params)) {
    let queryStr = "?";

    Object.keys(params).forEach((prop) => {
      if (queryStr.length > 1 && params[prop]) {
        queryStr += `&${prop}=${encodeURIComponent(params[prop])}`;
      } else if (params[prop]) {
        queryStr += `${prop}=${encodeURIComponent(params[prop])}`;
      }
    });

    return queryStr.length > 1 ? queryStr : "";
  }

  return "";
}
