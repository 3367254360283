import PropTypes from "prop-types";
import React, { useCallback, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

import EditPaymentMethodForm from "components/atomic/organisms/forms/EditPaymentMethodForm";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

// eslint-disable-next-line no-unused-vars
const EditPaymentMethodModal = ({ primaryId, onSave, onCancel }) => {
  const formRef = useRef(null);

  const handleConfirm = () => {
    formRef.current.submit();
  };

  const handleSubmit = useCallback(
    ({ cardReplacing, ...restCardDetails }) => {
      onSave && onSave({ ...restCardDetails, paySourceId: cardReplacing ? primaryId : null });
      onCancel && onCancel();
    },
    [onCancel, onSave, primaryId]
  );

  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader>Add a Payment Method</StyledHeader>
      <Modal.Body>
        <EditPaymentMethodForm ref={formRef} onSubmit={handleSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleConfirm}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditPaymentMethodModal.propTypes = {
  primaryId: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EditPaymentMethodModal;
