import { css } from "styled-components";

import MontserratLatin100ItalicWoff from "./montserrat-latin-100-italic.woff";
import MontserratLatin100NormalWoff from "./montserrat-latin-100-normal.woff";
import MontserratLatin200ItalicWoff from "./montserrat-latin-200-italic.woff";
import MontserratLatin200NormalWoff from "./montserrat-latin-200-normal.woff";
import MontserratLatin300ItalicWoff from "./montserrat-latin-300-italic.woff";
import MontserratLatin300NormalWoff from "./montserrat-latin-300-normal.woff";
import MontserratLatin400ItalicWoff from "./montserrat-latin-400-italic.woff";
import MontserratLatin400NormalWoff from "./montserrat-latin-400-normal.woff";
import MontserratLatin500ItalicWoff from "./montserrat-latin-500-italic.woff";
import MontserratLatin500NormalWoff from "./montserrat-latin-500-normal.woff";
import MontserratLatin600ItalicWoff from "./montserrat-latin-600-italic.woff";
import MontserratLatin600NormalWoff from "./montserrat-latin-600-normal.woff";
import MontserratLatin700ItalicWoff from "./montserrat-latin-700-italic.woff";
import MontserratLatin700NormalWoff from "./montserrat-latin-700-normal.woff";
import MontserratLatin800ItalicWoff from "./montserrat-latin-800-italic.woff";
import MontserratLatin800NormalWoff from "./montserrat-latin-800-normal.woff";
import MontserratLatin900ItalicWoff from "./montserrat-latin-900-italic.woff";
import MontserratLatin900NormalWoff from "./montserrat-latin-900-normal.woff";

export default css`
  /* Normal */
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin100NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 100;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin200NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 200;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin300NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 300;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin400NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin500NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin600NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin700NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 700;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin800NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 800;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin900NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 900;
  }

  /* Italic */
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin100ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 100;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin200ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 200;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin300ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 300;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin400ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 400;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin500ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 500;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin600ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 600;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin700ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 700;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin800ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 800;
  }
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratLatin900ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 900;
  }
`;
