import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { validateTrim } from "_helpers";
import useIsDesktopSize from "_hooks/useIsDesktopSize";

import Button from "components/atomic/atoms/Button";
import AreaField from "components/atomic/molecules/fields/AreaField";
import TextField from "components/atomic/molecules/fields/TextField";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const rules = {
  // typeOfIssue: () => ({
  //   required: "Type of Issue is a required field",
  //   maxLength: {
  //     value: 90,
  //     message: "Type of Issue should be less or equal 90 symbols",
  //   },
  // }),
  subject: () => ({
    required: "Subject is a required field",
    maxLength: {
      value: 90,
      message: "Subject should be less or equal 90 symbols",
    },
    validate: validateTrim,
  }),
  message: () => ({
    required: "Please, put your question here",
    minLength: {
      value: 5,
      message: "Subject should be more or equal 5 symbols",
    },
    validate: validateTrim,
  }),
};

const StyledForm = styled.form`
  width: 100%;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
  border: none;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  gap: 10px;
  overflow-y: hidden;

  label {
    width: 20%;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  label + * {
    width: 80%;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled(Row)`
  justify-content: end;
  padding-top: 10px;
  /* margin-left: auto; */
  /* margin-right: 0; */
  /* min-width: 692px; */

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const initialValues = { message: "", subject: "" };

const SupportForm = ({ defaultValues = { ...initialValues }, onSubmit }) => {
  const {
    control,
    reset,
    formState,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const isDesktopSize = useIsDesktopSize();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ ...initialValues });
    }
  }, [formState, reset]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledCard>
        <StyledCardHeader>Have a Question? Submit Ticket</StyledCardHeader>
        <StyledCardContent>
          {/* <TextField
            column={!isDesktopSize}
            label="Type of Issue"
            placeholder="Type of Issue"
            name="typeOfIssue"
            control={control}
            errors={errors}
            rules={rules.typeOfIssue()}
          /> */}

          <TextField
            column={!isDesktopSize}
            label="Subject"
            placeholder="Subject"
            name="subject"
            control={control}
            errors={errors}
            rules={rules.subject()}
          />

          <AreaField
            column={!isDesktopSize}
            label="Message"
            placeholder="Please, describe your problem"
            name="message"
            control={control}
            errors={errors}
            rules={rules.message()}
          />

          <Buttons>
            <Button type="submit" primary width="180px">
              Send
            </Button>
          </Buttons>
        </StyledCardContent>
      </StyledCard>
    </StyledForm>
  );
};

SupportForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SupportForm;
