import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import { STATE_MAP } from "_constants/variables";

import Table from "components/atomic/molecules/Table";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 100%;

  @media (max-width: 991px) {
    max-width: 700px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledEmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const getTrademarkOwnersColumns = () => {
  return [
    {
      dataIndex: "firstName",
      title: "FIRST NAME",
      width: "10%",
      minWidth: "100px",
    },
    {
      dataIndex: "lastName",
      title: "LAST NAME",
      width: "10%",
      minWidth: "100px",
    },
    {
      dataIndex: "phone",
      title: "PHONE",
      width: "10%",
      minWidth: "100px",
    },
    {
      dataIndex: "email",
      title: "EMAIL",
      width: "15%",
      minWidth: "100px",
    },
    {
      dataIndex: "address1",
      title: "ADDRESS",
      width: "15%",
      minWidth: "120px",
    },
    {
      dataIndex: "countryOfCitizenship",
      title: "	СITIZENSHIP",
      width: "10%",
      minWidth: "100px",
    },
    {
      dataIndex: "state",
      title: "STATE",
      width: "10%",
      minWidth: "100px",
      render: (row) => STATE_MAP[row?.state],
    },
    {
      dataIndex: "city",
      title: "CITY",
      width: "10%",
      minWidth: "100px",
    },
    {
      dataIndex: "zip",
      title: "ZIP",
      width: "10%",
      minWidth: "50px",
    },
  ];
};

const TrademarkOwners = ({ loading, data }) => {
  const columns = getTrademarkOwnersColumns();
  const isEmpty = !data?.length;

  return (
    <StyledCard>
      <StyledCardHeader>
        <div>Trademark Owners</div>
      </StyledCardHeader>
      <StyledCardContent>
        {isEmpty && <StyledEmptyMessage>No owner info</StyledEmptyMessage>}
        {!isEmpty && <Table columns={columns} rows={data} loading={loading} skeletonRowsNum={6} />}
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkOwners.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      address1: PropTypes.string,
      countryOfCitizenship: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
    })
  ),
};

export default TrademarkOwners;
