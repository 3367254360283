import PropTypes from "prop-types";
import React, { forwardRef, useCallback } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const StyledGroup = styled(Form.Group)`
  input:checked + label,
  input:not(:checked) + label {
    width: 100%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors["cl-text-dark"]};
    cursor: pointer;
  }
`;

const StyledCheck = styled(Form.Check)`
  margin-right: 0;
  margin-bottom: 0;

  label {
    line-height: 24px;
  }
`;

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef((props, ref) => {
  const { id, name, label, value, onChange } = props;

  const handleChange = useCallback(
    (e) => {
      const { id, name, checked } = e.target || {};
      onChange && onChange({ target: { id, name, value: checked } });
    },
    [onChange]
  );

  return (
    <StyledGroup controlId={id || name}>
      <StyledCheck inline ref={ref} type="checkbox" label={label} name={id} checked={value} onChange={handleChange} />
    </StyledGroup>
  );
});

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
