import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";
import styled from "styled-components";

import { selectOrderFiles, selectOrderUpsellsFiles } from "_store/orders/selector";
import { getFileFromProduct } from "_store/orders/slice";

import Button from "components/atomic/atoms/Button";
import EmptyMessage from "components/atomic/atoms/EmptyMessage";
import Icon from "components/atomic/atoms/Icon";
import DocumentInfoModal from "components/atomic/organisms/modals/DocumentInfoModal";
import Card from "components/layouts/Card";

const Container = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  /* gap: 30px; */
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;
  height: 100%;
  animation: fadeIn 0.5s;
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  overflow-y: hidden;

  ${({ theme }) => theme.animations.fadeIn}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px 0;
  height: 100%;
  width: 100%;

  overflow: auto;

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 0;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  height: max-content;
  /* width: 820px; */

  @media (max-width: 991px) {
    justify-content: center;
  }

  @media (max-width: 760px) {
    gap: 16px;
  }
`;

const DocumentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  width: 252px;
  height: 252px;
  padding: 20px;
  cursor: pointer;

  @media (max-width: 760px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 12px;
    margin: 0 8px;

    & .icon-container {
      width: 48px;
      height: 48px;
      padding: 8px;
      order: 1;
    }
  }
`;

const DocumentTitleContainer = styled.div`
  height: 32px;
  width: 210px;

  @media (max-width: 760px) {
    order: 2;
    /* min-width: 160px; */
    width: 130px;
    flex-grow: 1;
  }
`;

const DocumentTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors["cl-text-dark"]};
  text-align: center;

  @media (max-width: 760px) {
    text-align: left;
  }
`;

const DocumentSubtitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  transform: translateY(-3px);

  color: ${({ theme }) => theme.colors["cl-text-light"]};
  text-align: center;

  @media (max-width: 760px) {
    text-align: left;
  }
`;

const StyledIcon = styled(Icon)`
  display: none;

  @media (max-width: 760px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    margin: auto;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: 760px) {
    order: 3;
    min-width: 40px;
    max-width: 40px;
    padding: 0;
    border: none;
  }
`;

const ButtonText = styled.span`
  @media (max-width: 760px) {
    display: none;
  }
`;

const DocumentsPage = () => {
  const dispatch = useDispatch();
  const themeContext = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(null);

  const orderFiles = useSelector(selectOrderFiles);
  const upsellsFiles = useSelector(selectOrderUpsellsFiles);

  const files = [...orderFiles, ...upsellsFiles];

  const downloadHandler = (orderId, productId, fileType) => {
    dispatch(getFileFromProduct({ orderId, productId, fileType }));
  };

  return (
    <>
      <Container>
        <ContentWrapper>
          {files?.length ? (
            <ContentRow>
              {files?.map((file, idx) => {
                const { title, subtitle, filename, icon, orderId, productId } = file;

                return (
                  <DocumentCard
                    key={idx}
                    onClick={() => {
                      setDocumentDetails(file);
                      setShowModal(true);
                    }}
                  >
                    <DocumentTitleContainer>
                      <DocumentTitle>{title}</DocumentTitle>
                      <DocumentSubtitle>{subtitle}</DocumentSubtitle>
                    </DocumentTitleContainer>
                    <Icon
                      rounded
                      icon={icon || "documents:default"}
                      size="110px"
                      padding="28px"
                      color={
                        icon === "documents:default" || !icon
                          ? themeContext.colors["cl-item-default"]
                          : themeContext.colors[`cl-item${(idx % 5) + 1}`]
                      }
                    />
                    <StyledButton
                      outlined
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadHandler(orderId, productId, filename);
                      }}
                    >
                      <StyledIcon icon="documents:download" size="16px" />
                      <ButtonText>Download PDF</ButtonText>
                    </StyledButton>
                  </DocumentCard>
                );
              })}
            </ContentRow>
          ) : (
            <EmptyMessage>No Documents here</EmptyMessage>
          )}
        </ContentWrapper>
      </Container>
      {showModal && (
        <DocumentInfoModal
          documentDetails={documentDetails}
          downloadHandler={downloadHandler}
          onCancel={() => {
            setDocumentDetails(null);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default DocumentsPage;
