import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";

import { currencyFormatter } from "_helpers/formatter";

import Button from "components/atomic/atoms/Button";
import Indicator from "components/atomic/atoms/Indicator";
import Table from "components/atomic/molecules/Table";
import CancelSubscriptionConfirmModal from "components/atomic/organisms/modals/CancelSubscriptionConfirmModal";
import CancelSubscriptionRAConfirmModal from "components/atomic/organisms/modals/CancelSubscriptionRAConfirmModal";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  height: 390px;

  @media (max-width: 991px) {
    max-width: 700px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const Date = styled.div`
  font-style: italic;
`;

const Price = styled.div`
  font-weight: 500;
`;

const CancelledText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors["cl-status-red"]};
  `}
`;

const StyledButton = styled(Button)`
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

const getSubscriptionsColumns = ({ handleConfirmShow, handleRAConfirmShow }) => {
  const isRASubscription = (code) => {
    let flag = false;
    const namesRA = ["Resident Agent", "Registered Agent"];
    namesRA.forEach((name) => {
      if (code.includes(name)) {
        flag = true;
      }
    });

    return flag;
  };

  return [
    {
      dataIndex: "status",
      title: "#",
      width: "4%",
      minWidth: "20px",
      render: (row) => <Indicator status={row?.status === "ACTIVE"} />,
    },
    {
      dataIndex: "orderId",
      title: "Order ID",
      width: "16%",
      minWidth: "100px",
    },
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "36%",
      minWidth: "200px",
    },
    {
      dataIndex: "price",
      title: "Price",
      width: "10%",
      minWidth: "80px",
      render: (row) => <Price>{currencyFormatter(row?.price)}</Price>,
    },
    {
      dataIndex: "nextPayDate",
      title: "Next Billing Date",
      width: "20%",
      minWidth: "130px",
      render: (row) =>
        row?.nextPayDate && !row?.nextPayDate?.includes("null") ? (
          <Date>{moment(row?.nextPayDate).format("MM/DD")}</Date>
        ) : (
          "–"
        ),
    },
    {
      dataIndex: "cancel",
      title: "Cancel",
      width: "14%",
      minWidth: "100px",
      render: (row) => {
        if (row?.id) {
          if (row?.status === "CANCELLED") {
            return <CancelledText>Cancelled</CancelledText>;
          } else {
            return (
              <StyledButton
                outlined
                onClick={() => {
                  if (isRASubscription(row?.productName)) {
                    handleRAConfirmShow({ orderId: row?.id, productId: row?.productId, productName: row?.productName });
                  } else {
                    handleConfirmShow({ orderId: row?.id, productId: row?.productId, productName: row?.productName });
                  }
                }}
              >
                Cancel
              </StyledButton>
            );
          }
        }
      },
    },
  ];
};

const Subscriptions = ({ loading, data, onUnsubscribe }) => {
  const [open, setOpen] = useState(false);
  const [openRA, setOpenRA] = useState(false);
  const [cancelInfo, setCancelInfo] = useState(null);

  const handleConfirmHide = () => {
    setCancelInfo(null);
    setOpen(false);
  };

  const handleConfirmShow = ({ orderId, productId, productName }) => {
    setCancelInfo({ orderId, productId, productName });
    setOpen(true);
  };

  const handleRAConfirmHide = () => {
    setCancelInfo(null);
    setOpenRA(false);
  };

  const handleRAConfirmShow = ({ orderId, productId, productName }) => {
    setCancelInfo({ orderId, productId, productName });
    setOpenRA(true);
  };

  const handleUnsubscribe = useCallback(() => {
    cancelInfo && onUnsubscribe && onUnsubscribe(cancelInfo);
    setOpen(false);
  }, [cancelInfo, onUnsubscribe]);

  const columns = getSubscriptionsColumns({ handleConfirmShow, handleRAConfirmShow });

  return (
    <>
      <StyledCard>
        <StyledCardHeader>Subscriptions</StyledCardHeader>
        <StyledCardContent>
          <Table columns={columns} rows={data} loading={loading} height="100%" />
        </StyledCardContent>
      </StyledCard>
      {open && (
        <CancelSubscriptionConfirmModal {...cancelInfo} onConfirm={handleUnsubscribe} onCancel={handleConfirmHide} />
      )}
      {openRA && <CancelSubscriptionRAConfirmModal {...cancelInfo} onCancel={handleRAConfirmHide} />}
    </>
  );
};

Subscriptions.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  onUnsubscribe: PropTypes.func,
};

export default Subscriptions;
