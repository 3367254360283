import { css } from "styled-components";

const animations = {
  fadeIn: css`
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 100%;
      }
    }
  `,

  translateIn: css`
    @keyframes translateIn {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(0%);
      }
    }
  `,

  translateOut: css`
    @keyframes translateIn {
      from {
        transform: translateX(0%);
      }
      to {
        transform: translateX(-100%);
      }
    }
  `,
};

export default animations;
