import React, { useEffect } from "react";
import Notifications from "react-notification-system-redux";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Zendesk, { ZendeskAPI } from "react-zendesk";

import { selectNotifications } from "_store/notifications/selector";
import { restoreActiveOrderId } from "_store/orders/slice";
import { restoreDetails as restoreUserDetails } from "_store/user/slice";

const ZENDESK_KEY = "00f5c996-4513-4b9e-9a2e-39135e5364cb";

const settings = {
  color: {
    theme: "#000",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [{ id: "description", prefill: { "*": "My pre-filled description" } }],
  },
};

function App() {
  const dispatch = useDispatch();

  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    dispatch(restoreUserDetails());
    dispatch(restoreActiveOrderId());
  }, [dispatch]);

  return (
    <>
      <Outlet />
      <Notifications notifications={notifications} />
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...settings} onLoaded={() => ZendeskAPI("webWidget", "hide")} />
    </>
  );
}

export default App;
