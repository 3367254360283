import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { CUSTOMER_ACTIONS } from "_constants/variables";
import { selectActiveOrderId, selectMainProduct } from "_store/orders/selector";
import { customerAction, getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import BoiUploadConfirmationModal from "components/atomic/organisms/modals/BoiUploadConfirmationModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div``;

const FieldsGroupHeader = styled.div`
  text-align: center;
  margin: 0 0 24px 0;
`;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

const BoiOfferActionForm = () => {
  const dispatch = useDispatch();

  const [openBoiUpsellModal, setOpenBoiUpsellModal] = useState(false);

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const mainProduct = useSelector(selectMainProduct);

  function onSubmit() {
    setOpenBoiUpsellModal(true);
  }

  function sendDeclineBoiRequest() {
    dispatch(
      customerAction({
        customerId,
        productId: mainProduct?.id,
        actionType: CUSTOMER_ACTIONS.declineBoi,
        notification: "Thank you for answer! The action will be hidden.",
        cb: () => {
          dispatch(getOrder({ customerId, orderId, refreshOrders: true, redirectUrl: "../actions" }));
        },
      })
    );
  }

  return (
    <>
      <Container>
        <FieldsGroup>
          <FieldsGroupHeader>{"Would you like our specialists to file this report for you for $99?"}</FieldsGroupHeader>
          <FieldsGroupContent>
            <ButtonsGroup>
              <StyledButton primary type="button" onClick={onSubmit}>
                Yes
              </StyledButton>

              <StyledButton secondary type="button" onClick={sendDeclineBoiRequest}>
                No, I will file it myself
              </StyledButton>
            </ButtonsGroup>
          </FieldsGroupContent>
        </FieldsGroup>
      </Container>

      {openBoiUpsellModal && (
        <BoiUploadConfirmationModal
          onCancel={() => {
            setOpenBoiUpsellModal(false);
          }}
        />
      )}
    </>
  );
};

export default BoiOfferActionForm;
