import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { parseTrademarkForm } from "_helpers/trademark";
import { selectTrademarkForm } from "_store/orders/selector";
import { updateProducts } from "_store/orders/slice";
import { selectDetails as selectUserDetails } from "_store/user/selector";

import EditTrademarkSpecimenForm from "components/atomic/organisms/forms/EditTrademarkSpecimenForm";

const EditTrademarkSpecimenPage = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const formValues = useSelector(selectTrademarkForm);

  const handleSubmit = (values) => {
    const { uid: customerId } = userDetails || {};
    const products = parseTrademarkForm(values);

    dispatch(
      updateProducts({
        customerId,
        products,
        // specimenFile: values.isSpecimenFile ? values.specimenFile : null,
        specimenFile: values.specimenFile ? values.specimenFile : null,
        redirectUrl: "..",
      })
    );
  };

  return <EditTrademarkSpecimenForm defaultValues={formValues} onSubmit={handleSubmit} />;
};

export default EditTrademarkSpecimenPage;
