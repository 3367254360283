import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled, { css } from "styled-components";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  overflow: hidden;
  padding: 0;
`;

const List = styled.div`
  overflow-y: scroll;
  max-height: 70vh;
  padding: 8px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  /* border: 1px solid black; */
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`;
const Label = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${({ theme }) => theme.colors["cl-text-light"]};
`;
const Data = styled.div`
  width: 100%;
  /* word-break: break-all; */
  color: ${({ theme }) => theme.colors["cl-text-dark"]};
`;
const CurrentNumber = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const MembersListModal = ({ members, onCancel }) => {
  return (
    <Modal centered show onHide={onCancel} size="md">
      <StyledHeader>Members List</StyledHeader>
      <StyledBody>
        <List>
          {members.map((member, idx) => {
            const { name } = member;
            return (
              <Row key={`${idx}-${name}`}>
                <CurrentNumber>#{idx + 1}</CurrentNumber>
                <Label>Name:</Label>
                <Data>{name || "—"}</Data>
              </Row>
            );
          })}
        </List>
      </StyledBody>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

MembersListModal.propTypes = {
  members: PropTypes.array,
  onCancel: PropTypes.func,
};

export default MembersListModal;
