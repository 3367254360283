import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import Table from "components/atomic/molecules/Table";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 700px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

const StyledEmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledText = styled.span`
  word-break: break-all;
`;

const getTrademarkDocumentsColumns = ({ handleDownload }) => {
  return [
    {
      dataIndex: "download",
      title: "OPEN",
      width: "10%",
      minWidth: "42px",
      render: (row) => (
        <StyledIcon icon="documents:download" onClick={handleDownload(row?.orderId, row?.productId, row?.filename)} />
      ),
    },
    {
      dataIndex: "title",
      title: "NAME",
      width: "60%",
      render: (row) => <StyledText>{row?.title}</StyledText>,
    },
    {
      dataIndex: "uploaded",
      title: "UPLOADED",
      width: "30%",
      // minWidth: '50px',
      render: (row) => moment.utc(row?.uploaded).local().format("YYYY/MM/DD - HH:mm:ss"),
    },
  ];
};

const TrademarkDocuments = ({ disabled, loading, data, onEdit, onDownload }) => {
  const isEmpty = !data?.length;

  const handleDownload = (orderId, productId, fileType) => () => {
    onDownload(orderId, productId, fileType);
  };

  const columns = getTrademarkDocumentsColumns({ handleDownload });

  return (
    <StyledCard>
      <StyledCardHeader>
        <div>Trademark Documents</div>
        <StyledButton borderless disabled={disabled} onClick={onEdit}>
          Upload <Icon icon="documents:default" />
        </StyledButton>
      </StyledCardHeader>
      <StyledCardContent>
        {isEmpty && <StyledEmptyMessage>No documents</StyledEmptyMessage>}
        {!isEmpty && <Table hiddenHeader columns={columns} rows={data} loading={loading} skeletonRowsNum={6} />}
      </StyledCardContent>
    </StyledCard>
  );
};

TrademarkDocuments.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.string,
      productId: PropTypes.string,
      filename: PropTypes.string,
      title: PropTypes.string,
      note: PropTypes.string,
      type: PropTypes.string,
      fileKey: PropTypes.string,
      uploaded: PropTypes.string,
    })
  ),
  onEdit: PropTypes.func,
  onDownload: PropTypes.func,
};

export default TrademarkDocuments;
