import { MONTH_DAYS, STATE_MAP } from "_constants/variables";

export const getYearOptions = () => {
  const startYear = new Date().getFullYear() - 24;

  return Array.from(new Array(26), (_, i) => {
    return { value: `${startYear + i}`, label: `${startYear + i}` };
  });
};

export const getCustomYearOptions = ({ startYear, endYear }) => {
  const diffYears = endYear - startYear + 1;

  return Array.from(new Array(diffYears), (_, i) => {
    return { value: `${startYear + i}`, label: `${startYear + i}` };
  });
};

export const getDayOptions = ({ month, year }) => {
  const leapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  let day_array = [...MONTH_DAYS];
  if (month === "2" && leapYear) {
    day_array[1] = 29;
  }

  const days = [];
  for (let i = 1; i <= day_array[month - 1]; i++) {
    days.push({ value: `${i}`, label: `${i}` });
  }
  return days;
};

export const getStatesOptions = (isEmptyIncludes = false) => {
  const statesArray = [];

  if (isEmptyIncludes) {
    statesArray.push({ value: "", label: "Select..." });
  }

  Object.keys(STATE_MAP).forEach((key) => {
    statesArray.push({ value: key, label: STATE_MAP[key] });
  });

  return statesArray;
};

export function isLeapYear(value) {
  const year = Number(value);
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export const createDate = (form, dateId) => {
  if (!form[dateId + "Year"] && form[dateId + "Year"]?.length === 0) {
    return null;
  }

  const year = parseInt(form[dateId + "Year"]);
  const month = parseInt(form[dateId + "Month"]) - 1; // to start from 0
  const day = parseInt(form[dateId + "Day"]);

  if (!Number.isNaN(new Date(year, month, day, 10, 0, 0).getTime())) {
    return new Date(year, month, day, 10, 0, 0).toISOString().split(".")[0]?.split("T")[0];
  }

  return null;
};
