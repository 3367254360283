import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";
import Indicator from "components/atomic/atoms/Indicator";
import Table from "components/atomic/molecules/Table";
import EditPaymentMethodModal from "components/atomic/organisms/modals/EditPaymentMethodModal";
import Card from "components/layouts/Card";
import CardContent from "components/layouts/CardContent";
import CardHeader from "components/layouts/CardHeader";

const StyledCard = styled(Card)`
  width: 50%;
  height: 271px;

  @media (max-width: 991px) {
    width: 100%;
    max-width: 700px;
    height: 320px;
  }
`;

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const StyledCardContent = styled(CardContent)`
  overflow-y: unset;

  @media (max-width: 450px) {
    padding: 12px;
  }
`;

const Date = styled.div`
  font-style: italic;
`;

const CardNumber = styled.div`
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

const getPaymentMethodsColumns = () => {
  return [
    {
      dataIndex: "primary",
      title: "",
      width: "6%",
      render: (row) => <Indicator status={row?.primary} />,
    },
    {
      dataIndex: "cardType",
      title: "",
      width: "14%",
      render: (row) => <Icon icon={`billing:${row?.cardType?.toLowerCase()}`} />,
    },
    {
      dataIndex: "cardNumber",
      title: "Card Number",
      width: "50%",
      render: (row) => <CardNumber>{`**** **** **** ${row?.cardNumber}`}</CardNumber>,
    },
    {
      dataIndex: "expired",
      title: "Expired",
      width: "30%",
      render: (row) => <Date>{`${row?.cardMonth} / ${row?.cardYear}`}</Date>,
    },
  ];
};

const PaymentMethods = ({ loading, data, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [primaryId, setPrimaryId] = useState(null);
  const columns = getPaymentMethodsColumns();

  const handleEditShow = () => {
    setOpen(true);
  };

  const handleEditHide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const { paySourceId } = data?.find(({ primary }) => primary) || {};

    if (paySourceId) {
      setPrimaryId(paySourceId);
    }
  }, [data]);

  return (
    <>
      <StyledCard>
        <StyledCardHeader>
          <div>Payment Methods</div>
          <StyledButton borderless onClick={handleEditShow}>
            Edit <Icon icon="billing:edit" />
          </StyledButton>
        </StyledCardHeader>
        <StyledCardContent>
          <Table columns={columns} rows={data} loading={loading} skeletonRowsNum={2} />
        </StyledCardContent>
      </StyledCard>
      {open && <EditPaymentMethodModal primaryId={primaryId} onSave={onUpdate} onCancel={handleEditHide} />}
    </>
  );
};

PaymentMethods.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      cardNumber: PropTypes.string,
      cardType: PropTypes.string,
      cardMonth: PropTypes.string,
      cardYear: PropTypes.string,
      primary: PropTypes.bool,
      paySourceId: PropTypes.string,
    })
  ),
  onUpdate: PropTypes.func,
};

export default PaymentMethods;
