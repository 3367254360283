import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import AreaInput from "components/atomic/atoms/AreaInput";

import FieldWrapper from "../FieldWrapper";

const AreaField = ({ column, label, name, control, rules = {}, errors = {}, ...restProps }) => {
  return (
    <FieldWrapper column={column} label={label} name={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => <AreaInput {...restProps} {...field} />}
      />
    </FieldWrapper>
  );
};

AreaField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default AreaField;
