import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";
import { useTheme } from "styled-components";
import styled from "styled-components";

import Icon from "components/atomic/atoms/Icon";
import PasswordValidation from "components/atomic/atoms/PasswordValidation";

const Container = styled.div`
  box-sizing: border-box;
  position: relative;

  & > input[type="password"] {
    color: ${({ theme }) => theme.colors["cl-text-light"]};
    letter-spacing: 3px;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 9px 50px 9px 20px;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
  font-family: inherit;
  color: ${({ theme }) => theme.colors["cl-text-dark"]};

  &::placeholder {
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors["cl-text-light"]};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors["cl-text-dark"]};
    border-radius: 8px;
  }
`;

const HideButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: absolute;
  right: 8px;
  top: 0;
  border: none;
  outline: none;
  height: 100%;
  padding: 4px;

  &:focus {
    border: 1px solid black;
    border-radius: 8px;
  }
`;

// eslint-disable-next-line react/display-name
const InputPassword = forwardRef(
  ({ placeholder, required, validation, value = "", onChange, formContext, ...otherProps }, ref) => {
    const [isShow, setIsShow] = useState(false);
    const themeContext = useTheme();
    const hasContext = formContext !== undefined;

    return (
      <>
        <Container>
          <StyledInput
            type={isShow ? "text" : "password"}
            placeholder={placeholder}
            required={required}
            ref={ref}
            value={value}
            onChange={onChange}
            {...otherProps}
          />

          <HideButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setIsShow(!isShow);
            }}
          >
            {isShow ? (
              <Icon icon="password:hide" color={themeContext.colors["cl-text-light"]} />
            ) : (
              <Icon icon="password:show" color={themeContext.colors["cl-text-light"]} />
            )}
          </HideButton>
        </Container>
        {validation && hasContext && formContext?.isDirty && <PasswordValidation sm password={value} />}
        {validation && !hasContext && <PasswordValidation sm password={value} />}
      </>
    );
  }
);

InputPassword.propTypes = {
  placeholder: PropTypes.string,
  formContext: PropTypes.any,
  required: PropTypes.bool,
  validation: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputPassword;
