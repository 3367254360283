import React from "react";

import { CUSTOM_ACTION_CODES } from "_constants/products";

import BoiOfferActionForm from "components/atomic/organisms/forms/actions/BoiOfferActionForm";
import CustomerIDNeededActionForm from "components/atomic/organisms/forms/actions/CustomerIDNeededActionForm";
import EINInfoActionForm from "components/atomic/organisms/forms/actions/EINInfoActionForm";
import RejectionLetterActionForm from "components/atomic/organisms/forms/actions/RejectionLetterActionForm";
import SSNNeededActionForm from "components/atomic/organisms/forms/actions/SSNNeededActionForm";
import ActionErrorForm from "components/atomic/organisms/sections/ActionDetails/ActionErrorForm";

const ActionForm = (props) => {
  const { status, type } = props;

  function renderActionForm() {
    switch (status) {
      case "AwaitingBusinessEINInformation":
        return <EINInfoActionForm {...props} />;

      case "Rejected":
        return <RejectionLetterActionForm {...props} />;

      case "CustomerIDNeeded":
        return <CustomerIDNeededActionForm {...props} />;

      case "SSNNeeded":
        return <SSNNeededActionForm {...props} />;

      // case "Abandoned":
      // case "Processing":
      // case "AgentReview":
      //   return (
      //     <div>
      //       <Icon inline icon="misc:timer" size="32px" color="#347cd5" /> No actions is needed yet, please wait for
      //       processing
      //     </div>
      //   );

      case "Error":
        return <ActionErrorForm {...props} />;

      default:
        return <div>No available quick actions</div>;
    }
  }

  function renderCustomActionForm() {
    switch (type) {
      case CUSTOM_ACTION_CODES.boiOffer:
        return <BoiOfferActionForm {...props} />;

      default:
        return <div>No available quick actions</div>;
    }
  }

  return type === "ACTION" ? renderActionForm() : renderCustomActionForm();
};

export default ActionForm;
