import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import Switch from "components/atomic/atoms/Switch";

import FieldWrapper from "../FieldWrapper";

const SwitchField = ({ column, label, name, control, rules = {}, errors = {}, ...restProps }) => {
  return (
    <FieldWrapper column={column} name={name} label={label} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        // eslint-disable-next-line no-unused-vars
        render={({ field: { ref, ...restField } }) => <Switch {...restProps} {...restField} />}
      />
    </FieldWrapper>
  );
};

SwitchField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default SwitchField;
