import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import usePrevious from "_hooks/usePrevious";
import { CustomerService } from "_services";

import AsyncSelect from "components/atomic/atoms/AsyncSelect";

function TrademarkCategorySelect({ disabled, secondary, goods, value, onChange, ...restProps }) {
  const [loading, setLoading] = useState(false);
  const [rawValue, setRawValue] = useState(null);
  const prevGoods = usePrevious(goods);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      setRawValue(value);
      onChange && onChange(e);
    },
    [onChange]
  );

  const loadOptions = async (search, prevOptions, { page }) => {
    const options = [];
    let hasMore = false;

    setLoading(true);

    try {
      const { elements, maxPerPage, totalResults } = await CustomerService.getUSPTOCategories({
        page,
        count: 10,
        search,
        isGoods: goods === "true",
      });

      hasMore = totalResults > maxPerPage * page;

      elements.forEach(({ termId, description }) => {
        options.push({
          label: `${termId} - ${description}`,
          value: `${termId} - ${description}`,
        });
      });
    } catch (e) {
      setLoading(false);

      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }

    setLoading(false);

    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    if (prevGoods !== goods && value) {
      const checkGoodsCategory = async () => {
        setLoading(true);

        const { elements } = await CustomerService.getUSPTOCategories({
          page: 1,
          count: 5,
          termId: value?.split(" - ")[0],
          isGoods: goods,
        });

        const { termId, description } = elements[0] || {};

        setRawValue(termId ? `${termId} - ${description}` : null);
        setLoading(false);
      };

      checkGoodsCategory();
    }
  }, [prevGoods, goods, value]);

  return (
    <AsyncSelect
      refreshKey={goods}
      disabled={disabled}
      secondary={secondary}
      loading={loading}
      loadOptions={loadOptions}
      value={rawValue}
      onChange={handleChange}
      {...restProps}
    />
  );
}

TrademarkCategorySelect.propTypes = {
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  goods: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TrademarkCategorySelect;
