import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { usePaymentInputs } from "react-payment-inputs";
import styled from "styled-components";

import { checkIfTestCard } from "_helpers";

import Icon from "components/atomic/atoms/Icon";
import CheckboxField from "components/atomic/molecules/fields/CheckboxField";
import SelectField from "components/atomic/molecules/fields/SelectField";
import TextField from "components/atomic/molecules/fields/TextField";

export const getMonthOptions = () => [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const getYearOptions = () => {
  const startYear = new Date().getFullYear();

  return Array.from(new Array(20), (_, i) => {
    return { value: `${(startYear + i) % 100}`, label: `${startYear + i}` };
  });
};

const checkIfCardExpired = ({ cardMonth, cardYear }) => {
  if (cardYear !== null && cardMonth !== null) {
    let expDate = new Date();
    expDate.setFullYear("20" + cardYear, cardMonth, 1);
    let today = new Date();
    if (expDate < today) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

const initValues = {
  cardType: "testcard",
  cardNumber: "",
  cardMonth: "",
  cardYear: "",
  cvv: "",
  cardReplacing: false,
};

const rules = {
  cardNumber: ({ erroredFields, touchedFields }) => ({
    required: "Card Number is a required field",
    validate: (value) => {
      const isTestCard = checkIfTestCard(value);
      const isTouchedAndInvalid = touchedFields.cardNumber && erroredFields.cardNumber;
      return !isTestCard && isTouchedAndInvalid ? erroredFields.cardNumber : true;
    },
  }),
  cardMonth: ({ getValues, clearErrors, checkIfCardExpired }) => ({
    required: "Month is a required field",
    validate: (value) => {
      clearErrors("cardYear");
      const { cardYear } = getValues();
      const isCardExpired = checkIfCardExpired({ cardMonth: value, cardYear });
      return isCardExpired && cardYear ? "This card expired" : true;
    },
  }),
  cardYear: ({ getValues, clearErrors, checkIfCardExpired, trigger }) => ({
    required: "Year is a required field",
    validate: (value) => {
      clearErrors("cardMonth");
      const { cardMonth } = getValues();
      const isCardExpired = checkIfCardExpired({ cardMonth, cardYear: value });
      trigger("cardMonth");
      return isCardExpired && cardMonth ? "This card expired" : true;
    },
  }),
  cvv: ({ touchedFields, erroredFields }) => ({
    required: "CVC/CVV is a required field",
    pattern: {
      value: /^[0-9]{3,4}$/,
      message: "CVV/CVC is invalid",
    },
    validate: () => {
      const isTouchedAndInvalid = touchedFields.cvv && erroredFields.cvv;
      return isTouchedAndInvalid ? erroredFields.cvv : true;
    },
  }),
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

const FieldsGroupRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  & > * {
    flex: 1;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

// eslint-disable-next-line react/display-name
const EditPaymentMethodForm = forwardRef(({ onSubmit }, ref) => {
  const [monthAutocompleteValue, setMonthAutocompleteValue] = useState("");
  const [yearAutocompleteValue, setYearAutocompleteValue] = useState("");

  const handleMonthAutocompleteValue = (e) => {
    const { value } = e.target || {};
    setMonthAutocompleteValue(value);
  };

  const handleYearAutocompleteValue = (e) => {
    const { value } = e.target || {};
    setYearAutocompleteValue(value);
  };

  const {
    control,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    clearErrors,
    trigger,
    handleSubmit: createSubmitHandler,
  } = useForm({ defaultValues: initValues });

  const handleSubmit = createSubmitHandler(onSubmit);

  const { meta, getCardNumberProps } = usePaymentInputs();
  const { cardType, erroredInputs: erroredFields } = meta;

  useImperativeHandle(
    ref,
    () => ({
      submit: () => handleSubmit(),
    }),
    [handleSubmit]
  );

  useEffect(() => {
    if (monthAutocompleteValue) {
      setMonthAutocompleteValue("");
      setValue("cardMonth", monthAutocompleteValue, {
        shouldValidate: true,
      });
    }
  }, [monthAutocompleteValue, setValue]);

  useEffect(() => {
    if (yearAutocompleteValue) {
      setYearAutocompleteValue("");
      setValue("cardYear", yearAutocompleteValue, {
        shouldValidate: true,
      });
    }
  }, [yearAutocompleteValue, setValue]);

  return (
    <StyledForm ref={ref} onSubmit={handleSubmit}>
      <TextField
        column
        prefix={<Icon icon={`billing:${cardType?.type || "testcard"}`} />}
        name="cardNumber"
        label="Card Number"
        placeholder="0000 0000 0000 0000"
        data-threeds="pan"
        getPaymentProps={getCardNumberProps}
        autoComplete="cc-number"
        control={control}
        errors={errors}
        rules={rules.cardNumber({ erroredFields, touchedFields })}
      />
      <FieldsGroupRow>
        <select
          hidden
          id="cc-exp-month"
          name="cc-exp-month"
          autoComplete="cc-exp-month"
          value={monthAutocompleteValue}
          onChange={handleMonthAutocompleteValue}
        >
          <option value="" hidden />
          {getMonthOptions().map(({ title, value }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </select>

        <select
          hidden
          id="cc-exp-year"
          name="cc-exp-year"
          autoComplete="cc-exp-year"
          value={yearAutocompleteValue}
          onChange={handleYearAutocompleteValue}
        >
          <option value="" hidden />
          {getYearOptions().map(({ title, value }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </select>

        <SelectField
          column
          label="Expiration Date / Month"
          placeholder="Month"
          name="cardMonth"
          options={getMonthOptions()}
          isSearchable={!monthAutocompleteValue}
          // autoComplete="cc-exp-month"
          control={control}
          errors={errors}
          rules={rules.cardMonth({ getValues, clearErrors, checkIfCardExpired })}
        />
        <SelectField
          column
          label="Expiration Date / Year"
          placeholder="Year"
          name="cardYear"
          options={getYearOptions()}
          isSearchable={!yearAutocompleteValue}
          // autoComplete="cc-exp-year"
          control={control}
          errors={errors}
          rules={rules.cardYear({ getValues, clearErrors, trigger, checkIfCardExpired })}
        />
      </FieldsGroupRow>
      <TextField
        column
        label="CVC/CVV"
        placeholder="CVC/CVV"
        name="cvv"
        data-threeds="pan"
        autoComplete="cc-csc"
        control={control}
        errors={errors}
        rules={rules.cvv({ erroredFields, touchedFields })}
      />
      <CheckboxField name="cardReplacing" label="Replace the Current Card on File" control={control} errors={errors} />
    </StyledForm>
  );
});

EditPaymentMethodForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default EditPaymentMethodForm;
