import { css } from "styled-components";

import PoppinsLatin100ItalicWoff from "./poppins-latin-100-italic.woff";
import PoppinsLatin100NormalWoff from "./poppins-latin-100-normal.woff";
import PoppinsLatin200ItalicWoff from "./poppins-latin-200-italic.woff";
import PoppinsLatin200NormalWoff from "./poppins-latin-200-normal.woff";
import PoppinsLatin300ItalicWoff from "./poppins-latin-300-italic.woff";
import PoppinsLatin300NormalWoff from "./poppins-latin-300-normal.woff";
import PoppinsLatin400ItalicWoff from "./poppins-latin-400-italic.woff";
import PoppinsLatin400NormalWoff from "./poppins-latin-400-normal.woff";
import PoppinsLatin500ItalicWoff from "./poppins-latin-500-italic.woff";
import PoppinsLatin500NormalWoff from "./poppins-latin-500-normal.woff";
import PoppinsLatin600ItalicWoff from "./poppins-latin-600-italic.woff";
import PoppinsLatin600NormalWoff from "./poppins-latin-600-normal.woff";
import PoppinsLatin700ItalicWoff from "./poppins-latin-700-italic.woff";
import PoppinsLatin700NormalWoff from "./poppins-latin-700-normal.woff";
import PoppinsLatin800ItalicWoff from "./poppins-latin-800-italic.woff";
import PoppinsLatin800NormalWoff from "./poppins-latin-800-normal.woff";
import PoppinsLatin900ItalicWoff from "./poppins-latin-900-italic.woff";
import PoppinsLatin900NormalWoff from "./poppins-latin-900-normal.woff";

export default css`
  /* Normal */
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin100NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 100;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin200NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 200;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin300NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 300;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin400NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin500NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin600NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin700NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 700;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin800NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 800;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin900NormalWoff}) format("woff");
    font-style: normal;
    font-weight: 900;
  }

  /* Italic */
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin100ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 100;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin200ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 200;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin300ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 300;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin400ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 400;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin500ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 500;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin600ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 600;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin700ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 700;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin800ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 800;
  }
  @font-face {
    font-family: "Poppins";
    src: url(${PoppinsLatin900ItalicWoff}) format("woff");
    font-style: italic;
    font-weight: 900;
  }
`;
