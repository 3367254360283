import axios from "axios";

import { STORAGE_KEYS } from "_constants/storage";
import { restoreLocalValue } from "_helpers/storage";

export class AxiosClient {
  constructor(url) {
    this._url = url;
    this._Authorization = null;
  }

  get baseURL() {
    return this._url;
  }

  get Authorization() {
    return this._Authorization;
  }

  set Authorization(value) {
    this._Authorization = value;
  }

  get client() {
    const headers = {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    };

    if (this.Authorization) {
      headers.Authorization = this.Authorization;
    }

    if (restoreLocalValue(STORAGE_KEYS.accessToken)) {
      this.Authorization = null;
      headers.Authorization = `Bearer ${restoreLocalValue(STORAGE_KEYS.accessToken)}`;
    }

    return axios.create({
      baseURL: this.baseURL,
      headers,
    });
  }
}
