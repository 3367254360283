import { STORAGE_KEYS } from "_constants/storage";

export const storeLocalValue = (key, value) => {
  if (typeof window !== "undefined" && key) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const restoreLocalValue = (key, defaultValue = null) => {
  if (typeof window === "undefined" || !key) {
    return defaultValue;
  }

  const value = localStorage.getItem(key);
  try {
    const parsedValue = JSON.parse(value);
    return parsedValue || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const clearLocalValue = (key) => {
  if (typeof window !== "undefined" && key) {
    localStorage.removeItem(key);
  }
};

export const clearAllLocals = () => {
  if (typeof window !== "undefined") {
    localStorage.clear();
  }
};

export const clearAllIncorpifyLocals = () => {
  if (typeof window !== "undefined") {
    Object.values(STORAGE_KEYS).forEach((key) => localStorage.removeItem(key));
  }
};

export const storeSessionValue = (key, value) => {
  if (typeof window !== "undefined" && key) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const restoreSessionValue = (key, defaultValue = null) => {
  if (typeof window === "undefined" || !key) {
    return defaultValue;
  }

  const value = sessionStorage.getItem(key);
  try {
    const parsedValue = JSON.parse(value);
    return parsedValue || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const clearSessionValue = (key) => {
  if (typeof window !== "undefined" && key) {
    sessionStorage.removeItem(key);
  }
};

export const clearAllSessions = () => {
  if (typeof window !== "undefined") {
    sessionStorage.clear();
  }
};
