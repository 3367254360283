import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { PRODUCT_CODES } from "_constants/products";
import { currencyFormatter, handleError } from "_helpers";
import { OrdersService } from "_services";
import {
  selectActiveOrderId,
  selectFeeMapByProductCode,
  selectFeesLoading,
  selectMainProduct,
} from "_store/orders/selector";
import { calculateFees, getOrders } from "_store/orders/slice";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Icon from "components/atomic/atoms/Icon";
import { SpinnerBlock, SpinnerBlockWrapper } from "components/atomic/atoms/Spinner";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const SummaryContainer = styled.div`
  position: relative;
  padding: 10px;
  margin-top: 16px;
  box-shadow: 0 2px 0px 2px var(--bs-modal-header-border-color), 0 -2px 0px 2px var(--bs-modal-header-border-color);
  border-radius: 4px;
`;

const Text = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }

  ${({ textCenter }) =>
    textCenter &&
    css`
      width: 100%;
      text-align: center;
    `}

  ${({ textJustify }) =>
    textJustify &&
    css`
      width: 100%;
      text-align: justify;
    `}

  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
      align-items: end;
    `}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`;

const ErrorText = styled.div`
  font-weight: bold;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-danger"]};
  `}
`;

const ErrorMessage = styled.div`
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 0 4px gray inset;
  font-family: monospace;
  word-wrap: break-word;
`;

const StyledIcon = styled(Icon)`
  display: inline;
`;

const STEPS = {
  init: "",
  start: "step-start",
  loading: "step-loading",
  success: "step-success",
  error: "step-error",
};

// eslint-disable-next-line no-unused-vars
const DissolveOrderModal = ({ onCancel }) => {
  const dispatch = useDispatch();

  const orderId = useSelector(selectActiveOrderId);
  const customerId = useSelector(selectCustomerId);
  const mainProduct = useSelector(selectMainProduct);
  const stateFeeLoading = useSelector(selectFeesLoading);
  const basicPriceObj = useSelector(selectFeeMapByProductCode(PRODUCT_CODES.incDissolution));
  const stateFeeObj = useSelector(selectFeeMapByProductCode(PRODUCT_CODES.incDissolutionFilingFee));

  const [error, setError] = useState(null);
  const [step, setStep] = useState(STEPS.init);

  const processingOption = mainProduct?.processingOption;

  const basicPrice = basicPriceObj[processingOption] || 0;
  const stateFee = stateFeeObj[processingOption] || 0;
  const total = basicPrice + stateFee;
  const isCanCharge = !stateFeeLoading && basicPrice > 0 && stateFee >= 0 && total > 0;

  function handleCloseModal({ update = false }) {
    setError(null);
    setStep(STEPS.init);
    onCancel();

    update && customerId && dispatch(getOrders({ customerId }));
    update && orderId && customerId && dispatch(getOrder({ orderId, customerId }));
  }

  function renderPrice(price, loading) {
    if (!loading) {
      if (price === 0) {
        return currencyFormatter(price);
      }

      return currencyFormatter(price);
    }

    return "";
  }

  async function dissolveOrder(orderId) {
    try {
      const request = await OrdersService.dissolveOrder(orderId);

      if (request?.status === 200) {
        if (request?.data?.success) {
          setStep(STEPS.success);
        } else {
          setStep(STEPS.error);
          setError(handleError(request));
        }
      } else {
        setStep(STEPS.error);
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep(STEPS.error);
      setError(handleError(response));
    }
  }

  useEffect(() => {
    orderId && setStep(STEPS.start);
  }, [orderId]);

  useEffect(() => {
    if (mainProduct?.code?.category && mainProduct?.organizedState && processingOption) {
      dispatch(
        calculateFees({
          state: mainProduct?.organizedState,
          codes: [PRODUCT_CODES.incDissolution, PRODUCT_CODES.incDissolutionFilingFee],
          processingOption: processingOption,
        })
      );
    }
  }, [dispatch, mainProduct]);

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: false })}>
          <StyledHeader closeButton>Request Dissolution</StyledHeader>
          <StyledBody>
            <Row>Are you sure you want to dissolve your business?</Row>
            <Row>
              <SummaryContainer>
                <Text spaceBetween>
                  Dissolution Service: <b>{renderPrice(basicPrice, stateFeeLoading)}</b>
                </Text>
                <Text spaceBetween>
                  State Fee: <b>{renderPrice(stateFee, stateFeeLoading)}</b>
                </Text>
                <hr />
                <Text spaceBetween>
                  Total: <b>{renderPrice(total, stateFeeLoading)}</b>
                </Text>
                {stateFeeLoading && <SpinnerBlockWrapper />}
              </SummaryContainer>
            </Row>

            <Buttons>
              <Button variant="secondary" onClick={() => handleCloseModal({ update: false })}>
                Cancel
              </Button>
              <Button
                variant="success"
                disabled={!isCanCharge}
                onClick={() => {
                  setStep(STEPS.loading);
                  dissolveOrder(orderId);
                }}
              >
                Process
              </Button>
            </Buttons>
          </StyledBody>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => {}}>
          <StyledHeader>Request Dissolution</StyledHeader>
          <StyledBody>
            <SpinnerBlock message="Sending Request..." />
          </StyledBody>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader>Request Dissolution</StyledHeader>
          <StyledBody>
            <Row>Thank you! We have received your dissolution request.</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered show size="md" backdrop="static" onHide={() => handleCloseModal({ update: true })}>
          <StyledHeader>Request Dissolution</StyledHeader>
          <StyledBody>
            <Row>
              <ErrorText>
                <StyledIcon icon="error:alert" size="24px" />
                &nbsp;Error:
              </ErrorText>
              <ErrorMessage>{error?.message || "Something went wrong..."}</ErrorMessage>
            </Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseModal({ update: true })}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

DissolveOrderModal.propTypes = {
  onCancel: PropTypes.func,
};

export default DissolveOrderModal;
