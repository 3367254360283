import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledPrefixContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 44px;
  width: 52px;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;

  font-size: 0.875rem;
  font-weight: 400;

  ${({ theme, prefix }) => css`
    height: 44px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding-left: ${prefix ? "52px" : "20px"};
    padding-right: 20px;
    padding-top: 9px;
    padding-bottom: 9px;
    outline: none;
    color: ${theme.colors["cl-text-dark"]};

    &::placeholder {
      color: ${theme.colors["cl-text-light"]};
    }

    &:focus {
      border: 1px solid;
      border-radius: 8px;
    }
  `}
`;

// eslint-disable-next-line react/display-name
const Input = forwardRef(({ prefix, value, ...restProps }, ref) => {
  return (
    <StyledInputContainer>
      {prefix && <StyledPrefixContainer>{prefix}</StyledPrefixContainer>}
      <StyledInput ref={ref} prefix={prefix} value={value || ""} {...restProps} />
    </StyledInputContainer>
  );
});

Input.propTypes = {
  prefix: PropTypes.node,
  value: PropTypes.string,
};

export default Input;
