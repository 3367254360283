import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setPasswordSuccessModalClose } from "_store/user/slice";

const SetPasswordSuccessModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirm = () => {
    dispatch(setPasswordSuccessModalClose());
    navigate("../login");
  };

  return (
    <Modal centered show onHide={handleConfirm} size="md">
      <Modal.Header style={{ color: "green" }}>Success</Modal.Header>
      <Modal.Body>
        Password successfully set. You may now login to your account using your e-mail and password.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetPasswordSuccessModal;
