export function reverse(arr) {
  if (!arr?.length) {
    return [];
  }

  const newArray = [...arr];
  newArray.reverse();

  return newArray;
}
