import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { selectLoading, selectTrademarkSearchResults } from "_store/orders/selector";

import Button from "components/atomic/atoms/Button";
import TrademarkSearchResults from "components/atomic/organisms/sections/TrademarkSearchResults";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    align-items: center;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledCol = styled(Col)`
  margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const TrademarkSearchReportPage = () => {
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const searchResults = useSelector(selectTrademarkSearchResults);

  const handleGoBack = () => {
    navigate("..");
  };

  return (
    <Container>
      <ContentRow>
        <TrademarkSearchResults title="Federal Search Results" loading={loading} data={searchResults} />
      </ContentRow>
      <ContentRow>
        <StyledRow>
          <Col xs={0} md={4} />
          <StyledCol xs={12} md={4}>
            <StyledButton onClick={handleGoBack}>Go Back</StyledButton>
          </StyledCol>
          <Col xs={0} md={4} />
        </StyledRow>
      </ContentRow>
    </Container>
  );
};

export default TrademarkSearchReportPage;
