import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import InputCustomMask from "components/atomic/molecules/InputCustomMask";

import FieldWrapper from "../FieldWrapper";

const StyledInputCustomMask = styled(InputCustomMask)`
  &:focus {
    border: 1px solid;
    border-radius: 8px;
  }
`;

const CustomMaskField = ({ column, label, name, control, rules = {}, errors = {}, ...restProps }) => {
  return (
    <FieldWrapper column={column} label={label} name={name} errors={errors}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => <StyledInputCustomMask {...restProps} {...field} />}
      />
    </FieldWrapper>
  );
};

CustomMaskField.propTypes = {
  column: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
};

export default CustomMaskField;
