import store from "_store";
import { logout } from "_store/user/slice";

export function handleResponse(response) {
  if (Array.isArray(response)) {
    return response.map(({ data } = {}) => {
      if (typeof data?.values !== "function" && data?.values) {
        return data?.values;
      }

      return data;
    });
  }

  if (typeof response?.data?.values !== "function" && response?.data?.values) {
    return response?.data?.values;
  }

  return response?.data;
}

export function handleError(axiosError) {
  const { status, data } = axiosError?.response || {};

  if (status === 401 || status === 403) {
    store.dispatch(logout());
  }

  const error = data || axiosError?.data || axiosError;

  if (error && error?.message?.errors?.length) {
    return { message: error.message.errors[0]?.toString() };
  }

  if (error && !error?.message?.errors?.length && error?.errors?.length) {
    return { message: error.errors?.join(" \n") };
  }

  if (error && !error?.message?.errors && error?.message) {
    const message = error.message?.toString();

    if (message === "Card Declined") {
      return { message: "Uh oh - your card on file has been declined. Please update your payment method to proceed." };
    }

    return { message };
  }

  if (error && !error?.message?.errors && !error?.message && error?.code) {
    return { message: `${error.code?.toString()} Something went wrong` };
  }

  if (error && !error?.message?.errors && !error?.message && !error?.code && error?.toString() === "[object Blob]") {
    return { message: "An error occurred while downloading the file" };
  }

  return { message: error?.toString() };
}
