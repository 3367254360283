import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  selectLoading,
  selectTrademarkDetailsData,
  selectTrademarkFiles,
  selectTrademarkForm,
  selectTrademarkOwner,
  selectTrademarkProduct,
} from "_store/orders/selector";
import { getFileFromProduct } from "_store/orders/slice";

import Button from "components/atomic/atoms/Button";
import TrademarkClasses from "components/atomic/organisms/sections/TrademarkClasses";
import TrademarkDetails from "components/atomic/organisms/sections/TrademarkDetails";
import TrademarkDocuments from "components/atomic/organisms/sections/TrademarkDocuments";
import TrademarkOwners from "components/atomic/organisms/sections/TrademarkOwners";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  max-height: 100%;

  border-top: 1px solid #dbe4f0;
  border-bottom: 1px solid #dbe4f0;

  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    align-items: center;
    gap: 10px;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledCol = styled(Col)`
  margin-bottom: 30px;

  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const TrademarkPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector(selectLoading);
  const trademarkProduct = useSelector(selectTrademarkProduct);
  const trademarkFormValues = useSelector(selectTrademarkForm);
  const trademarkDetailsData = useSelector(selectTrademarkDetailsData);
  const trademarkFiles = useSelector(selectTrademarkFiles);
  const trademarkOwner = useSelector(selectTrademarkOwner);

  const handleEditDetails = () => {
    navigate("../trademark/edit/details");
  };

  const handleEditDocuments = () => {
    navigate("../trademark/edit/documents");
  };

  const handleEditClasses = () => {
    navigate("../trademark/edit/classes");
  };

  const handleSearchReport = () => {
    navigate("../trademark/report/search");
  };

  const handleMonitoringReport = () => {
    navigate("../trademark/report/monitoring");
  };

  const downloadHandler = (orderId, productId, fileType) => {
    dispatch(getFileFromProduct({ orderId, productId, fileType }));
  };

  return (
    <>
      <Container>
        <ContentRow>
          <TrademarkDetails
            disabled={!trademarkProduct}
            loading={loading}
            data={trademarkDetailsData}
            onEdit={handleEditDetails}
          />
          <TrademarkDocuments
            disabled={!trademarkProduct}
            loading={loading}
            data={trademarkFiles}
            onEdit={handleEditDocuments}
            onDownload={downloadHandler}
          />
        </ContentRow>
        <ContentRow>
          <TrademarkClasses
            disabled={!trademarkProduct}
            loading={loading}
            data={trademarkFormValues.classes}
            onEdit={handleEditClasses}
          />
        </ContentRow>
        <ContentRow>
          <TrademarkOwners loading={loading} data={[trademarkOwner]} />
        </ContentRow>
        <ContentRow>
          <StyledRow>
            <Col xs={0} md={6} />
            <StyledCol xs={12} md={3}>
              <StyledButton primary onClick={handleSearchReport}>
                Search Results
              </StyledButton>
            </StyledCol>
            <StyledCol xs={12} md={3}>
              <StyledButton primary onClick={handleMonitoringReport}>
                Monitoring Reports
              </StyledButton>
            </StyledCol>
          </StyledRow>
        </ContentRow>
      </Container>
    </>
  );
};

export default TrademarkPage;
