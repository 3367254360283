import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as BillingAmexCard } from "./assets/Billing/CardAMEX.svg";
import { ReactComponent as BillingDiscoverCard } from "./assets/Billing/CardDISCOVER.svg";
import { ReactComponent as BillingMastercardCard } from "./assets/Billing/CardMASTERCARD.svg";
import { ReactComponent as BillingTestCard } from "./assets/Billing/CardTESTCARD.svg";
import { ReactComponent as BillingVisaCard } from "./assets/Billing/CardVISA.svg";
import { ReactComponent as BillingDate } from "./assets/Billing/date.svg";
import { ReactComponent as BillingEdit } from "./assets/Billing/edit.svg";
import { ReactComponent as BillingView } from "./assets/Billing/view.svg";
import { ReactComponent as DocumentsAnnual } from "./assets/Documents/annual.svg";
import { ReactComponent as DocumentsArticles } from "./assets/Documents/articles.svg";
import { ReactComponent as DefaultPDF } from "./assets/Documents/default_pdf.svg";
import { ReactComponent as DocumentsDownload } from "./assets/Documents/download.svg";
import { ReactComponent as DocumentsEin } from "./assets/Documents/ein.svg";
import { ReactComponent as DocumentsFiling } from "./assets/Documents/filing.svg";
import { ReactComponent as DocumentsStatement } from "./assets/Documents/statement.svg";
import { ReactComponent as HeaderAlerts } from "./assets/Header/alerts.svg";
import { ReactComponent as HeaderHelp } from "./assets/Header/help.svg";
import { ReactComponent as HeaderLogout } from "./assets/Header/logout.svg";
import { ReactComponent as HeaderMenu } from "./assets/Header/menu.svg";
import { ReactComponent as HeaderSettings } from "./assets/Header/settings.svg";
import { ReactComponent as PasswordHide } from "./assets/InputPassword/hide.svg";
import { ReactComponent as PasswordShow } from "./assets/InputPassword/show.svg";
import { ReactComponent as ErrorAlert } from "./assets/InputWrapper/alert.svg";
import { ReactComponent as MailboxOpen } from "./assets/Mailbox/detailsMore.svg";
import { ReactComponent as MiscBackArrow } from "./assets/Misc/backArrow.svg";
import { ReactComponent as MiscExternalLink } from "./assets/Misc/externalLink.svg";
import { ReactComponent as MiscHandClick } from "./assets/Misc/handClick.svg";
import { ReactComponent as MiscInfo } from "./assets/Misc/info.svg";
import { ReactComponent as MiscMessage } from "./assets/Misc/message.svg";
import { ReactComponent as MiscSuccessCheck } from "./assets/Misc/successCheck.svg";
import { ReactComponent as MiscTimer } from "./assets/Misc/timer.svg";
import { ReactComponent as ProfileDate } from "./assets/Profile/date.svg";
import { ReactComponent as ProfileOrder } from "./assets/Profile/order.svg";
import { ReactComponent as ProfileStatus } from "./assets/Profile/status.svg";
import { ReactComponent as ServicesAgent } from "./assets/Services/agent.svg";
import { ReactComponent as ServicesAnnualReport } from "./assets/Services/annualReport.svg";
import { ReactComponent as ServicesBankingResolutino } from "./assets/Services/bankingResolution.svg";
import { ReactComponent as ServicesBookkeeping } from "./assets/Services/bookkeeping.svg";
import { ReactComponent as ServicesCreditCards } from "./assets/Services/creditCards.svg";
import { ReactComponent as ServicesEinTaxId } from "./assets/Services/einTaxId.svg";
import { ReactComponent as ServicesMerchant } from "./assets/Services/merchant.svg";
import { ReactComponent as ServicesOperatingAgreement } from "./assets/Services/operatingAgreement.svg";
import { ReactComponent as ServicesTrademark } from "./assets/Services/trademark.svg";
import { ReactComponent as SidebarActions } from "./assets/Sidebar/actions.svg";
import { ReactComponent as SidebarBilling } from "./assets/Sidebar/billing.svg";
import { ReactComponent as SidebarClose } from "./assets/Sidebar/close.svg";
import { ReactComponent as SidebarDocuments } from "./assets/Sidebar/documents.svg";
import { ReactComponent as SidebarLicenses } from "./assets/Sidebar/licenses.svg";
import { ReactComponent as SidebarMailbox } from "./assets/Sidebar/mailbox.svg";
import { ReactComponent as SidebarMyBusiness } from "./assets/Sidebar/mybusiness.svg";
import { ReactComponent as SidebarProfile } from "./assets/Sidebar/profile.svg";
import { ReactComponent as SidebarServices } from "./assets/Sidebar/services.svg";
import { ReactComponent as SidebarSettings } from "./assets/Sidebar/settings.svg";
import { ReactComponent as SidebarSupport } from "./assets/Sidebar/support.svg";
import { ReactComponent as SidebarTrademark } from "./assets/Sidebar/trademark.svg";

function hexToRgba(hexColor, opacity) {
  return `rgba(
      ${parseInt(hexColor.substring(1, 3), 16)}, 
      ${parseInt(hexColor.substring(3, 5), 16)}, 
      ${parseInt(hexColor.substring(5, 7), 16)}, 
      ${opacity})`;
}

const StyledIcon = styled.div`
  box-sizing: border-box;

  ${({ color, size, width, height, padding }) => css`
    width: ${width || size || "32px"};
    height: ${height || size || "32px"};

    svg {
      color: ${color || "inherit"};
      width: inherit;
      height: inherit;
    }

    ${({ inline }) =>
      inline &&
      css`
        display: inline;
      `}

    ${({ rounded }) =>
      rounded &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: ${color ? hexToRgba(color, 0.1) : "transparent"};
        overflow: visible;
        padding: ${padding || "0px"};
        transition: all 0.2s;

        &:hover {
          background-color: ${({ color }) => (color ? hexToRgba(color, 0.2) : "transparent")};
          transform: scale(1.1);
        }
      `}
  `}
`;

let SelectedIcon;

const Icon = ({ icon, ...otherProps }) => {
  switch (icon) {
    // Billing
    case "billing:view":
      SelectedIcon = <BillingView />;
      break;
    case "billing:date":
      SelectedIcon = <BillingDate />;
      break;
    case "billing:testcard":
    case "billing:TESTCARD":
      SelectedIcon = <BillingTestCard />;
      break;
    case "billing:amex":
      SelectedIcon = <BillingAmexCard />;
      break;
    case "billing:discover":
      SelectedIcon = <BillingDiscoverCard />;
      break;
    case "billing:mastercard":
      SelectedIcon = <BillingMastercardCard />;
      break;
    case "billing:visa":
      SelectedIcon = <BillingVisaCard />;
      break;
    case "billing:edit":
      SelectedIcon = <BillingEdit />;
      break;

    // Documents
    case "documents:annual":
      SelectedIcon = <DocumentsAnnual />;
      break;
    case "documents:articles":
      SelectedIcon = <DocumentsArticles />;
      break;
    case "documents:ein":
      SelectedIcon = <DocumentsEin />;
      break;
    case "documents:filing":
      SelectedIcon = <DocumentsFiling />;
      break;
    case "documents:statement":
      SelectedIcon = <DocumentsStatement />;
      break;
    case "documents:default":
      SelectedIcon = <DefaultPDF />;
      break;
    case "documents:download":
      SelectedIcon = <DocumentsDownload />;
      break;

    // Header
    case "header:alerts":
      SelectedIcon = <HeaderAlerts />;
      break;
    case "header:help":
      SelectedIcon = <HeaderHelp />;
      break;
    case "header:settings":
      SelectedIcon = <HeaderSettings />;
      break;
    case "header:menu":
      SelectedIcon = <HeaderMenu />;
      break;
    case "header:logout":
      SelectedIcon = <HeaderLogout />;
      break;

    // Sidebar
    case "sidebar:actions":
      SelectedIcon = <SidebarActions />;
      break;
    case "sidebar:licenses":
      SelectedIcon = <SidebarLicenses />;
      break;
    case "sidebar:billing":
      SelectedIcon = <SidebarBilling />;
      break;
    case "sidebar:documents":
      SelectedIcon = <SidebarDocuments />;
      break;
    case "sidebar:mailbox":
      SelectedIcon = <SidebarMailbox />;
      break;
    case "sidebar:trademark":
      SelectedIcon = <SidebarTrademark />;
      break;
    case "sidebar:mybusiness":
      SelectedIcon = <SidebarMyBusiness />;
      break;
    case "sidebar:profile":
      SelectedIcon = <SidebarProfile />;
      break;
    case "sidebar:services":
      SelectedIcon = <SidebarServices />;
      break;
    case "sidebar:settings":
      SelectedIcon = <SidebarSettings />;
      break;
    case "sidebar:support":
      SelectedIcon = <SidebarSupport />;
      break;
    case "sidebar:close":
      SelectedIcon = <SidebarClose />;
      break;

    // Services
    case "services:agent":
      SelectedIcon = <ServicesAgent />;
      break;
    case "services:bookkeeping":
      SelectedIcon = <ServicesBookkeeping />;
      break;
    case "services:merchant":
      SelectedIcon = <ServicesMerchant />;
      break;
    case "services:trademark":
      SelectedIcon = <ServicesTrademark />;
      break;
    case "services:banking-resolution":
      SelectedIcon = <ServicesBankingResolutino />;
      break;
    case "services:operating-agreement":
      SelectedIcon = <ServicesOperatingAgreement />;
      break;
    case "services:ein-taxid":
      SelectedIcon = <ServicesEinTaxId />;
      break;
    case "services:annual-report":
      SelectedIcon = <ServicesAnnualReport />;
      break;
    case "services:credit-cards":
      SelectedIcon = <ServicesCreditCards />;
      break;

    // Profile
    case "profile:date":
      SelectedIcon = <ProfileDate />;
      break;
    case "profile:order":
      SelectedIcon = <ProfileOrder />;
      break;
    case "profile:status":
      SelectedIcon = <ProfileStatus />;
      break;

    // Mailbox
    case "mailbox:open":
      SelectedIcon = <MailboxOpen />;
      break;

    // Password Input
    case "password:show":
      SelectedIcon = <PasswordShow />;
      break;
    case "password:hide":
      SelectedIcon = <PasswordHide />;
      break;

    // Input Error Alert
    case "error:alert":
      SelectedIcon = <ErrorAlert />;
      break;

    case "misc:external-link":
      SelectedIcon = <MiscExternalLink />;
      break;

    case "misc:info":
      SelectedIcon = <MiscInfo />;
      break;

    case "misc:success-check":
      SelectedIcon = <MiscSuccessCheck />;
      break;

    case "misc:message":
      SelectedIcon = <MiscMessage />;
      break;

    case "misc:timer":
      SelectedIcon = <MiscTimer />;
      break;

    case "misc:hand-click":
      SelectedIcon = <MiscHandClick />;
      break;

    case "misc:back-arrow":
      SelectedIcon = <MiscBackArrow />;
      break;

    default:
      return;
  }

  return (
    <StyledIcon className="icon-container" {...otherProps}>
      {SelectedIcon}
    </StyledIcon>
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
};

export default Icon;
