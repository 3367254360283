import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error as errorNotification } from "react-notification-system-redux";

import { handleError } from "_helpers/api";
import { selectLicensesProduct, selectTrademarkProduct } from "_store/orders/selector";

export const checkRequiredProducts = createAsyncThunk(
  "application/checkRequiredProducts",
  async function (_, { dispatch, getState, rejectWithValue }) {
    try {
      const requiredProductsStatus = {
        hasBusinessLicenses: false,
        hasTrademarkRegister: false,
      };
      const state = getState();

      const licensesProduct = selectLicensesProduct(state);
      if (licensesProduct) {
        requiredProductsStatus.hasBusinessLicenses = true;
      }

      const trademarkRegisterProduct = selectTrademarkProduct(state);
      if (trademarkRegisterProduct) {
        requiredProductsStatus.hasTrademarkRegister = true;
      }

      // dispatch(completeInitialLoading());
      return requiredProductsStatus;
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    notifPopup: false,
    initialLoading: true,
    isUpdateRequired: "",
    isRequiredProductsChecked: false,
    requiredProducts: {
      hasBusinessLicenses: false,
      hasTrademarkRegister: false,
    },
  },
  reducers: {
    closeNotifPopup(state) {
      state.notifPopup = false;
    },
    openNotifPopup(state) {
      state.notifPopup = true;
    },
    completeInitialLoading(state) {
      state.initialLoading = false;
    },
    resetProductsChecked(state) {
      state.isRequiredProductsChecked = false;
    },
    checkBusinessLicenses(state, action) {
      state.requiredProducts.hasBusinessLicenses = action.payload;
    },
    checkTrademarkRegister(state, action) {
      state.requiredProducts.hasTrademarkRegister = action.payload;
    },
    requestUpdate(state, action) {
      state.isUpdateRequired = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkRequiredProducts.pending, (state) => {
        state.requiredProducts.hasBusinessLicenses = false;
        state.requiredProducts.hasTrademarkRegister = false;
        state.isRequiredProductsChecked = false;
      })
      .addCase(checkRequiredProducts.fulfilled, (state, action) => {
        const { hasBusinessLicenses, hasTrademarkRegister } = action.payload;

        state.requiredProducts.hasBusinessLicenses = hasBusinessLicenses;
        state.requiredProducts.hasTrademarkRegister = hasTrademarkRegister;

        state.isRequiredProductsChecked = true;
        state.initialLoading = false;
      });
  },
});

export const {
  closeNotifPopup,
  openNotifPopup,
  completeInitialLoading,
  resetProductsChecked,
  checkBusinessLicenses,
  checkTrademarkRegister,
  requestUpdate,
} = applicationSlice.actions;

export default applicationSlice.reducer;
