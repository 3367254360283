import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 66px);
  padding: 20px 30px;
  overflow-y: auto;
`;

const CardContent = ({ children, ...otherProps }) => {
  return <Container {...otherProps}>{children}</Container>;
};

CardContent.propTypes = {
  children: PropTypes.node,
};

export default CardContent;
