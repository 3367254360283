import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { refreshPage } from "_helpers";
import { requestUpdate } from "_store/application/slice";
import { selectActiveOrderId } from "_store/orders/selector";
import { getOrder } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import Icon from "components/atomic/atoms/Icon";

const StyledHeader = styled(Modal.Header)`
  margin-bottom: 12px;
  font-size: 1.125rem;
  text-align: center;
`;
const StyledBody = styled(Modal.Body)`
  font-size: 1rem;
  text-align: center;
  margin-bottom: 16px;
`;
const HeaderRow = styled.div`
  width: 100%;
`;
const TextRow = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;
const StyledFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled(Button)`
  background: #454cdf;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

const UpdateRequiredModal = ({ payment, header, message }) => {
  const dispatch = useDispatch();

  const customerId = useSelector(selectCustomerId);
  const orderId = useSelector(selectActiveOrderId);

  const refreshHandler = () => {
    if (customerId && orderId) {
      // dispatch(getOrders({ customerId }));
      dispatch(getOrder({ customerId, orderId, refreshOrders: true }));
      dispatch(requestUpdate(""));
    } else {
      refreshPage();
    }
  };

  return (
    <Modal centered show size="md">
      <StyledHeader>
        {header && <HeaderRow>{header}</HeaderRow>}
        {payment && (
          <HeaderRow>
            <Icon inline icon="misc:success-check" size="32px" color="#32D583" />
            &nbsp;Payment successful
          </HeaderRow>
        )}
      </StyledHeader>
      <StyledBody>
        {payment && <TextRow>Now you can use all the features of the product.</TextRow>}
        {message && <TextRow>{message}</TextRow>}
      </StyledBody>
      <StyledFooter>
        <StyledButton primary onClick={refreshHandler}>
          Reload Page
        </StyledButton>
      </StyledFooter>
    </Modal>
  );
};

UpdateRequiredModal.propTypes = {
  payment: PropTypes.bool,
  header: PropTypes.string,
  message: PropTypes.string,
};

export default UpdateRequiredModal;
