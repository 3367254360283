import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { handleProductName } from "_helpers";
import { getStatusColor } from "_helpers/profile";
import {
  selectActionRequiredProducts,
  selectCustomActionRequired,
  selectProcessingErrorsObj,
} from "_store/orders/selector";

import EmptyMessage from "components/atomic/atoms/EmptyMessage";
import Icon from "components/atomic/atoms/Icon";
import Card from "components/layouts/Card";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  box-sizing: border-box;
  width: 100%;
  height: 90%;
  max-height: 90%;
  animation: fadeIn 0.5s;

  ${({ theme }) => theme.animations.fadeIn}

  @media (max-width: 991px) {
    height: 100%;
    max-height: 100%;
  }
`;

const ContentRowHeader = styled.div`
  min-width: 100px;
  max-width: 100%;
  width: max-content;
  padding: 8px;
  font-weight: bold;
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
  border-top: 1px solid #eaecf0;
  border-radius: 8px 8px 0 0;
  /* box-shadow: 0px 0px 4px lightgray inset; */
  background-color: #eaecf0;

  ${({ theme }) =>
    css`
      color: ${theme.colors["cl-text-dark"]};
    `};
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  /* border: 5px solid #eaecf0; */
  background-color: #eaecf0;
  /* box-shadow: 0px 0px 4px lightgray inset; */
  border-radius: 0 8px 8px 8px;
  padding: 8px;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const ActionsList = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 8px;   */
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* padding: 12px 0; */

  border-top: 1px solid rgb(219, 228, 240);
  /* border-bottom: 1px solid rgb(219, 228, 240); */
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding: 0px 8px;
  min-height: 32px;

  ${({ theme }) =>
    css`
      color: ${theme.colors["cl-text-light"]};
      font-weight: bold;
    `}

  @media (max-width: 450px) {
    display: none;
  }
`;

const ActionItem = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding: 16px 8px;
  min-height: 64px;
  border-radius: 0;

  cursor: pointer;
  transition: background-color 0.08s linear;

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background-color: #bddaff66;

    & .action-title {
      text-decoration: underline;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const ActionsItemStatic = styled(ActionItem)`
  cursor: default;
  transition: none;
  &:hover {
    background-color: white;
  }
`;

const ActionCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 4px;

  min-width: 100px;

  ${({ $flex }) =>
    $flex &&
    css`
      flex: ${$flex};
    `}

  ${({ $status }) =>
    $status &&
    css`
      color: ${$status};
      font-weight: bold;
    `}

  @media (max-width: 450px) {
    flex: 12;
    /* width: 100%; */
  }
`;

const ActionContentRow = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 8px;
  overflow-wrap: anywhere;

  ${({ $small }) =>
    $small &&
    css`
      font-size: 0.875em;
      color: gray;
      text-decoration: none !important;
    `}
`;

const OpenIcon = styled(Icon)`
  display: none;

  @media (max-width: 450px) {
    display: block;
    position: absolute;
    bottom: 8px;
    right: 8px;

    ${({ theme }) =>
      css`
        color: ${theme.colors["cl-text-light"]};
      `}
  }
`;

const DesktopOpenIcon = styled(Icon)`
  ${({ theme }) =>
    css`
      color: ${theme.colors["cl-text-light"]};
    `}

  @media (max-width: 450px) {
    display: none;
  }
`;

const Space = styled.div`
  height: 64px;
  border-bottom: 1px solid white;
  opacity: 0%;
`;

function renderActionTitle(action, processingErrorsObj) {
  const currentError = action?.product?.processingResult?.processingError;
  const errorName = processingErrorsObj[currentError]?.value || processingErrorsObj[currentError]?.code || "Error";

  switch (action?.status) {
    case "Error":
      return errorName;

    default:
      return action?.title || "Action Required";
  }
}

const CurrentActionElement = ({ item, processingErrorsObj, handleOpenDetails }) => {
  return (
    <ActionItem onClick={() => handleOpenDetails(item)}>
      <ActionCol $flex={8}>
        <ActionContentRow className="action-title">
          <DesktopOpenIcon inline size="24px" icon="misc:external-link" />
          {renderActionTitle(item, processingErrorsObj)}
        </ActionContentRow>
        {item?.product?.customerName && (
          <ActionContentRow $small>
            For Product: {handleProductName(item?.product?.customerName) || "–"}
          </ActionContentRow>
        )}
      </ActionCol>
      <ActionCol $flex={2} $status={getStatusColor("Unresolved")}>
        Unresolved
      </ActionCol>
      <ActionCol $flex={2}>{item?.timestamp ? moment(item.timestamp).format("MM/DD/YYYY") : "–"}</ActionCol>
      <OpenIcon icon="misc:hand-click" />
    </ActionItem>
  );
};

CurrentActionElement.propTypes = {
  handleOpenDetails: PropTypes.func,
  processingErrorsObj: PropTypes.object,
  item: PropTypes.shape({
    product: PropTypes.shape({
      customerName: PropTypes.string,
    }),
    timestamp: PropTypes.string,
    title: PropTypes.string,
  }),
};

const HistoryActionElement = ({ item, processingErrorsObj }) => {
  const currentError = item?.error;
  const errorName = processingErrorsObj[currentError]?.value || processingErrorsObj[currentError]?.code || "Error";

  return (
    <ActionsItemStatic>
      <ActionCol $flex={8}>
        <ActionContentRow>{currentError ? errorName : item?.title}</ActionContentRow>
        <ActionContentRow $small>For Product: {handleProductName(item?.product?.customerName) || "–"}</ActionContentRow>
      </ActionCol>
      <ActionCol $status={getStatusColor("Resolved")} $flex={2}>
        Resolved
      </ActionCol>
      <ActionCol $flex={2}>{moment(item?.timestamp).format("MM/DD/YYYY")}</ActionCol>
    </ActionsItemStatic>
  );
};

HistoryActionElement.propTypes = {
  item: PropTypes.shape({
    error: PropTypes.string,
    product: PropTypes.shape({
      customerName: PropTypes.string,
    }),
    timestamp: PropTypes.string,
    title: PropTypes.string,
  }),
  processingErrorsObj: PropTypes.object,
};

const ActionsPage = () => {
  const navigate = useNavigate();

  const actions = useSelector(selectActionRequiredProducts);
  const customActions = useSelector(selectCustomActionRequired);
  const processingErrorsObj = useSelector(selectProcessingErrorsObj);

  const anyCustomActionsShouldBeRendered = customActions.some((item) => item.shouldBeRendered);

  function handleOpenActionDetails(action) {
    navigate(`../action-details/${action?.product?.id || "-"}/${action?.type}`);
  }

  return (
    <Container>
      <ContentRowHeader>Current Actions</ContentRowHeader>
      <ContentRow>
        {!actions.unresolved?.length && !anyCustomActionsShouldBeRendered ? (
          <EmptyMessage $text>No Actions are required</EmptyMessage>
        ) : (
          <>
            <HeaderRow>
              <ActionCol $flex={8}>Name</ActionCol>
              <ActionCol $flex={2}>Status</ActionCol>
              <ActionCol $flex={2}>Date</ActionCol>
            </HeaderRow>
            <ActionsList>
              {anyCustomActionsShouldBeRendered &&
                customActions.map((item, idx) => (
                  <CurrentActionElement
                    key={`custom-action-item-${idx}`}
                    item={item}
                    processingErrorsObj={processingErrorsObj}
                    handleOpenDetails={handleOpenActionDetails}
                  />
                ))}

              {actions.unresolved.map((item, idx) => (
                <CurrentActionElement
                  key={`current-action-item-${idx}`}
                  item={item}
                  processingErrorsObj={processingErrorsObj}
                  handleOpenDetails={handleOpenActionDetails}
                />
              ))}
            </ActionsList>
          </>
        )}
      </ContentRow>

      <ContentRowHeader>Actions History</ContentRowHeader>
      <ContentRow>
        {!actions.resolved?.length ? (
          <EmptyMessage $text>No actions have been done before</EmptyMessage>
        ) : (
          <>
            <HeaderRow>
              <ActionCol $flex={8}>Name</ActionCol>
              <ActionCol $flex={2}>Status</ActionCol>
              <ActionCol $flex={2}>Resolved Date</ActionCol>
            </HeaderRow>
            <ActionsList>
              {actions.resolved.map((item, idx) => (
                <HistoryActionElement
                  key={`history-action-item-${idx}`}
                  item={item}
                  processingErrorsObj={processingErrorsObj}
                />
              ))}
            </ActionsList>
          </>
        )}
      </ContentRow>
      <Space />
    </Container>
  );
};

export default ActionsPage;
