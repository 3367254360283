import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const StyledGroup = styled(Form.Group)`
  display: flex;
  align-items: center;
  height: 100%;

  input {
    cursor: pointer;
  }

  input:checked + label,
  input:not(:checked) + label {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors["cl-text-dark"]};
    cursor: pointer;
  }
`;

const StyledCheck = styled(Form.Check)`
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 8px;

  label {
    line-height: 24px;
  }

  &:last-child {
    margin-left: 24px;
  }
`;

const Switch = ({ name, labelOn = "On", labelOff = "Off", valueOn = true, valueOff = false, value, onChange }) => {
  const handleChange = useCallback(
    (e) => {
      const { value: rawValue } = e.target;
      const value = rawValue === "true" ? true : false;
      onChange && onChange({ target: { name, value } });
    },
    [onChange, name]
  );

  return (
    <StyledGroup>
      <StyledCheck
        id={`${name}:on`}
        inline
        type="radio"
        label={labelOn}
        checked={value === true}
        value={valueOn}
        onChange={handleChange}
      />
      <StyledCheck
        id={`${name}:off`}
        inline
        type="radio"
        label={labelOff}
        checked={value === false}
        value={valueOff}
        onChange={handleChange}
      />
    </StyledGroup>
  );
};

Switch.propTypes = {
  name: PropTypes.string,
  labelOn: PropTypes.node,
  labelOff: PropTypes.node,
  valueOn: PropTypes.bool,
  valueOff: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
