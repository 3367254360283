import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { config } from "_config";
import { selectLoading, selectLoggedIn } from "_store/user/selector";

import { SpinnerWrapper } from "components/atomic/atoms/Spinner";

function PrivateRoute() {
  const loggedIn = useSelector(selectLoggedIn);
  const loading = useSelector(selectLoading);

  if (loading && !loggedIn) {
    return <SpinnerWrapper />;
  }

  return loggedIn ? <Outlet /> : <Navigate to={config.publicUrl + "/login"} />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
