import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { validateTrim } from "_helpers";
import { checkCompanyName } from "_helpers/validationCheckers";
import { selectMainProduct } from "_store/orders/selector";
import { updateProducts } from "_store/orders/slice";
import { selectCustomerId } from "_store/user/selector";

import Button from "components/atomic/atoms/Button";
import TextField from "components/atomic/molecules/fields/TextField";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  /* min-height: 500px; */
  padding: 8px 12px;
  box-shadow: 0 0 3px lightgray inset;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-content-bg"]};
  `}
`;

const FieldsGroup = styled.div``;

const FieldsGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

const StyledButton = styled(Button)`
  min-width: 112px;
  width: 200px;
`;

const rules = {
  companyName: ({ companyName, altCompanyName, trigger }) => ({
    required: "Name Choice #1 is a required field",
    validate: (value) => {
      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        trigger("altCompanyName");
        return trimError;
      }

      if (companyName === value || altCompanyName === value) {
        trigger("altCompanyName");
        return "Please, provide a name other than the previous one";
      }

      const validated = checkCompanyName(value);
      if (!validated) {
        trigger("altCompanyName");
        return "Please enter a valid Company Name";
      }

      trigger("altCompanyName");
      return true;
    },
  }),
  altCompanyName: ({ companyName, altCompanyName, getValues }) => ({
    required: "Name Choice #2 is a required field",
    validate: (value) => {
      const trimError = validateTrim(value);
      if (typeof trimError === "string") {
        return trimError;
      }

      if (getValues("companyName") === value) {
        return "Please, provide different name variants";
      }

      if (companyName === value || altCompanyName === value) {
        return "Please, provide a name other than the old one";
      }

      const validated = checkCompanyName(value);
      if (!validated) {
        return "Please enter a valid Alternate Company Name";
      }

      return true;
    },
  }),
};

const initValues = {
  companyName: "",
  altCompanyName: "",
};

const NameConflictErrorForm = () => {
  const dispatch = useDispatch();

  const form = useForm({ defaultValues: initValues });
  const {
    control,
    formState: { errors },
    trigger,
    getValues,
    handleSubmit,
  } = form;

  const values = useWatch(form);
  const customerId = useSelector(selectCustomerId);
  const mainProduct = useSelector(selectMainProduct);

  function onSubmit() {
    const products = [
      {
        ...mainProduct,
        companyName: values.companyName,
        altCompanyName: values.altCompanyName,
      },
    ];

    dispatch(
      updateProducts({
        customerId,
        products,
        redirectUrl: "../actions",
      })
    );
  }

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FieldsGroup>
          <FieldsGroupContent>
            <TextField
              column
              name="companyName"
              label="Name Choice #1"
              control={control}
              errors={errors}
              rules={rules.companyName({
                companyName: mainProduct?.companyName,
                altCompanyName: mainProduct?.altCompanyName,
                trigger,
              })}
            />
            <TextField
              column
              name="altCompanyName"
              label="Name Choice #2"
              control={control}
              errors={errors}
              rules={rules.altCompanyName({
                companyName: mainProduct?.companyName,
                altCompanyName: mainProduct?.altCompanyName,
                getValues,
              })}
            />
            <ButtonsGroup>
              <StyledButton primary type="submit" disabled={!values.companyName || !values.altCompanyName}>
                Submit
              </StyledButton>
            </ButtonsGroup>
          </FieldsGroupContent>
        </FieldsGroup>
      </StyledForm>
    </>
  );
};

export default NameConflictErrorForm;
