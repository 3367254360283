import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    background-color: ${theme.colors["cl-card-bg"]};
    border: 1px solid ${theme.colors["cl-card-border"]};
  `}

  ${({ size, width, height, minheight, maxheight, minwidth, maxwidth }) => css`
    width: ${width || size || "auto"};
    height: ${height || size || "auto"};
    min-height: ${minheight || "auto"};
    max-height: ${maxheight || "auto"};
    /* min-width: ${minwidth || "auto"}; */
    max-width: ${maxwidth || "none"};
  `}
`;

const Card = ({ children, ...otherProps }) => {
  return <StyledCard {...otherProps}>{children}</StyledCard>;
};

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
