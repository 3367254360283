import React from "react";

import CardDeclinedErrorForm from "components/atomic/organisms/forms/actions/errors/CardDeclinedErrorForm";
import MissmatchErrorForm from "components/atomic/organisms/forms/actions/errors/MissmatchErrorForm";
import NameConflictErrorForm from "components/atomic/organisms/forms/actions/errors/NameConflictErrorForm";

const ActionErrorForm = (props) => {
  const { error } = props;

  function renderErrorActionForm() {
    switch (error) {
      case "CardDeclined":
        return <CardDeclinedErrorForm {...props} />;

      case "NameConflict":
        return <NameConflictErrorForm {...props} />;

      case "MismatchError":
        return <MissmatchErrorForm {...props} />;

      default:
        return <div>No available quick actions</div>;
    }
  }

  return renderErrorActionForm();
};

export default ActionErrorForm;
