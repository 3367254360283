import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { amendments_options_array } from "_constants/variables";
import { handleError } from "_helpers";
import { OrdersService } from "_services";
import { selectActiveOrderId, selectActiveOrderProductCodes, selectAmendmentUpsells } from "_store/orders/selector";

import Indicator from "components/atomic/atoms/Indicator";
import Select from "components/atomic/atoms/Select";
import { SpinnerBlock } from "components/atomic/atoms/Spinner";
import Table from "components/atomic/molecules/Table";

const StyledHeader = styled(Modal.Header)`
  font-size: 1rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-light"]};
  `}
`;

const StyledBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`;

const columns = [
  {
    dataIndex: "status",
    title: "#",
    width: "4%",
    minWidth: "20px",
    render: (row) => <Indicator status={row?.status} />,
  },
  {
    dataIndex: "title",
    title: "Title",
    width: "80%",
    // minWidth: "200px",
    render: (row) => row?.title || "—",
  },
  {
    dataIndex: "date",
    title: "Date",
    width: "16%",
    minWidth: "100px",
    render: (row) =>
      row?.date ? (
        <div>
          <div>{moment(row?.date).format("MM/DD/YY")}</div>
          <div>{moment(row?.date).format("HH:mm")}</div>
        </div>
      ) : (
        "—"
      ),
  },
];

// eslint-disable-next-line no-unused-vars
const RequestAmendmentModal = ({ onCancel }) => {
  const orderId = useSelector(selectActiveOrderId);
  const productCodes = useSelector(selectActiveOrderProductCodes);
  const amendments = useSelector(selectAmendmentUpsells);

  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState("step1");

  const availableAmendmentOptions = useMemo(() =>
    amendments_options_array.filter(
      (option) => {
        if (option.requiredProduct) return productCodes.includes(option.requiredProduct);
        return true;
      },
      [productCodes]
    )
  );

  const rows = amendments?.filter(({ parentOrderUid }) => parentOrderUid === orderId);

  async function createAmendmentUpsale(orderId, body) {
    try {
      const request = await OrdersService.createDelayedUpsell({ orderId, body });

      if (request?.status === 200) {
        setStep("step3");
      } else {
        setStep("stepError");
        setError({ message: "Unknown Error" });
      }
    } catch (response) {
      setStep("stepError");
      setError(handleError(response));
    }
  }

  switch (step) {
    case "step1":
      return (
        <Modal centered show onHide={onCancel} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>Please select the option that corresponds to the necessary amendments.</Row>
            <Select
              secondary
              options={availableAmendmentOptions}
              value={selectedOption}
              onChange={(option) => {
                setSelectedOption(option);
              }}
            />
            <Row>
              Please note that amendment requests incur a $99.00 processing fee (inclusive of State fees). After
              clicking Process below, your card on file will be billed for this amount. An agent will follow up with you
              directly to complete your request.
            </Row>

            <Buttons>
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="success"
                disabled={!selectedOption}
                onClick={() => {
                  const body = {
                    order: { processingOption: "UPSELL", campaignId: 29 },
                    products: [{ code: { code: selectedOption?.value } }],
                  };
                  setStep("step2");
                  createAmendmentUpsale(orderId, body);
                }}
              >
                Process
              </Button>
            </Buttons>
          </StyledBody>
          {Boolean(rows?.length) && (
            <Modal.Footer>
              <Row bold>
                Related Amendments:
                <Table hiddenHeader columns={columns} rows={rows} maxHeight="200px" />
              </Row>
            </Modal.Footer>
          )}
        </Modal>
      );

    case "step2":
      return (
        <Modal centered show onHide={() => {}} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <SpinnerBlock message="Sending Request" />
          </StyledBody>
        </Modal>
      );

    case "step3":
      return (
        <Modal centered show onHide={onCancel} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>We have received your amendment request, an agent will be reaching out to you via email shortly.</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="success" onClick={onCancel}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case "stepError":
      return (
        <Modal centered show onHide={onCancel} size="md">
          <StyledHeader>Request Amendment</StyledHeader>
          <StyledBody>
            <Row>Please, try again later or contact with our agents.</Row>
            <Row>{error?.message ? `Error: ${error?.message}` : ""}</Row>
          </StyledBody>
          <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
};

RequestAmendmentModal.propTypes = {
  onCancel: PropTypes.func,
};

export default RequestAmendmentModal;
