import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider } from "styled-components";

import FontStyles from "assets/fonts";

import GlobalStyles from "./GlobalStyles";
import animations from "./animations";
import colors from "./colors";
import { fontFamilies, fontSizes, fontWeights } from "./fonts";

const theme = {
  animations,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
};

const Theme = ({ children }) => (
  <>
    <FontStyles />
    <GlobalStyles />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
);

Theme.propTypes = {
  children: PropTypes.element,
};

export default Theme;
